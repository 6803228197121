import { useEffect, useRef } from 'react';
import playIcon from '../../../assets/play.svg';
import { Link } from 'react-router-dom';
import './video.scss';

const Video = ({ animateRef, content, dataIndex }) => {
  const maps = useRef();
  const saMap = useRef();
  const iMap = useRef();
  const colMap = useRef();
  const usMap = useRef();

  maps.current = [saMap, iMap, usMap, colMap];
  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            // Iterate over child nodes
            for (
              var child = animateRef.current.firstChild;
              child !== null;
              child = child.nextSibling
            ) {
              child.style.cssText = 'opacity: 1; transform: translateY(0)';

              document.getElementById(content[dataIndex].id).classList.add('animate-cover');
              document
                .getElementById('video-' + content[dataIndex].id)
                .classList.add('animate-map');
            }
            maps.current[dataIndex].current.style.opacity = 1;
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(animateRef.current);
  }, [animateRef, content, dataIndex]);

  return (
    <section className={`video color-${content[dataIndex].color}`} id={content[dataIndex].id}>
      <video autoPlay muted playsInline loop className="video-loop video-d">
        <source src={content[dataIndex].videoD} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <video autoPlay muted playsInline loop className="video-loop video-m">
        <source src={content[dataIndex].videoM} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>

      <img
        className="video__map"
        src={content[dataIndex].map}
        alt=""
        ref={maps.current[dataIndex]}
      />
      <div className="video-wrapper" id={`video-${content[dataIndex].id}`}>
        <div className="inner-wrapper">
          <div className="video-content animate-content" ref={animateRef}>
            <div className="video-content-inner-wrapper">
              <div className="video-eyebrow">
                <span>The Virus Hunt: Episode {content[dataIndex].episodeNumber}</span>
              </div>
              <div className="video-title">
                <h2>{content[dataIndex].title}</h2>
              </div>
              <div className="video-description">
                <p>{content[dataIndex].description}</p>
              </div>

              <Link to={content[dataIndex].link} className="outline-button">
                <img src={playIcon} alt="" className="play-icon" />
                Watch and Explore
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Video;
