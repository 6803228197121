import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { responseData } from './responseData';
import correctIcon from '../../../../assets/interactive/correct.svg';
import wrongIcon from '../../../../assets/interactive/wrong.svg';
import './response.scss';
import { sendAnswerClickEvents } from '../../sendInteractiveEvents';

function Response({ index, active, reveal, setActive }) {
  const s1Button0Ref = useRef();
  const s1Button1Ref = useRef();
  const s1Button2Ref = useRef();
  const s1Button3Ref = useRef();

  const s2Button0Ref = useRef();
  const s2Button1Ref = useRef();
  const s2Button2Ref = useRef();

  const s3Button0Ref = useRef();
  const s3Button1Ref = useRef();
  const s3Button2Ref = useRef();

  const s4Button0Ref = useRef();
  const s4Button1Ref = useRef();
  const s4Button2Ref = useRef();

  const buttonsRef = useRef();
  buttonsRef.current = [
    [s1Button0Ref, s1Button1Ref, s1Button2Ref, s1Button3Ref],
    [s2Button0Ref, s2Button1Ref, s2Button2Ref],
    [s3Button0Ref, s3Button1Ref, s3Button2Ref],
    [s4Button0Ref, s4Button1Ref, s4Button2Ref]
  ];
  const copy1Ref = useRef();
  const copy2Ref = useRef();
  const copy3Ref = useRef();
  const copy4Ref = useRef();
  const copiesRef = useRef();
  copiesRef.current = [copy1Ref, copy2Ref, copy3Ref, copy4Ref];
  const { correct, buttons, answers } = responseData[index];

  const handleCorrect = useCallback(() => {
    copiesRef.current[index].current.style.opacity = '1';
    buttonsRef.current[index] &&
      buttonsRef.current[index].forEach((button, i) => {
        if (copiesRef.current[index]) {
          copiesRef.current[index].current.style.opacity = '1';
        }
        if (button.current) {
          button.current.style.pointerEvents = 'none';
        }
        if (correct !== i && button.current) {
          button.current.style.background =
            'linear-gradient(72.3deg, rgba(54, 107, 181, 0.1) 6.28%, rgba(34, 191, 248, 0.1) 87.53%)';
          button.current.style.color = 'rgba(255, 255, 255, 0.2)';
        }
      });
  }, [index, correct, buttonsRef, copiesRef]);

  useEffect(() => {
    if (copiesRef.current[index].current && active !== null) {
      copiesRef.current[index].current.style.opacity = '1';
    }

    buttonsRef.current[index] &&
      buttonsRef.current[index].forEach((button, i) => {
        if (button.current) {
          button.current.style.animation = '';
        }
        if (active !== correct) {
          if (active === i) {
            button.current.style.animation = 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both';
          }
        }
      });
  }, [active, correct, buttonsRef, copiesRef, index]);

  useEffect(() => {
    if (active === correct) {
      handleCorrect();
    }
  }, [active, correct, handleCorrect]);

  return (
    <div className={`response response-${index}`}>
      <div className="response__buttons">
        {buttons.map((data, i) => {
          return (
            <button
              key={`response-${index}-${i}`}
              className={
                active !== i
                  ? 'response__buttons__button'
                  : correct !== i
                  ? 'response__buttons__button response__buttons__button--wrong'
                  : 'response__buttons__button response__buttons__button--correct'
              }
              onClick={() => {
                setActive(i);
                sendAnswerClickEvents('Q & A', index + 1, data);
              }}
              ref={buttonsRef.current[index][i]}
            >
              {data}
              {active === i && (
                <img
                  src={correct === i ? correctIcon : wrongIcon}
                  alt=""
                  className="response__buttons__button__icon"
                />
              )}
            </button>
          );
        })}
      </div>

      <div
        className={index === 0 ? 'response__copy response__copy-0' : 'response__copy'}
        ref={copiesRef.current && copiesRef.current[index]}
      >
        {active !== correct ? (
          <h4 className="response__copy--wrong">Try again</h4>
        ) : (
          <h4 className="response__copy--correct">Correct {reveal ? 'Answer' : ''}</h4>
        )}

        {answers &&
          active !== null &&
          answers[active].map((answer, i) => {
            return (
              <Fragment key={i}>
                <p>{answer}</p>
                {i + 1 && <br />}
              </Fragment>
            );
          })}
      </div>
    </div>
  );
}

export default Response;
