import React, { useRef } from 'react';

import SubPgsNav from '../SubPgs/SubPgsNav';
import SubPgsVimeo from '../SubPgs/SubPgsVimeo';
import '../../styles/App.scss';
import RelatedContents from '../RelatedContents';
import MetaTags from 'react-meta-tags';
import subPgsData from '../SubPgs/subPgsData';

import SubPgsContent from '../SubPgs/SubPgsContent';
import SubPgsHeroes from '../SubPgs/SubPgsHeroes';
import SubPgsQuote from '../SubPgs/SubPgsQuote';
import InlinePhoto from '../SubPgs/InlinePhoto';
import ArticlesCarousel from '../Hub/ArticlesCarousel';
import ImageGrid from '../SubPgs/ImageGrid';

import Content from './Content';
import Heroes from './Heroes';
import Quote from './Quote';
import articles from '../Hub/ArticlesCarousel/articles';
import Grid from './Grid';

import ImageOne from '../../assets/enhanced-contents/col-inline.jpg';

function Colombia({ screenWidth }) {
  const quoteRef = useRef();
  const quoteRef2 = useRef();
  const topRef = useRef();
  const btmRef = useRef();

  return (
    <section className="colombia">
      <MetaTags>
        <title>Abbott | The Virus Hunt Episode 4: Colombia | Sponsored Content | Discovery</title>
      </MetaTags>
      <SubPgsNav data={subPgsData.col.nav} />
      <SubPgsVimeo data={subPgsData.col.vimeo} />
      <SubPgsContent
        topData={Content.top}
        topRef={topRef}
        btmData={Content.bottom}
        btmRef={btmRef}
        screenWidth={screenWidth}
      />
      <SubPgsHeroes data={Heroes} origin={'colombia'} />
      <InlinePhoto img={ImageOne} />
      <SubPgsQuote data={Quote.one} animateRef={quoteRef} />
      <div className="subPgsCarousel">
        <ArticlesCarousel location={articles.colombia} color={'purple'} />
      </div>
      <ImageGrid items={Grid} />
      <SubPgsQuote data={Quote.two} animateRef={quoteRef2} />
      <RelatedContents origin="video" />
    </section>
  );
}

export default Colombia;
