import React, { Fragment, useState } from 'react';
import './mapDots.scss';
import Lottie from 'lottie-react';
import { mapDotsData } from './mapDotsData';
import xButton from '../../../../assets/interactive/popup_close.svg';
import { sendPopOutClickEvents } from '../../sendInteractiveEvents';

const MapDots = ({
  mapContainerRef,
  upper1Ref,
  upper2Ref,
  lower1Ref,
  lower2Ref,
  lottieRef,
  index
}) => {
  const { upper1, upper2, lower1, lower2, lottie } = mapDotsData[index];
  const [text1Open, setText1open] = useState(false);
  return (
    <div className={`mapDots mapDots-${index}`} ref={mapContainerRef}>
      <div className={`mapDots__mapCopy mapDots__mapCopy-${index}`}>
        <div className="mapDots__mapCopy__copy mapDots__copy mapDots__copy--r" ref={upper1Ref}>
          {upper1.map((copy, i) => {
            return (
              <p key={`upper1-${index}-${i}`}>
                {copy}

                {upper1[i + 1] && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </p>
            );
          })}
        </div>

        <div className="mapDots__mapCopy__copy mapDots__copy mapDots__copy--r" ref={upper2Ref}>
          {upper2 &&
            upper2.map((copy, i) => {
              return (
                <p key={`upper2-${index}-${i}`}>
                  {copy}

                  {upper2[i + 1] && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                </p>
              );
            })}
        </div>
      </div>
      <Lottie animationData={lottie} className="mapDots__lottie" lottieRef={lottieRef} />
      <div className={`mapDots__mapCopy mapDots__mapCopy-lower`}>
        <div className="mapDots__copy mapDots__copy--r mapDots__copy-lower" ref={lower1Ref}>
          {lower1.map((copy, i) => {
            return (
              <p key={`lower1-${index}-${i}`}>
                {copy}

                {lower1[i + 1] && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </p>
            );
          })}
        </div>
        {index === 0 ? (
          <p className="mapDots__copy mapDots__copy--b mapDots__copy-lower" ref={lower2Ref}>
            {lower2}
          </p>
        ) : (
          <p
            className="mapDots__mapCopy__copy mapDots__copy mapDots__copy--r mapDots__copy-lower"
            ref={lower2Ref}
          >
            To determine how far the virus has spread and how easily it is transmitted, the world
            needs diagnostic tools (called{' '}
            <span
              onClick={() => {
                setText1open(!text1Open);
                sendPopOutClickEvents(2, '“assays”');
              }}
              className="sequence1__copy__text__popup"
            >
              “assays”
            </span>
            ) to rapidly test samples taken from human patients and confirm if they are positive or
            negative for the virus.
            <br />
            <br />
            But how do you create a diagnostic test in the first place?
            <br />
            <br />
            And how do you mass produce it at scale?
          </p>
        )}
      </div>
      {text1Open && (
        <>
          <div className="sequence1__popout1">
            <div className="sequence1__popout1__wrapper">
              <div className="xButton">
                <button onClick={() => setText1open(!text1Open)}>
                  <img src={xButton} alt="circle with x" />
                </button>
              </div>
              <div className="textCont">
                <p>
                  An assay is the industry term for a diagnostic test to assess or measure the
                  presence of a virus in a sample.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MapDots;
