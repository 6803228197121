import React from 'react';
import './finish.scss';
import FinishContent4 from '../../Stages/Stage4/Finish4/FinishContent4';
import { stageData } from '../../stageData';

const Finish = ({ stage, containerRef }) => {
  return (
    <div className="finish finish-common" ref={containerRef}>
      <div className="finish__stage">stage{stage}</div>
      <h4>Completed</h4>
      {stage !== 4 ? (
        <div className="finish__copy">
          {stageData[stage - 1].finish.map((copy, i) => {
            return (
              <p
                key={`finishcopy${i}`}
                className={
                  stageData[stage - 1].finish[i + 1] ? 'finish__copy--r' : 'finish__copy--b'
                }
              >
                {copy}
                <br /> <br />
              </p>
            );
          })}
        </div>
      ) : (
        <FinishContent4 />
      )}
    </div>
  );
};

export default Finish;
