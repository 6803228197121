import { useContext, useEffect, useRef } from 'react';
import { WidthContext } from '../../WidthContext';
import videoData from '../Hub/Video/videoData';
import { Link } from 'react-router-dom';
import './macro.scss';

const MacroBody = () => {
  const animateRef = useRef();
  const { width } = useContext(WidthContext);
  useEffect(() => {
    const delayAnimation = width <= window.innerHeight;
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            if (delayAnimation !== true) {
              animateRef.current.style.cssText = 'opacity: 1;transform:translateY(0);';
            } else {
              setTimeout(() => {
                animateRef.current.style.cssText = 'opacity: 1;transform:translateY(0);';
              }, [1000]);
            }
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(animateRef.current);
  }, [animateRef, width]);

  return (
    <section className="macroBody" ref={animateRef}>
      <span className="macroBody__eyebrow">film</span>
      <h2 className="macroBody__title">{videoData[4].title}</h2>
      <p className="macroBody__description">{videoData[4].description}</p>
      <Link to={'/'} className="macroBody__back">
        Back to Hub
      </Link>
    </section>
  );
};

export default MacroBody;
