import icon1 from '../../assets/interactive/stage1/stage1_icon.png';
import icon2 from '../../assets/interactive/stage2/stage2_icon.svg';
import icon3 from '../../assets/interactive/stage3/stage3_icon.svg';
import icon4 from '../../assets/interactive/stage4/stage4_icon.png';
import icon1S from '../../assets/interactive/stage1/stage1_sm.svg';
import icon2S from '../../assets/interactive/stage2/stage2_sm.svg';
import icon3S from '../../assets/interactive/stage3/stage3_sm.svg';
import icon4S from '../../assets/interactive/stage4/stage4_sm.svg';

export const stageData = [
  {
    icon: icon1,
    iconSm: icon1S,
    head: 'Identify',
    sub: 'Identify a new pathogen. Publish a complete genomic sequence.',
    copy: 'Identify a new pathogen and then generate and publish a complete genomic sequence.',
    finish: [
      'You’ve successfully navigated the first stage in pandemic prevention.',
      'Freely sharing vital research about emerging pathogens with the global public health community is crucial to mitigating outbreaks before they explode into pandemics. Now researchers and scientists around the world can get to work.',
      'This is just the beginning.'
    ]
  },
  {
    icon: icon2,
    iconSm: icon2S,
    head: 'Develop',
    sub: 'Create diagnostic tests.',
    copy:
      'Create molecular, serological, and rapid diagnostic tests with samples from initial cases.',
    finish: [
      'You’ve now created two diagnostic assays that are highly accurate and effective — in the laboratory setting.',
      'How will it fare in the real world?'
    ]
  },
  {
    icon: icon3,
    iconSm: icon3S,
    head: 'Deploy',
    sub: 'Deploy tests around the world.',
    copy: 'Deploy tests around the world to supercharge research and prevention.',
    finish: [
      'Your PCR test and antigen test are approved — and being deployed to outbreak sites.',
      'Now, scientists and public health experts are working together to deliver the tools needed to identify infections and help slow outbreaks.',
      'You can finally track the true spread of this virus.'
    ]
  },
  {
    icon: icon4,
    iconSm: icon4S,
    head: 'Surveillance',
    sub: 'Track mutations — and respond alongside public health authorities.',
    copy:
      'Conduct virus surveillance to understand how many are affected, where it has spread, and risk factors for the disease — then assist the public health community in taking appropriate and measured responses.'
  }
];
