import quoteImg from '../../assets/enhanced-contents/purple-quote.png';

const Quote = {
  one: {
    img: quoteImg,
    desc:
      'In Colombia, we are very well placed to study viruses because the country is considered a hotspot for the emergence of new pathogens. We have a tropical climate and a large mosquito population. In the Amazon, people live in very close contact with the forest and wild animals. The opportunity is there for viruses to cycle between animals and humans.',
    author: 'Jorge Osorio'
  },
  two: {
    img: quoteImg,
    desc:
      'The Abbott Pandemic Defense Coalition has significantly helped us locally to reduce the time that we need to produce results from testing research samples. We are now able to sequence more samples in Colombia instead of shipping them outside for analysis. And by building local capacity and skills, our well-trained scientists are able to deliver insights more quickly to better aid in the identification of pathogens.',
    author: 'Jorge Osorio'
  }
};

export default Quote;
