import React from 'react';
import Numbers from '../Numbers/Numbers';
import './questions.scss';

function Questions({ questionsData, number }) {
  return (
    <div className="questions">
      <Numbers number={number} origin="body" />
      <h3>
        {questionsData[0]}
        <br />
        {questionsData[1] ? questionsData[1] : ''}
      </h3>
    </div>
  );
}

export default Questions;
