import { useEffect } from 'react';

const BottomBlurb = ({ data, animateRef }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            // Iterate over child nodes
            for (
              var child = animateRef.current.firstChild;
              child !== null;
              child = child.nextSibling
            ) {
              child.style.cssText = 'opacity: 1;transform:translateY(0);';
            }
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(animateRef.current);
  }, [animateRef]);

  return (
    <section className="SubPgsContent SubPgsContent-bio" ref={animateRef}>
      <div className="SubPgsContent-image">
        <img src={data.img} alt="" />
      </div>
      <div className="SubPgsContent-container SubPgsContent-bio-container">
        <div className="SubPgsContent-title">
          <h3>{data.title}</h3>
        </div>
        <div className="SubPgsContent-bio-description">
          <span>
            {data.bio}
            <br />
            {data.bio2 ? <span>{data.bio2}</span> : ''}
          </span>
          <div className="SubPgsContent-bio-bar" />
        </div>
        <div className="SubPgsContent-blurb-description">
          <p>{data.description}</p>
        </div>
      </div>
    </section>
  );
};

export default BottomBlurb;
