import './footer.scss';

const Footer = () => {
  return (
    <footer className="discovery-footer-nav">
      <div className="inner-wrapper">
        <nav className="discovery-footer-nav-menu">
          <ul>
            <li>
              <a href="https://www.discovery.com/site-map" target="_blank" rel="noreferrer">
                Site Map
              </a>
            </li>
            <li>
              <a
                href="https://corporate.discovery.com/visitor-agreement"
                target="_blank"
                rel="noreferrer"
              >
                Visitor Agreement
              </a>
            </li>
            <li>
              <a
                href="https://corporate.discovery.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Notice
              </a>
            </li>
            <li>
              <a
                href="https://info.evidon.com/pub_info/1212?v=1&nt=1&nw=false"
                target="_blank"
                rel="noreferrer"
              >
                AdChoices
              </a>
            </li>
            <li>
              <a href="https://corporate.discovery.com/" target="_blank" rel="noreferrer">
                About
              </a>
            </li>
            <li>
              <a
                href="https://corporate.discovery.com/contact/advertising"
                target="_blank"
                rel="noreferrer"
              >
                Advertise
              </a>
            </li>
            <li>
              <a href="https://corporate.discovery.com/careers" target="_blank" rel="noreferrer">
                Careers at Discovery
              </a>
            </li>
            <li>
              <a href="http://www.tvguidelines.org/ratings.html" target="_blank" rel="noreferrer">
                TV Ratings
              </a>
            </li>
            <li>
              <a href="https://help.discovery.com/" target="_blank" rel="noreferrer">
                Help
              </a>
            </li>
            <li>
              <a
                href="https://corporate.discovery.com/privacy-policy/#cappi"
                target="_blank"
                rel="noreferrer"
              >
                California Privacy Notice
              </a>
            </li>
            <li>
              <a
                href="https://corporate.discovery.com/website-accessibility-statement/"
                target="_blank"
                rel="noreferrer"
              >
                Accessibility
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
