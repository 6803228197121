import React from 'react';
import Numbers from '../Numbers/Numbers';
import './qaNav.scss';
import { contentsData } from '../contentslData';

function QANav({ showMenu, qaNavRef, collapsedMenuRef, expandedMenuRef, handleMenu }) {
  return (
    <div className="qaNav" ref={qaNavRef}>
      {showMenu ? (
        <div className="qaNav__expanded" ref={expandedMenuRef}>
          <svg
            width="43"
            height="43"
            viewBox="0 0 43 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="qaNav__expanded__exit"
            onClick={() => {
              handleMenu();
            }}
          >
            <circle
              cx="21.269"
              cy="21.916"
              r="20.5"
              stroke="white"
              className="qaNav__expanded__exit__stroke"
            />
            <mask id="path-2-inside-1_223_781" fill="white" className="qaNav__expanded__exit__path">
              <path d="M15.269 17.0332L16.3862 15.916L21.2547 20.7845L26.1519 15.916L27.269 17.0332L22.4005 21.9017L27.269 26.7988L26.1519 27.916L21.2547 23.0475L16.3862 27.916L15.269 26.7988L20.1375 21.9017L15.269 17.0332Z" />
            </mask>
            <path
              d="M15.269 17.0332L16.3862 15.916L21.2547 20.7845L26.1519 15.916L27.269 17.0332L22.4005 21.9017L27.269 26.7988L26.1519 27.916L21.2547 23.0475L16.3862 27.916L15.269 26.7988L20.1375 21.9017L15.269 17.0332Z"
              fill="white"
              className="qaNav__expanded__exit__path"
            />
            <path
              d="M15.269 17.0332L15.1276 16.8918L14.9862 17.0332L15.1276 17.1746L15.269 17.0332ZM16.3862 15.916L16.5276 15.7746L16.3862 15.6332L16.2448 15.7746L16.3862 15.916ZM21.2547 20.7845L21.1133 20.9259L21.2543 21.0669L21.3957 20.9264L21.2547 20.7845ZM26.1519 15.916L26.2933 15.7746L26.1523 15.6336L26.0109 15.7742L26.1519 15.916ZM27.269 17.0332L27.4105 17.1746L27.5519 17.0332L27.4105 16.8918L27.269 17.0332ZM22.4005 21.9017L22.2591 21.7603L22.1181 21.9013L22.2587 22.0427L22.4005 21.9017ZM27.269 26.7988L27.4105 26.9403L27.5515 26.7992L27.4109 26.6578L27.269 26.7988ZM26.1519 27.916L26.0109 28.0578L26.1523 28.1984L26.2933 28.0574L26.1519 27.916ZM21.2547 23.0475L21.3957 22.9057L21.2543 22.7651L21.1133 22.9061L21.2547 23.0475ZM16.3862 27.916L16.2448 28.0574L16.3862 28.1989L16.5276 28.0574L16.3862 27.916ZM15.269 26.7988L15.1272 26.6578L14.9866 26.7992L15.1276 26.9403L15.269 26.7988ZM20.1375 21.9017L20.2794 22.0427L20.42 21.9013L20.279 21.7603L20.1375 21.9017ZM15.4105 17.1746L16.5276 16.0574L16.2448 15.7746L15.1276 16.8918L15.4105 17.1746ZM16.2448 16.0574L21.1133 20.9259L21.3962 20.6431L16.5276 15.7746L16.2448 16.0574ZM21.3957 20.9264L26.2929 16.0579L26.0109 15.7742L21.1137 20.6427L21.3957 20.9264ZM26.0104 16.0574L27.1276 17.1746L27.4105 16.8918L26.2933 15.7746L26.0104 16.0574ZM27.1276 16.8918L22.2591 21.7603L22.542 22.0431L27.4105 17.1746L27.1276 16.8918ZM22.2587 22.0427L27.1272 26.9398L27.4109 26.6578L22.5424 21.7607L22.2587 22.0427ZM27.1276 26.6574L26.0104 27.7746L26.2933 28.0574L27.4105 26.9403L27.1276 26.6574ZM26.2929 27.7742L21.3957 22.9057L21.1137 23.1893L26.0109 28.0578L26.2929 27.7742ZM21.1133 22.9061L16.2448 27.7746L16.5276 28.0574L21.3962 23.1889L21.1133 22.9061ZM16.5276 27.7746L15.4105 26.6574L15.1276 26.9403L16.2448 28.0574L16.5276 27.7746ZM15.4109 26.9398L20.2794 22.0427L19.9957 21.7607L15.1272 26.6578L15.4109 26.9398ZM20.279 21.7603L15.4105 16.8918L15.1276 17.1746L19.9961 22.0431L20.279 21.7603Z"
              fill="white"
              mask="url(#path-2-inside-1_223_781)"
              className="qaNav__expanded__exit__path"
            />
          </svg>

          <h3 className="qaNav__expanded__head">Top Questions</h3>
          <span className="qaNav__expanded__subHead">Click to jump to questions</span>
          <menu className="qaNav__expanded__list">
            {contentsData.menu.map((data) => {
              return (
                <li className="qaNav__expanded__list__items" key={data.number}>
                  <a
                    href={`#qa-${data.number}`}
                    className="qaNav__expanded__list__items--link"
                    onClick={() => {
                      handleMenu();
                    }}
                  >
                    <Numbers origin="menu" number={data.number} />{' '}
                    <span className="qaNav__expanded__list__items--link__text">{data.q}</span>
                  </a>
                </li>
              );
            })}
          </menu>
        </div>
      ) : (
        <h3
          className="qaNav__collapsed"
          onClick={() => {
            handleMenu();
          }}
          ref={collapsedMenuRef}
        >
          jump to other questions{' '}
          <svg
            width="23"
            height="12"
            viewBox="0 0 23 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="qaNav__collapsed__down"
          >
            <path
              d="M2.00987 0.00105776C1.67929 0.00105776 1.34346 0.129417 1.09201 0.380862C0.58912 0.883757 0.58912 1.71458 1.09201 2.21657L10.4956 11.6202C10.7383 11.8628 11.0812 12 11.4135 12C11.7432 12 12.0711 11.8804 12.3313 11.6202L21.7228 2.22873C22.2486 1.73375 22.2556 0.882699 21.7527 0.380705C21.2498 -0.12219 20.419 -0.12219 19.917 0.380705L11.4312 8.88315L2.92786 0.379805C2.67642 0.128357 2.34057 0 2.01001 0L2.00987 0.00105776Z"
              fill="white"
              fillOpacity="0.8"
              className="qaNav__collapsed__down__path"
            />
          </svg>
        </h3>
      )}
    </div>
  );
}

export default QANav;
