import React from 'react';
import './stageHero.scss';
import { stageData } from '../../stageData';

function StageHero({ index, reference }) {
  return (
    <div className={`stageHero stageHero--${index}`} ref={reference}>
      <img className="stageHero__icon" src={stageData[index].icon} alt="" />
      <div className="stageHero__stage">Stage {index + 1}</div>
      <h3>{stageData[index].head}</h3>
      <p>{stageData[index].copy}</p>
    </div>
  );
}

export default StageHero;
