import React, { useRef, useLayoutEffect, useContext, useState, useEffect } from 'react';
import { gsap } from '../../../../../gsap';
import MapDots from '../../../Common/MapDots/MapDots';
import './sequence2.scss';
import { ReactComponent as Virus } from './Virus.svg';
import pcrF from '../../../../../assets/interactive/stage2/pcr-f.png';
import pcrB from '../../../../../assets/interactive/stage2/pcr-b.png';
import antigenF from '../../../../../assets/interactive/stage2/antigen-f.png';
import antigenB from '../../../../../assets/interactive/stage2/antigen-b.png';
import { WidthContext } from '../../../../../WidthContext';
import arrow from '../../../../../assets/interactive/intro/scroll.svg';

function Sequence2() {
  const [drawer1Open, setDrawer1Open] = useState(false);
  const [drawer2Open, setDrawer2Open] = useState(false);
  const [distance, setDistance] = useState();
  const sequence2Text = useRef();
  const sequence2Text2 = useRef();
  const sequence2Text3 = useRef();
  const sequence2Text4 = useRef();
  const sequence2Text5 = useRef();
  const sequence2Text6 = useRef();
  const sequence2Text7 = useRef();
  const sequence2Text8 = useRef();
  const sequence2Section = useRef();
  const mapContainerRef = useRef();
  const drawer1Ref = useRef();
  const drawer2Ref = useRef();
  const drawer1CopyRef = useRef();
  const drawer2CopyRef = useRef();
  const drawer1ArrowRef = useRef();
  const drawer2ArrowRef = useRef();
  const upper1Ref = useRef();
  const lower1Ref = useRef();
  const lower2Ref = useRef();
  const lottieRef = useRef();
  const animSequence = useRef();
  const pcrHideRef = useRef();
  const antigenHideRef = useRef();
  const pcrRef = useRef();
  const antigenRef = useRef();
  const antigenCellRef = useRef();
  const antigenHeadRef = useRef();
  const animRef = useRef();
  const animHideRef = useRef();
  const wrapperRef = useRef();
  const { width } = useContext(WidthContext);

  const handleDrawer = (action, drawer) => {
    let currentDrawer = drawer === 'pcr' ? drawer1Ref.current : drawer2Ref.current;
    let currentCopy = drawer === 'pcr' ? drawer1CopyRef.current : drawer2CopyRef.current;
    let currentArrow = drawer === 'pcr' ? drawer1ArrowRef.current : drawer2ArrowRef.current;
    if (action) {
      gsap.to(currentDrawer, { height: '100%', duration: 0.7 });
      gsap.to(currentCopy, { opacity: 1, duration: 0.7, delay: 0.7 });
      gsap.to(currentArrow, { rotate: 180, duration: 0.7 });
    } else {
      gsap.to(currentCopy, { opacity: 0, duration: 0.7 });
      gsap.to(currentDrawer, { height: '15%', duration: 0.7, delay: 0.7 });
      gsap.to(currentArrow, { rotate: 0, duration: 0.7 });
    }
    drawer === 'pcr' ? setDrawer1Open(action) : setDrawer2Open(action);
  };

  useEffect(() => {
    const gap = width > 1023 ? 30 : 15;
    const cardWidth = antigenHideRef.current.offsetWidth;
    const cellWidth = antigenCellRef.current.offsetWidth;
    const scaled = width > 1023 ? cellWidth * 1.5 : cellWidth * 2;
    const currentDistance = ((cardWidth - scaled) / 2 + gap + scaled / 2) * -1;
    setDistance(currentDistance);
  }, [width]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: sequence2Section.current,
          start: () => 'top 50%',
          end: () => 'top top',
          scrub: true
        }
      });

      tl.to(upper1Ref.current, {
        opacity: 1,
        y: 0,
        duration: 2
      }).to(mapContainerRef.current, {
        opacity: 1,
        duration: 1,
        onStart: () => {
          lottieRef.current.goToAndPlay(0, true);
        }
      });

      const pin = gsap.timeline({
        scrollTrigger: {
          trigger: sequence2Section.current,
          start: () => 'top top',
          end: () => 'bottom-=300 bottom',
          pin: wrapperRef.current,
          scrub: true
        }
      });

      const tl1 = gsap.timeline();

      tl1
        .to(lower1Ref.current, {
          opacity: 1,
          y: 0,
          duration: 2
        })
        .to(
          upper1Ref.current,
          {
            opacity: 0,
            duration: 1
          },
          '>10'
        )
        .to(
          lower1Ref.current,
          {
            opacity: 0,
            duration: 1
          },
          '<'
        )
        .to(lower2Ref.current, {
          opacity: 1,
          y: 0,
          duration: 2
        })
        .to(mapContainerRef.current, { opacity: 0, visibility: 'hidden', duration: 2 }, '>10')
        .to(animSequence.current, { opacity: 1, duration: 1 })
        .to(sequence2Text.current, { opacity: 1, y: 0, duration: 2 })
        .to(sequence2Text.current, { opacity: 0, duration: 1 }, '>10')

        .to(antigenRef.current, { rotateY: 180, duration: 5 })
        .to(pcrRef.current, { rotateY: 180, duration: 5 }, '<')
        .to(sequence2Text2.current, { opacity: 1, y: 0, duration: 2 })
        .to(animHideRef.current, { opacity: 1, duration: 1 }, '>10')
        .to(
          animRef.current,
          {
            opacity: 0,
            duration: 1
          },
          '<'
        )

        .to(
          sequence2Text2.current,
          {
            opacity: 0,
            duration: 1
          },
          '>15'
        )
        .to(drawer1Ref.current, { height: '100%', duration: 0.7 }, '<')
        .to(drawer2Ref.current, { height: '100%', duration: 0.7 }, '<')
        .to(drawer1CopyRef.current, { opacity: 1, duration: 0.7 })
        .to(drawer2CopyRef.current, { opacity: 1, duration: 0.7 }, '<')
        .to(drawer1ArrowRef.current, { rotate: 180, duration: 0.7 }, '<')
        .to(
          drawer2ArrowRef.current,
          {
            rotate: 180,
            duration: 0.5,
            onComplete: () => {
              setDrawer1Open(true);
              setDrawer2Open(true);
            }
          },
          '<'
        )

        .to(sequence2Text3.current, { opacity: 1, y: 0, duration: 2 })
        .to(sequence2Text3.current, { opacity: 0, duration: 1 }, '>10')

        .to(sequence2Text4.current, {
          opacity: 1,
          y: 0,
          duration: 2
        })
        .to(drawer1CopyRef.current, { opacity: 0, duration: 0.7 }, '<')
        .to(drawer2CopyRef.current, { opacity: 0, duration: 0.7 }, '<')
        .to(drawer1Ref.current, { height: '15%', duration: 0.7 })
        .to(drawer2Ref.current, { height: '15%', duration: 0.7 }, '<')
        .to(drawer1ArrowRef.current, { rotate: 0, duration: 0.7 }, '<')
        .to(
          drawer2ArrowRef.current,
          {
            rotate: 0,
            duration: 0.5,
            onComplete: () => {
              setDrawer1Open(false);
              setDrawer2Open(false);
            }
          },
          '<'
        )
        .to(sequence2Text4.current, { opacity: 0, duration: 1 }, '<10')
        .to(sequence2Text5.current, { opacity: 1, y: 0, duration: 2 })
        .to(sequence2Text5.current, { opacity: 0, duration: 1 }, '>10')
        .to(pcrHideRef.current, { opacity: 0, duration: 5 })
        .to(drawer1Ref.current, { opacity: 0, duration: 1 }, '<')
        .to(drawer2Ref.current, { opacity: 0, duration: 1 }, '<')
        .to(antigenHideRef.current, { background: 'transparent', duration: 5 }, '<')

        .to(sequence2Text6.current, { opacity: 1, y: 0, duration: 2 }, '<')
        .to(sequence2Text6.current, { opacity: 0, duration: 1 }, '>10')
        .to(antigenHeadRef.current, { opacity: 0, duration: 1 }, '<')

        .to(sequence2Text7.current, { opacity: 1, y: 0, duration: 2 })
        .to(
          antigenCellRef.current,
          {
            x: width > 480 ? distance : 0,
            y: width > 480 ? '-10%' : '-100%',
            scale: width > 1023 ? 1.5 : 2,
            duration: 5
          },
          '<'
        )
        .to(sequence2Text8.current, { opacity: 1, y: 0, duration: 2 });

      pin.add(tl1);
    });
    return () => ctx.revert();
  }, [width, distance]);

  return (
    <section className="sequence2" ref={sequence2Section}>
      <div className="sequence2-wrapper" ref={wrapperRef}>
        <MapDots
          mapContainerRef={mapContainerRef}
          upper1Ref={upper1Ref}
          lower1Ref={lower1Ref}
          lower2Ref={lower2Ref}
          lottieRef={lottieRef}
          index={1}
        />

        <div className="sequence2__tests" ref={animSequence}>
          <div className="sequence2__copy">
            <p className="sequence2__copy__text" ref={sequence2Text}>
              With your help, the Coalition decides to parallel path on developing two common tests:
            </p>
            <p className="sequence2__copy__text" ref={sequence2Text2}>
              Each detects a different part of the virus, which in turn influences the test’s speed
              and sensitivity.
            </p>
            <p className="sequence2__copy__text" ref={sequence2Text3}>
              Both tests are important in aiding the detection efforts that can help slow outbreaks
              before they become pandemics.
            </p>
            <p className="sequence2__copy__text" ref={sequence2Text4}>
              Any mutations in the underlying genetic code can impact the effectiveness of a PCR
              test — and have a possible downstream effect on the antigens targeted by an antigen
              test, too.
            </p>
            <p className="sequence2__copy__text" ref={sequence2Text5}>
              In the following sections, you’ll jump back and forth between PCR tests and antigen
              tests, not necessarily in chronological order, to learn more about the process — and
              challenges — behind the development of both of these important diagnostics.
            </p>
            <p className="sequence2__copy__text" ref={sequence2Text6}>
              We’ll start with one of the most complicated questions in diagnostics: Which antigens
              should you target for an antigen test?
            </p>
            <p className="sequence2__copy__text" ref={sequence2Text7}>
              Hard-won research has shown that certain areas on a virus tend to be more prone to
              mutation — in particular, where the virus comes into direct contact with human cells
              and the human immune system.
            </p>
          </div>

          <div className="sequence2__animation">
            <div className="sequence2__animation-wrapper" ref={animRef}>
              <div className="sequence2__card">
                <div className="sequence2__card-inner" ref={pcrRef}>
                  <div className="sequence2__card__contents sequence2__card__contents-f">
                    <h5 className="sequence2__card__head">PCR test</h5>
                    <img src={pcrF} alt="" className="sequence2__card__icon" />
                  </div>
                  <div className="sequence2__card__contents sequence2__card__contents-b">
                    <h5 className="sequence2__card__head">PCR test</h5>

                    <Virus className="sequence2__card__icon" />
                  </div>
                </div>
              </div>

              <div className="sequence2__card">
                <div className="sequence2__card-inner" ref={antigenRef}>
                  <div className="sequence2__card__contents sequence2__card__contents-f">
                    <h5 className="sequence2__card__head">Antigen test</h5>
                    <img src={antigenF} alt="" className="sequence2__card__icon" />
                  </div>
                  <div className="sequence2__card__contents sequence2__card__contents-b">
                    <h5 className="sequence2__card__head">Antigen test</h5>

                    <Virus className="sequence2__card__icon" />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="sequence2__animation-wrapper sequence2__animation--color"
              ref={animHideRef}
            >
              <div className="sequence2__card">
                <div className="sequence2__card__contents" ref={pcrHideRef}>
                  <h5 className="sequence2__card__head">pcr test</h5>
                  <img src={pcrB} alt="" className="sequence2__card__icon" />
                </div>
                <div className="sequence2__card__contents__drawer">
                  <div className="sequence2__card__contents__drawer-contents" ref={drawer1Ref}>
                    <img
                      ref={drawer1ArrowRef}
                      className="sequence2__card__contents__drawer__arrow"
                      alt=""
                      src={arrow}
                      onClick={() => {
                        handleDrawer(!drawer1Open, 'pcr');
                      }}
                    />
                    <p ref={drawer1CopyRef}>
                      A PCR (polymerase chain reaction) test targets the genomic sequence at the
                      heart of the virus. PCR tests tend to be created first and deployed more
                      quickly when compared to other diagnostic tests.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sequence2__card">
                <div className="sequence2__card__contents" ref={antigenHideRef}>
                  <h5 className="sequence2__card__head" ref={antigenHeadRef}>
                    Antigen test
                  </h5>
                  <img
                    ref={antigenCellRef}
                    src={antigenB}
                    alt=""
                    className="sequence2__card__icon"
                  />
                </div>
                <div className="sequence2__card__contents__drawer">
                  <div className="sequence2__card__contents__drawer-contents" ref={drawer2Ref}>
                    <img
                      ref={drawer2ArrowRef}
                      className="sequence2__card__contents__drawer__arrow"
                      alt=""
                      src={arrow}
                      onClick={() => {
                        handleDrawer(!drawer2Open, 'antigen');
                      }}
                    />
                    <p ref={drawer2CopyRef}>
                      An antigen test targets the antigens: molecular structures, usually proteins
                      or sugars, which are found on the surface of viruses. Antigens are recognized
                      by our immune system, and trigger our body’s immune response: the creation of
                      antibodies. Antigen tests typically have longer and more complicated
                      development cycles.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sequence2__copy sequence2__copy-lower">
            <p className="sequence2__copy__text" ref={sequence2Text8}>
              To evade our immune system, viruses evolve — and if the antigens your test targets
              change or disappear, the test you create might no longer work. That’s why makers of
              diagnostic tests, like Abbott, focus their assays on antigens that are relatively
              stable and less likely to change as new mutations and variants arise.  
              <br />
              <br />
              So choose wisely.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sequence2;
