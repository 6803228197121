import { useLayoutEffect, useRef } from 'react';
import './paragraphWLottie.scss';
import LottieLoop from '../../../Common/LottieLoop/LottieLoop';
import computerLottie from '../../../../../assets/interactive/stage1/computerShare.json';

const ParagraphWLottie = () => {
  const containerRef = useRef();
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['intersectionRatio'] > 0.5) {
            entry.target.style.opacity = 1;
          }
        });
      },
      { threshold: [0, 0.5, 1] }
    );
    observer.observe(containerRef.current);
  }, []);
  return (
    <div className="paragraphWLottie" ref={containerRef}>
      <div className="paragraphWLottie__textCont">
        <p>You’ve sequenced the genome and identified the type of virus you’re dealing with.</p>
        <p>But there are still crucial questions you don’t have answers for. </p>
        <p>How deadly is this virus?  </p>
        <p>How easily is it transmitted?  </p>
        <p>How far has it circulated already?  </p>
        <p>You can’t find answers on your own. You need help. </p>
        <p>
          You share the genome sequence of this new virus with the world — and help kick start
          further research. 
        </p>
      </div>
      <div className="paragraphWLottie__lottieCont">
        <LottieLoop lottie={computerLottie} />
      </div>
    </div>
  );
};

export default ParagraphWLottie;
