import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, discoveryNavRef, discoveryHeaderRef }) => {
  return (
    <>
      <Header discoveryNavRef={discoveryNavRef} discoveryHeaderRef={discoveryHeaderRef} />
      <>{children}</>
      <Footer />
    </>
  );
};

export default Layout;
