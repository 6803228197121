import React, { Fragment } from 'react';

import './progress.scss';

function Progress({ origin, stage, containerRef, animateRef, allRefs }) {
  return (
    <div className={`progress progress-${origin}`} ref={containerRef}>
      {[...Array(4)].map((_, i) => (
        <Fragment key={i}>
          <div
            className={`progress__number progress__number-${origin}`}
            ref={allRefs ? allRefs.current[i][0] : null}
          >
            <div className={`progress__number__circle progress__number__circle-${origin}`}>
              <svg
                className="progress__number__circle__svg"
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {stage > i && (
                  <>
                    <circle cx="24.3875" cy="24.0005" r="23.4033" fill="white" />
                    <circle cx="24.3875" cy="24.0005" r="23.4033" fill="#22BFF8" />
                  </>
                )}

                <circle
                  cx="24.3875"
                  cy="24.0005"
                  r="23.4033"
                  stroke="#AAAAAA"
                  strokeWidth="1.19336"
                />
                <circle
                  cx="24.3875"
                  cy="24.0005"
                  r="23.4033"
                  stroke="#073458"
                  strokeWidth="1.19336"
                />
                <circle
                  cx="24.3875"
                  cy="24.0005"
                  r="23.4033"
                  stroke="#3377A4"
                  strokeWidth="1.19336"
                />
                <circle
                  cx="24.3875"
                  cy="24.0005"
                  r="23.4033"
                  stroke="#22BFF8"
                  strokeWidth="1.19336"
                />
              </svg>
            </div>
            <span
              className={
                i < stage
                  ? `progress__number__text progress__number__text-${origin} progress__number__text--active`
                  : `progress__number__text progress__number__text-${origin}`
              }
            >
              {i + 1}
            </span>
          </div>
          {i < 3 ? (
            <div className={`progress__bar progress__bar-${origin}`}>
              <div
                className={`progress__bar-base progress__bar-base-${origin}`}
                ref={allRefs ? allRefs.current[i][1] : null}
              />
              <div
                className={
                  i <= stage - 2 ? 'progress__bar-progress' : `progress__bar-base-${origin}-top`
                }
              />
              {i === stage - 1 ? <div className="progress__bar-animate" ref={animateRef} /> : <></>}
            </div>
          ) : (
            ''
          )}
        </Fragment>
      ))}
    </div>
  );
}

export default Progress;
