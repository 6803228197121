import './genomes.scss';

const Genomes = ({ height }) => {
  return (
    <div className={`genomes-container genomes-${height}`}>
      {/* Create empty array and iterate */}
      {Array.from(Array(16), (e, i) => {
        return <div className={`genome genome-${i}`} key={i}></div>;
      })}
    </div>
  );
};

export default Genomes;
