import gavin from '../../assets/qa/carousel/gavin.png';
import alan from '../../assets/qa/carousel/alan.png';
import pontiano from '../../assets/qa/carousel/pontiano.png';
import dora from '../../assets/qa/carousel/dora.png';
import tulio from '../../assets/qa/carousel/tulio.png';
import melody from '../../assets/qa/carousel/melody.png';
import jorge from '../../assets/qa/carousel/jorge.png';
import mary from '../../assets/qa/carousel/mary.png';

export const huntersData = {
  gavin: {
    name: 'Gavin Cloherty',
    photo: gavin,
    class: 'gavin'
  },
  dora: {
    name: 'Dora Mbanya',
    photo: dora,
    class: 'dora'
  },
  pontiano: {
    name: 'Pontiano Kaleebu',
    photo: pontiano,
    class: 'pontiano'
  },
  melody: {
    name: 'Mélody Achille',
    photo: melody,
    class: 'melody'
  },
  alan: {
    name: 'Alan Landay',
    photo: alan,
    class: 'alan'
  },
  tulio: {
    name: 'Tulio de Oliveira',
    photo: tulio,
    class: 'tulio'
  },

  jorge: {
    name: 'Jorge Osorio',
    photo: jorge,
    class: 'jorge'
  },
  mary: {
    name: 'Mary Rodgers',
    photo: mary,
    class: 'mary'
  }
};
