const articles = {
  southAfrica: [
    {
      title: 'Inside the race to sequence a virus variant',
      description: '',
      image: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/sa/sa1.jpg',
      position: 'left'
    },
    {
      title: 'Collection',
      description:
        'In places like South Africa, which already has a robust on-the-ground surveillance system carried over from the HIV crisis, samples are collected regularly from across the country, then sent to labs for testing.',
      image: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/sa/sa2.jpg',
      position: 'left'
    },
    {
      title: 'Sequencing',
      description:
        'In labs like the ones at the Centre for Epidemic Response and Innovation (CERI), the complete genome sequences of pathogens are generated from the collected samples. These genomes are then checked against a database of known viruses — and new or unusual pathogens are recorded for further study.',
      image: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/sa/sa3.jpg',
      position: 'left'
    },
    {
      title: 'Develop and deploy',
      description:
        'New pathogens may require new diagnostic tests, which can be developed from the initial samples and genome sequencing in the previous steps. If needed, these tests can be manufactured and deployed around the world to help detect and slow the spread of pathogens.',
      image: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/sa/sa4.jpg'
    },
    {
      title: 'Research and prevention',
      description:
        'Armed with diagnostic tests, researchers can then conduct initial assessments of how many people are affected, identify where the new pathogen has spread, and establish risk factors that may make people more vulnerable to the disease. This crucial fact-finding stage then helps the public health community take appropriate responses in the face of outbreaks.',
      image: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/sa/sa5.jpg'
    },
    {
      title: 'Time is of the essence. Earlier detection gives the world a head start.',
      description: '',
      image: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/sa/sa6.jpg',
      position: 'right'
    }
  ],
  india: [
    {
      title: 'A day in the life of a laborer',
      description: '',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/india/india1.jpg',
      position: 'right'
    },
    {
      title: 'The struggle to find work',
      description:
        'For impoverished day laborers like Govind, the day begins early. At meeting places like the Chawri Bazar, employers gather in the morning to connect with workers for daily work contracts. For those who do not get work for the day, a few rupees can be made gathering recyclable items and delivering them to local recycling centers.',
      image: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/india/india2.jpg'
    },
    {
      title: 'The challenges of substance use',
      description:
        'After delivering their loads of recycling, day laborers like Govind gather near recycling centers for social connection, gossip, and camaraderie. They often also come into contact with people who inject drugs here. Sharing injection paraphernalia can be common — a prime risk behavior for the transmission of HIV, hepatitis and other blood-borne infections.',
      image: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/india/india3.jpg'
    },
    {
      title: 'The need for services',
      description:
        'Often fearing discrimination and afraid of stigma, people who inject drugs can find themselves cut off from services and support. Non-governmental organizations like YRGCARE seek out this vulnerable population where they live, conducting field-based testing, connecting them to local specialized clinics for further care, and facilitating relationships with outreach workers like Deepa who can walk alongside people like Govind on the road to recovery.',
      image: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/india/india4.jpg'
    },
    {
      title: 'The quest to stop transmission',
      description:
        'YRGCARE outreach workers like Deepa help people like Govind access health services, including testing and treatment. Samples from the clinic are also sent to YRGCARE lab in Chennai, where viruses can be closely analyzed and tracked for mutations and lineages. These insights help YRGCARE and the Coalition better understand networks of transmissions and how to develop interventions to help reduce the spread of infectious diseases.',
      image: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/india/india5.jpg'
    },
    {
      title: 'Interventions can help save lives and reduce transmissions',
      description: '',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/india/india6.jpg',
      position: 'right'
    }
  ],
  unitedStates: [
    {
      title: 'How the city of the future will fight viruses',
      description: '',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/usa/abbott-usa-carousel-1.jpg',
      position: 'right'
    },
    {
      title: 'Wastewater',
      description:
        'In the age of AI and big data, virus surveillance of the future will incorporate many data sets and disciplines to create a holistic dashboard for pandemic defense. One of these data sources is wastewater from a community’s pipes, toilets, and drains. By testing wastewater for viruses and other pathogens, and comparing the results against historical baselines, public health officials can capture a “snapshot” of the infectious diseases circulating in large populations — and sound an early-warning alarm bell, if necessary.',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/usa/abbott-usa-carousel-2.jpg'
    },
    {
      title: 'Animals',
      description:
        'Many infectious diseases spill over from animals to humans and humans to animals. These zoonotic diseases represent a significant percentage of the pathogens that impact human health. When new viruses jump from animals to humans, the results can be dire, especially in urban environments where people live in close proximity to creatures like rats, dogs, and birds. By systematically collecting tissue samples from the animals that live among us — and among whom we live — researchers can keep tabs on the potential emergence of new viruses and transmission routes.',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/usa/abbott-usa-carousel-3.jpg'
    },
    {
      title: 'Electronic medical records',
      description:
        'When patients arrive in the emergency rooms or doctor’s offices of the future, important information about their symptoms, history, and vitals could be recorded in secure electronic databases. Unusual or concerning symptoms and lab results could be automatically flagged using artificial intelligence, and clusters of similar cases could trigger alerts that indicate the potential spread of virus outbreaks in the community — and mark new, potentially unknown pathogens for further analysis.',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/usa/abbott-usa-carousel-4.jpg'
    },
    {
      title: 'Air Monitoring',
      description:
        'Air filtration systems in emergency rooms or community centers like nursing homes could be monitored for viruses using sophisticated sensors that look for viral genetic material or other markers. If a viral signature is detected, researchers could conduct further analysis to determine if pathogens are circulating within the population. Handheld devices could test individual breath samples for further analysis. ',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/usa/abbott-usa-carousel-5.jpg'
    },
    {
      title: 'Software',
      description:
        'All these streams of monitoring data — from wastewater, animals, clinical data, and air sensors — could be bundled together into one common database, where powerful artificial intelligence could flag emerging viral threats before they escalate into outbreaks. Today, sophisticated software from Abbott can also take the complex next generation sequencing datasets generated through each of these monitoring streams and compare them to the sequences of known viruses to identify what is circulating and flag anything new that is emerging.',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/usa/abbott-usa-carousel-6.jpg'
    },
    {
      title:
        'We can’t fight what we can’t see. Tools and technology help us identify emerging viral threats.',
      description: '',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/usa/abbott-usa-carousel-7.jpg',
      position: 'right'
    }
  ],
  colombia: [
    {
      title: 'On the hunt for Oropouche',
      description: '',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/colombia/abbott-labs-colombia-carousel-1.jpg',
      position: 'right'
    },
    {
      title: 'An unknown cause of fever illness',
      description:
        'In tropical countries like Colombia, and especially in remote communities on the edge of the Amazon jungle, mosquito-borne fever illnesses are relatively common and cause widespread suffering and death. The usual suspects often include dengue, malaria, yellow fever, and Zika. Correct diagnosis is crucial to treating the disease.So when Jorge and his team received notification of large numbers of people with undiagnosed fever illnesses, they were puzzled. What unknown pathogen could be causing these illnesses?',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/colombia/abbott-labs-colombia-carousel-2.jpg'
    },
    {
      title: 'The scientific detective work begins',
      description:
        'The team got to work collecting blood samples for research purposes from patients across Colombia – a sufficiently large number to conduct a rigorous analysis. At the genomics lab in Medellín, these samples were tested with more sensitive laboratory equipment and against a wider range of possible fever illnesses. But the results still came back inconclusive. The unknown pathogen remained elusive.',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/colombia/abbott-labs-colombia-carousel-3.jpg',
      position: 'left30'
    },
    {
      title: 'Finding a needle in a haystack',
      description:
        'Next, Jorge and his team sent the blood samples to Abbott headquarters just north of Chicago, where they could be analyzed further by the scientists there. Here, the team ran the billions of DNA or RNA bases found in these samples through a state-of-the-art process that flags the presence of genetic markers which serve as “signatures” for virtually every known disease in the world. In one sample the Abbott scientists finally identified a possible candidate: Oropouche.',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/colombia/abbott-labs-colombia-carousel-4.jpg'
    },
    {
      title: 'Uncovering an emerging outbreak',
      description:
        'Prior to 2017, only one confirmed case of Oropouche fever – an acute dengue-like virus that can cause fever, headache, and muscle and joint pain – had been documented in Colombia. So when Jorge and his team got the news, they were surprised. Based on the sample detected by Abbott, the team was able to conduct further analysis to determine if the blood samples in their collection had been exposed to the virus in the past. When they re-tested their samples, a large percentage of them indicated previous exposure. As a result, Jorge and his team were able to show that Oropouche was circulating in Colombia.',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/colombia/abbott-labs-colombia-carousel-5.jpg'
    },
    {
      title: 'The road ahead',
      description:
        'Oropouche is still little known and understudied. To better understand its cycle of transmission, the vectors by which it is spread, and its impact on human health, more research is needed. In partnership with the Abbott Pandemic Defense Coalition, Jorge and his team continue to study which mosquito and black midge species are behind Oropouche transmission. And they also hope to develop future tools and insights to better aid in detection and slow transmission of this virus.',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/colombia/abbott-labs-colombia-carousel-6.jpg'
    },
    {
      title: 'Global collaboration is key to stopping history from repeating itself.',
      description: '',
      image:
        'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/carousel/colombia/abbott-labs-colombia-carousel-7.jpg',
      position: 'left'
    }
  ]
};

export default articles;
