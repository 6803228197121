import { useEffect, useRef, useContext } from 'react';
import { ReactComponent as Markers } from '../../../assets/hero/Markers.svg';
import Genomes from '../Genomes';
import heroVideo from '../../../assets/hero/desktop-top-of-page_TxpgjhP4.mp4';
import mobileHeroVideo from '../../../assets/hero/mobile-xl_QQITCb41.mp4';
import Lockup from '../../../assets/hero/the_virus_hunt.png';
import Map from '../../../assets/hero/map.png';
import { WidthContext } from '../../../WidthContext';

import './hero.scss';

const Hero = ({ animateRef }) => {
  const { viewSize } = useContext(WidthContext);
  const videoLoopRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            // Iterate over child nodes
            for (
              var child = animateRef.current.firstChild;
              child !== null;
              child = child.nextSibling
            ) {
              child.style.cssText = 'opacity: 1; transform: translateY(0)';
            }
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(animateRef.current);
  }, [animateRef]);

  useEffect(() => {
    //if width changes load the corresponding video
    //responsibly updates the video
    if (viewSize === 'small' || viewSize === 'large') {
      videoLoopRef.current.load();
    }
  }, [viewSize]);
  return (
    <section className="hero">
      <video
        muted
        autoPlay
        // loop
        playsInline
        className="hero__video hero__video-d"
        ref={videoLoopRef}
      >
        <source src={heroVideo} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
      <video
        muted
        autoPlay
        // loop
        playsInline
        className="hero__video hero__video-m"
        ref={videoLoopRef}
      >
        <source src={mobileHeroVideo} type="video/mp4" />
        Your browser does not support HTML video.
      </video>

      <div className="video-overlay"></div>
      <div className="inner-wrap">
        <div className="hero-lockup">
          <div className="hero-image">
            <img src={Lockup} alt="Abbott Virus Hunters Lockup" />
          </div>
        </div>
        <div className="hero-content animate-content" ref={animateRef}>
          <div className="genomes-left">
            <Genomes height={'medium'} />
            <Genomes height={'xlarge'} />
          </div>
          <div className="genomes-right">
            <Genomes height={'large'} />
            <Genomes height={'medium'} />
          </div>
          <div className="hero-description">
            <p>
              The Abbott Pandemic Defense Coalition is working nonstop at the front lines of virus
              surveillance and prevention to help stop outbreaks before they become pandemics.
            </p>
            <p>
              From the Amazon jungle to village townships of South Africa, to crowded urban centers
              in India and finally cutting-edge surveillance sites in Chicago, follow a group of
              Coalition experts as they race the clock to stay one step ahead of the next viral
              threat.
            </p>
          </div>
        </div>
      </div>
      <div className="hero-map">
        <img src={Map} alt="" />
        <div className="map-markers">
          <Markers />
        </div>
      </div>
    </section>
  );
};

export default Hero;
