import quoteImg from '../../assets/enhanced-contents/green-quote.png';

const Quote = {
  one: {
    img: quoteImg,
    desc:
      'In Delhi, especially among laborers who come to the city looking for jobs, many turn to drug use to cope with unemployment and homelessness. We have seen an extremely high burden of infection in these people. I’m committed to figuring out a solution to end this, and getting as many people as I can involved in trying to solve this problem.',
    author: 'Sunil Solomon'
  },
  two: {
    img: quoteImg,
    desc:
      "The biggest challenge is diagnostics, because most people don't get diagnosed until it's too late. How do we get diagnostic tools to the people who need them? How do you diagnose and treat people early enough to prevent pandemics from taking off? Working together to address these problems is a focus of the Abbott Pandemic Defense Coalition.",
    author: 'Sunil Solomon'
  }
};

export default Quote;
