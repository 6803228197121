import { useEffect, useRef, useState } from 'react';
import Player from '@vimeo/player';
import articleTeaser from '../../../assets/Teaser_Desktop_ew.mp4';
import mobileArticleTeaser from '../../../assets/Teaser_Mobile_Xl_ew.m4v';
import exit from '../../../assets/exit.svg';
import playIcon from '../../../assets/play.svg';
import { Link } from 'react-router-dom';

import './teaser.scss';

const Teaser = ({ animateRef, content }) => {
  const teaserModalRef = useRef();
  const closeRef = useRef();
  const teaserRef = useRef();
  const videoLoopRef = useRef();
  const [showTeaser, setShowTeaser] = useState(false);
  const { description, title, link, id } = content;
  useEffect(() => {
    teaserRef.current = new Player('teaserVideo', {
      id: '863204816',
      controls: true,
      frameborder: '0',
      allow: 'fullscreen; picture-in-picture',
      responsive: true
    });
  }, []);

  useEffect(() => {
    teaserRef.current.on('ended', () => {
      setShowTeaser(false);
    });
  }, [setShowTeaser]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement && showTeaser) {
        setShowTeaser(false);
      }
    });
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            // Iterate over child nodes
            for (
              var child = animateRef.current.firstChild;
              child !== null;
              child = child.nextSibling
            ) {
              child.style.cssText = 'opacity: 1; transform: translateY(0)';
            }
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(animateRef.current);
  }, [animateRef]);

  useEffect(() => {
    if (showTeaser) {
      teaserModalRef.current.style.visibility = 'visible';
      closeRef.current.style.visibility = 'visible';
      teaserRef.current.play();
    } else {
      teaserModalRef.current.style.visibility = 'hidden';
      closeRef.current.style.visibility = 'hidden';
      teaserRef.current.pause();
    }
  }, [showTeaser]);

  return (
    <section className="teaser" id={id}>
      <video
        muted
        autoPlay
        loop
        playsInline
        className="teaser-loop teaser-loop-d"
        ref={videoLoopRef}
      >
        <source src={articleTeaser} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
      <video
        muted
        autoPlay
        loop
        playsInline
        className="teaser-loop teaser-loop-m"
        ref={videoLoopRef}
      >
        <source src={mobileArticleTeaser} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
      <div className="teaser-wrapper">
        <div className="inner-wrapper">
          <div className="teaser-content animate-content" ref={animateRef}>
            <div className="teaser__eyebrow">
              <span>film</span>
            </div>
            <div className="teaser__title">
              <h2>{title}</h2>
            </div>
            <div className="teaser__description">
              <p>{description}</p>
            </div>
            <div className="teaser__buttons">
              <Link to={link} className="outline-button">
                <img src={playIcon} alt="" className="play-icon" />
                Watch Film
              </Link>
              <div
                className="outline-button"
                onClick={() => {
                  setShowTeaser(true);
                }}
              >
                <img src={playIcon} alt="" className="play-icon" />
                Watch Trailer
              </div>
            </div>
          </div>
        </div>
        <img
          className="film__close"
          src={exit}
          alt="Close video"
          ref={closeRef}
          onClick={() => {
            setShowTeaser(false);
          }}
        />
        <div className="film__teaserModal" ref={teaserModalRef}>
          <div ref={teaserRef} id="teaserVideo" className="teaser-video" />
        </div>
      </div>
    </section>
  );
};

export default Teaser;
