import React, { useRef, useEffect, useContext, useState } from 'react';
import { gsap } from '../../../../../gsap';
import MapDots from '../../../Common/MapDots/MapDots';
import './sequence1.scss';
import { WidthContext } from '../../../../../WidthContext';
import xButton from '../../../../../assets/interactive/popup_close.svg';
import dnaGraphic from '../../../../../assets/interactive/stage1/dnaGraphic.png';
import rnaGraphic from '../../../../../assets/interactive/stage1/rnaGraphic.png';
import { sendPopOutClickEvents } from '../../../sendInteractiveEvents';

function Sequence1({ progressRef }) {
  const sequence1Text = useRef();
  const sequence1Text2 = useRef();
  const sequence1Text3 = useRef();
  const sequence1Canvas = useRef();
  const sequence1Section = useRef();
  const sequence1Animation = useRef();
  const mapContainerRef = useRef();
  const upper1Ref = useRef();
  const upper2Ref = useRef();
  const lower2Ref = useRef();
  const lottieRef = useRef();
  const pngSequence = useRef();
  const sequence1Pin = useRef();
  const { width } = useContext(WidthContext);
  const [text1Open, setText1open] = useState(false);
  const [text2Open, setText2open] = useState(false);
  const [text3Open, setText3open] = useState(false);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: mapContainerRef.current,
          start: () => 'top 50%',
          endTrigger: sequence1Section.current,
          end: () => 'top top',
          scrub: true
        }
      });

      tl.to(mapContainerRef.current, { opacity: 1, duration: 1 }).to(upper1Ref.current, {
        opacity: 1,
        y: 0,
        duration: 2,
        onComplete: () => {
          lottieRef.current.goToAndPlay(0, true);
        }
      });

      const tl1 = gsap.timeline();
      const pin = gsap.timeline({
        scrollTrigger: {
          trigger: sequence1Section.current,
          start: () => 'top top',
          end: () => 'bottom bottom',
          pin: sequence1Pin.current,
          scrub: true,
          onEnter: () => {
            progressRef.current.style.opacity = 1;
          }
        }
      });
      tl1
        .to(
          upper1Ref.current,
          {
            opacity: 0,
            duration: 2
          },
          '>10'
        )
        .to(upper2Ref.current, {
          opacity: 1,
          y: 0,
          duration: 1
        })
        .to(lower2Ref.current, {
          opacity: 1,
          y: 0,
          duration: 2
        })
        .to(mapContainerRef.current, { opacity: 0, duration: 1 }, '>10')

        .to(
          sequence1Animation.current,
          {
            opacity: 1,
            duration: 1
          },
          'pngStart'
        )
        .to(
          pngSequence.current,
          {
            opacity: 1,
            duration: 1
          },
          '<'
        )
        .to(
          sequence1Text.current,
          {
            opacity: 1,
            y: 0,
            duration: 2
          },
          '<'
        )
        .to(
          sequence1Text.current,
          {
            opacity: 0,
            duration: 1
          },
          '>10'
        )
        .to(sequence1Text2.current, {
          opacity: 1,
          y: 0,
          duration: 2
        })
        .to(
          sequence1Text2.current,
          {
            opacity: 0,
            duration: 1
          },
          '>10'
        )
        .to(sequence1Text3.current, {
          opacity: 1,
          y: 0,
          duration: 2
        });

      pin.add(tl1);

      // Set up png sequence
      const canvas = sequence1Canvas.current.getContext('2d');

      // Set the size of the canvas
      sequence1Canvas.current.height = sequence1Animation.current.clientHeight;
      sequence1Canvas.current.width = sequence1Animation.current.clientHeight * 1.2243;
      const frameCount = 67;
      // Count the frames and set the path to the images
      const currentFrame = (index) =>
        `https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/interactive/s1/s1_sequence${(
          index + 1
        )
          .toString()
          .padStart(2, '0')}.png`;

      // Set start frame
      const images = [];
      const target = {
        frame: 0
      };

      // Go through frames from start to finish

      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
      }
      pin.add('pngAnim', '<25').to(
        target,
        {
          frame: frameCount - 1,
          snap: 'frame',
          duration: 27,
          ease: 'linear',
          onUpdate: renderPng
        },
        'pngAnim'
      );
      images[0].onload = renderPng;
      function renderPng() {
        if (sequence1Canvas.current) {
          canvas.clearRect(0, 0, sequence1Canvas.current.width, sequence1Canvas.current.height);
          canvas.drawImage(
            images[target.frame],
            0,
            0,
            sequence1Canvas.current.width,
            sequence1Canvas.current.height
          );
        }
      }
    });
    return () => ctx.revert();
  }, [width, progressRef]);

  return (
    <section className="sequence1" ref={sequence1Section}>
      <div className="sequence1-wrapper" ref={sequence1Pin}>
        <MapDots
          mapContainerRef={mapContainerRef}
          upper1Ref={upper1Ref}
          upper2Ref={upper2Ref}
          lower2Ref={lower2Ref}
          lottieRef={lottieRef}
          index={0}
        />
        <div className="sequence1__png" ref={pngSequence}>
          <div className="sequence1__copy">
            <p className="sequence1__copy__text" ref={sequence1Text}>
              Isolating the{' '}
              <span
                onClick={() => {
                  setText1open(!text1Open);
                  sendPopOutClickEvents(1, 'genome');
                }}
                className="sequence1__copy__text__popup"
              >
                genome
              </span>{' '}
              of a new virus is like trying to find a specific needle in a haystack of needles.
            </p>
            <p className="sequence1__copy__text" ref={sequence1Text2}>
              Each sample can contain hundreds of millions of different  {' '}
              <span
                onClick={() => {
                  setText2open(!text2Open);
                  sendPopOutClickEvents(1, 'DNA base-pairs and RNA bases');
                }}
                className="sequence1__copy__text__popup"
              >
                DNA base-pairs and RNA bases
              </span>{' '}
              from thousands of sources – including human cells, bacteria, other viruses, and more.
              <br />
              <br />A process called Next-generation sequencing (NGS) is needed to first generate
              the genomic sequences of all these different sources of DNA and RNA.
            </p>
            <p className="sequence1__copy__text" ref={sequence1Text3}>
              You've just completed your next generation sequencing experiment and generated a large
              amount of sequencing data from the sample.
              <br />
              <br />
              To find specific viral DNA or RNA in this soup of genomic material requires advanced
              laboratory equipment, massive computing power, and cutting-edge{' '}
              <span
                onClick={() => {
                  setText3open(!text3Open);
                  sendPopOutClickEvents(1, 'bioinformatics software');
                }}
                className="sequence1__copy__text__popup"
              >
                bioinformatics software
              </span>{' '}
              to sift out unneeded genomes and target the unknown viral material that remains.
            </p>
          </div>
          <div className="sequence1__animation" ref={sequence1Animation}>
            <canvas className="sequence1__animation__canvas" ref={sequence1Canvas} />
          </div>
        </div>
      </div>
      {text1Open && (
        <>
          <div className="sequence1__popout1">
            <div className="sequence1__popout1__wrapper">
              <div className="xButton">
                <button onClick={() => setText1open(!text1Open)}>
                  <img src={xButton} alt="circle with x" />
                </button>
              </div>
              <div className="textCont">
                <p>
                  A genome is the unique genetic makeup of a virus, as expressed through its DNA or
                  RNA sequence.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {text2Open && (
        <>
          <div className="sequence1__popout1">
            <div className="sequence1__popout1__wrapper">
              <div className="xButton">
                <button onClick={() => setText2open(!text2Open)}>
                  <img src={xButton} alt="circle with x" />
                </button>
              </div>
              <div className="textCont">
                <img src={dnaGraphic} alt="dna graphic" />
                <p>
                  A genome is the unique genetic makeup of a virus, as expressed through its DNA or
                  RNA sequence.
                </p>
                <img src={rnaGraphic} alt="rna graphic" />
                <p>
                  Unlike DNA, RNA is most often single-stranded, composed of four bases: adenine
                  (A), uracil (U), cytosine (C) or guanine (G).
                </p>
                <p>
                  For both DNA and RNA, the 4 letters describing these base patterns can be used to
                  express the unique genetic “code” of all living things, including viruses.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {text3Open && (
        <>
          <div className="sequence1__popout1">
            <div className="sequence1__popout1__wrapper">
              <div className="xButton">
                <button onClick={() => setText3open(!text3Open)}>
                  <img src={xButton} alt="circle with x" />
                </button>
              </div>
              <div className="textCont">
                <p>
                  Bioinformatics refers to the use of computation and software to analyze and
                  interpret large amounts of of biological data, such as DNA and RNA.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default Sequence1;
