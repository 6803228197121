import React from 'react';
import { stageData } from '../../stageData';

import './card.scss';

function Card({ origin, cardRef, stage, hide }) {
  return (
    <div
      className={
        hide ? `card card-${origin} card-${stage} card--hide` : `card card-${origin} card-${stage}`
      }
      ref={cardRef}
    >
      <div className={`card__icon card__icon-${origin}`}>
        <img
          alt=""
          src={origin === 'intro' ? stageData[stage].icon : stageData[stage].iconSm}
          className={`card__icon-icon card__icon-icon-${origin}`}
        />
      </div>
      {origin === 'intro' && (
        <div className="card__text">
          <div className="card__text__stage">stage {stage + 1}</div>
          <h4>{stageData[stage].head}</h4>
          <p>{stageData[stage].sub}</p>
        </div>
      )}
    </div>
  );
}

export default Card;
