import govindImg from '../../assets/enhanced-contents/india-hero-govind.png';
import deepaImg from '../../assets/enhanced-contents/india-hero-deepa.png';
import gavinImg from '../../assets/enhanced-contents/sa-hero-gavin.png';

const Heroes = [
  {
    img: govindImg,
    name: 'Govind',
    title: 'Day Laborer',
    desc:
      'After the death of his father and mother, Govind ended up on his own in Delhi. Struggling to make a living while working at a sari store, he eventually found himself living on the streets, picking up recyclable trash to earn money. He turned to drug use to cope with his situation. When Deepa, a YRGCARE outreach coordinator, encountered him one day, she struck up a relationship and convinced him to visit their clinic to access essential health services to improve his life.'
  },
  {
    img: deepaImg,
    name: 'Deepa',
    title: 'Outreach Worker, YRGCARE',
    desc:
      'This outreach worker for YRGCARE goes into the streets and fields around Delhi to find people who may be vulnerable to HIV or HCV (viral hepatitis) transmission. One part social worker and one part community health worker, Deepa builds deep relationships with clients like Govind, screening them for health conditions, counseling them, and connecting them to services and medicine through outreach clinics like the Green Shed. She works to manage their journey to better health and recovery over time.'
  },
  {
    img: gavinImg,
    name: 'Gavin Cloherty, PhD',
    title: 'Head of Infectious Disease Research, Abbott',
    desc:
      'This expert in infectious disease diagnostics leads the effort to establish the Abbott Pandemic Defense Coalition, a first-of-its-kind, industry-led initiative that has brought together more than 20 academic and public health organizations worldwide to support early detection and rapid response to pandemic threats. The Coalition actively hunts, tracks, analyzes, and researches numerous pathogens of public health concern in member countries.'
  }
];

export default Heroes;
