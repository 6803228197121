import React, { useLayoutEffect, useRef, useContext, useEffect } from 'react';
import '../../../Common/Finish/finish.scss';
import confetti from 'canvas-confetti';
import Progress from '../../../Common/Progress/Progress';
import Cards from '../../../Common/Cards/Cards';
import { gsap, ScrollTrigger } from '../../../../../gsap';
import Finish from '../../../Common/Finish/Finish';
import { WidthContext } from '../../../../../WidthContext';

const Finish4 = ({ progressRef }) => {
  const progressAnimRef = useRef();
  const progress2Ref = useRef();
  const finishRef = useRef();
  const s2card1Ref = useRef();
  const s2card2Ref = useRef();
  const s2card3Ref = useRef();
  const s2card4Ref = useRef();
  const s2blank1Ref = useRef();
  const s2blank2Ref = useRef();
  const s2blank3Ref = useRef();
  const s2blank4Ref = useRef();
  const blankRef = useRef();
  const confettiRef = useRef();
  const allCardsRef = useRef();
  allCardsRef.current = [s2card1Ref, s2card2Ref, s2card3Ref, s2card4Ref];
  const blankCardsRef = useRef();
  blankCardsRef.current = [s2blank1Ref, s2blank2Ref, s2blank3Ref, s2blank4Ref];
  const text1Ref = useRef();

  const { viewSize } = useContext(WidthContext);

  useLayoutEffect(() => {
    const stage4Confetti = confetti.create(confettiRef.current, {
      resize: true,
      useWorker: true
    });
    let ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: finishRef.current,
        start: 'top top',
        onEnter: () => {
          // progressRef.current.style.opacity = 0;
          stage4Confetti({
            particleCount: 120,
            spread: 200,
            gravity: 0.7
          });
        }
      });
    });
    return () => ctx.revert();
  }, [viewSize]);
  return (
    <div className="finish finish-stages finish-stages-4" ref={finishRef}>
      <div className="finish__confetti">
        <canvas className="finish__confetti__canvas" ref={confettiRef} />
      </div>
      <Progress origin="stage" stage={4} containerRef={progress2Ref} animateRef={progressAnimRef} />
      <Cards
        origin="stage"
        stage={4}
        cardRefs={allCardsRef.current}
        blankCardRefs={blankCardsRef.current}
        blankRef={blankRef}
      />
      <Finish containerRef={text1Ref} stage={4} />
    </div>
  );
};

export default Finish4;
