import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { WidthContext } from '../../../../../WidthContext';
import './mutationsCheckpt.scss';
import mutationsGraphic from '../../../../../assets/interactive/stage4/mutationsCheckptGraphic.svg';
import mutationsGraphicM from '../../../../../assets/interactive/stage4/mutationsCheckptGraphicM.svg';

import Response from '../../../Common/Response/Response';
import { responseData } from '../../../Common/Response/responseData';
import { gsap, ScrollTrigger } from '../../../../../gsap';

const MutationsCheckpt = () => {
  const { width } = useContext(WidthContext);
  const [active, setActive] = useState(null);
  const [reveal, setReveal] = useState(false);
  const bottomRef = useRef();
  const { correct } = responseData[3];
  const containerRef = useRef();
  const text1Ref = useRef();
  const bodyRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries, i) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (entry['intersectionRatio'] > 0.3) {
              if (i === 0) {
                entry.target.style.transform = 'translateY(0)';
              }

              entry.target.style.opacity = 1;
            }
          }
        });
      },
      { threshold: [0, 0.3, 0.5, 1] }
    );
    observer.observe(text1Ref.current);
    observer.observe(bodyRef.current);
  }, []);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (active !== correct) {
        ScrollTrigger.create({
          trigger: bottomRef.current,
          start: () => 'bottom+=100 top',
          onEnter: () => {
            setReveal(true);
            setActive(correct);
          }
        });
      }
    });
    return () => ctx.revert();
  }, [active, correct, reveal]);

  return (
    <div className="mutationsCheckpt" ref={containerRef}>
      <div className="mutationsCheckpt__paragraph mutationsCheckpt__paragraph-upper" ref={text1Ref}>
        <p>
          Now that your virus surveillance system is sequencing a meaningful percentage of positive
          samples, you can begin to track mutations — and monitor whether your diagnostic assays
          continue to be effective.
        </p>
      </div>
      <div className="mutationsCheckpt__body" ref={bodyRef}>
        <div className="mutationsCheckpt__paragraph mutationsCheckpt__paragraph-lower">
          <h4 className="bold">Where are mutations occurring the most across these variants?</h4>
          <p className="instructionText">
            Select the area where the colors and shapes differ the most from the original.
          </p>
        </div>
        <div className="mutationsCheckpt__graphic" ref={bottomRef}>
          <img
            src={width > 768 ? mutationsGraphic : mutationsGraphicM}
            alt="graphic of data tree"
          />
        </div>
        <Response index={3} active={active} reveal={reveal} setActive={setActive} />
      </div>
    </div>
  );
};

export default MutationsCheckpt;
