import React from 'react';
import './answers.scss';
import { huntersData } from '../huntersData';

function Answers({ answersData }) {
  return (
    <div className="answers">
      <div className="answers__image">
        <div className={`answers__image__bar ${answersData.class}`} />
        <img className="answers__image__img" src={huntersData[answersData.class].photo} alt="" />
      </div>
      <div className="answers__copy">
        <div className="answers__copy__name">{huntersData[answersData.class].name}</div>
        {answersData.copy.map((copy, i) => {
          return (
            <p key={`${i}${answersData.class}`} className="answers__copy__body">
              {copy}
            </p>
          );
        })}
        {answersData.highlight && (
          <p className={`answers__copy__highlight answers__copy__highlight--${answersData.class}`}>
            {answersData.highlight}
          </p>
        )}
      </div>
    </div>
  );
}

export default Answers;
