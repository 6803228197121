import React from 'react';
import SubPgsNav from '../SubPgs/SubPgsNav';
import SubPgsVimeo from '../SubPgs/SubPgsVimeo';
import '../../styles/App.scss';
import RelatedContents from '../RelatedContents';
import MetaTags from 'react-meta-tags';
import MacroBody from './MacroBody';

import subPgsData from '../SubPgs/subPgsData';

function Macro() {
  return (
    <section className="macro">
      <MetaTags>
        <title>Abbott | Film: The Virus Hunt | Sponsored Content | Discovery</title>
      </MetaTags>
      <SubPgsNav data={subPgsData.macro.nav} />
      <SubPgsVimeo data={subPgsData.macro.vimeo} />
      <MacroBody />
      <RelatedContents origin="video" />
    </section>
  );
}

export default Macro;
