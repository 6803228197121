import { useLayoutEffect, useRef } from 'react';
import './paragraphWLottie4.scss';
import LottieLoop from '../../../Common/LottieLoop/LottieLoop';
import pillarCountryLottie from '../../../../../assets/interactive/stage4/pillarCountry.json';

const ParagraphWLottie4 = ({ progressRef }) => {
  const containerRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            progressRef.current.style.opacity = 1;
          }
          if (entry['intersectionRatio'] > 0.5) {
            entry.target.style.opacity = 1;
          }
        });
      },
      { threshold: [0, 0.5, 1] }
    );
    observer.observe(containerRef.current);
  }, [progressRef]);

  return (
    <div className="paragraphWLottie4" ref={containerRef}>
      <div className="paragraphWLottie4__textCont">
        <p>
          As predicted, mutations are occurring in the outer envelope, where the virus comes into
          most frequent contact with the host cell.
        </p>
        <p>
          These types of mutations may make the virus more pathogenic or easier to transmit, so it
          is important to inform public health authorities of these mutations.
        </p>
        <p>
          And because your diagnostic assays targeted the more stable capsid area, your tests
          continue to work effectively, despite these mutations.
        </p>
        <p>
          Now, you’re able to demonstrate that your antigen test is still effective with new
          emerging variants — and you share this research across the Coalition.
        </p>
      </div>
      <div className="paragraphWLottie4__lottieCont">
        <LottieLoop lottie={pillarCountryLottie} />
      </div>
    </div>
  );
};

export default ParagraphWLottie4;
