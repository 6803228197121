import React, { useRef, useLayoutEffect, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import './interactive.scss';
import Intro from './Stages/Intro/Intro';
import Stage1 from './Stages/Stage1/index';
import Stage2 from './Stages/Stage2/index';
import Stage3 from './Stages/Stage3/index';
import Stage4 from './Stages/Stage4/index';
import Lottie from 'lottie-react';
import { gsap } from '../../gsap';
import scrollProgressLottie from '../../assets/interactive/scrollProgress.json';
import Ending from './Stages/Stage4/Ending/Ending';
import Finish4 from './Stages/Stage4/Finish4/Finish4';

function Interactive() {
  const s1StartRef = useRef();
  const s2StartRef = useRef();
  const s3StartRef = useRef();
  const s4StartRef = useRef();
  const progressLottie = useRef();
  const showProgressRef = useRef();
  const progressAnimation = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            progressAnimation.current.style.position = 'fixed';
          } else {
            progressAnimation.current.style.position = 'absolute';
            progressAnimation.current.style.opacity = 0;
          }
        });
      },
      { threshold: [0, 0.3, 1] }
    );
    observer.observe(showProgressRef.current);
  }, []);

  useEffect(() => {
    progressLottie.current.goToAndStop(6);
    let ctx = gsap.context(() => {
      let playhead = [{ frame: 6 }, { frame: 16 }, { frame: 27 }, { frame: 37 }];

      let s1FrameCount = 16;
      let s2FrameCount = 27;
      let s3FrameCount = 37;
      let s4FrameCount = 48;

      const s1Progress = gsap.timeline({
        scrollTrigger: {
          trigger: s1StartRef.current,
          start: () => 'top top',
          end: () => 'bottom bottom',
          scrub: true
        }
      });
      const s2Progress = gsap.timeline({
        scrollTrigger: {
          trigger: s2StartRef.current,
          start: () => 'top top',
          end: () => 'bottom bottom',
          scrub: true
        }
      });
      const s3Progress = gsap.timeline({
        scrollTrigger: {
          trigger: s3StartRef.current,
          start: () => 'top top',
          end: () => 'bottom bottom',
          scrub: true
        }
      });
      const s4Progress = gsap.timeline({
        scrollTrigger: {
          trigger: s4StartRef.current,
          start: () => 'top top',
          end: () => 'bottom bottom',
          scrub: true
        }
      });

      s1Progress.add('s1progressStart').to(
        playhead[0],
        {
          frame: (s1FrameCount -= 1),
          ease: 'none',
          onUpdate: () => {
            renderLottie(0);
          }
        },
        's1ProgressStart'
      );

      s2Progress.add('s2progressStart').to(
        playhead[1],
        {
          frame: (s2FrameCount -= 1),
          ease: 'none',
          onUpdate: () => {
            renderLottie(1);
          }
        },
        's2ProgressStart'
      );

      s3Progress.add('s3progressStart').to(
        playhead[2],
        {
          frame: (s3FrameCount -= 1),
          ease: 'none',
          onUpdate: () => {
            renderLottie(2);
          }
        },
        's3ProgressStart'
      );
      s4Progress.add('s4progressStart').to(
        playhead[3],
        {
          frame: (s4FrameCount -= 1),
          ease: 'none',
          onUpdate: () => {
            renderLottie(3);
          }
        },
        's4ProgressStart'
      );
      function renderLottie(index) {
        progressLottie.current.goToAndStop(playhead[index].frame, true);
      }
    });
    return () => ctx.revert();
  }, []);

  return (
    <section className="interactive">
      <MetaTags>
        <title>Abbott | On The Hunt | Sponsored Content | Discovery</title>
      </MetaTags>

      <Intro progressAnimation={progressAnimation} />
      <div className="interactive__stages" ref={showProgressRef}>
        <div className="interactive__scrollProgress" ref={progressAnimation}>
          <Lottie animationData={scrollProgressLottie} loop={false} lottieRef={progressLottie} />
        </div>
        <Stage1 startRef={s1StartRef} progressRef={progressAnimation} />
        <Stage2 startRef={s2StartRef} progressRef={progressAnimation} />
        <Stage3 startRef={s3StartRef} progressRef={progressAnimation} />
        <Stage4 startRef={s4StartRef} progressRef={progressAnimation} />
      </div>
      <Finish4 />
      <Ending />
    </section>
  );
}

export default Interactive;
