import quoteImg from '../../assets/enhanced-contents/abbott-quote-orange.png';

const Quote = {
  one: {
    img: quoteImg,
    desc:
      'For pandemics, speed is essential. If you can prepare to respond before the outbreak, you can avoid large epidemics and large pandemics.',
    author: 'Tulio De Oliveria'
  },
  two: {
    img: quoteImg,
    desc:
      'The Abbott Pandemic Defense Coalition is very important because it touches academics, scientists, universities, and industry — to work together to quickly identify and characterize new pathogens and new threats for human health.',
    author: 'Tulio De Oliveria'
  }
};

export default Quote;
