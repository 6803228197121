import { useEffect } from 'react';

import './subPgsQuote.scss';

const SubPgsQuote = ({ data, animateRef }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            // Iterate over child nodes
            for (
              var child = animateRef.current.firstChild;
              child !== null;
              child = child.nextSibling
            ) {
              child.style.cssText = 'opacity: 1;transform:translateY(0);';
            }
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(animateRef.current);
  }, [animateRef]);

  return (
    <section className="SubPgsQuote__container" ref={animateRef}>
      <div className="SubPgsQuote__quote">
        <div className="SubPgsQuote__img">
          <img src={data.img} alt="Open quotation" />
        </div>
        <div className="SubPgsQuote__desc">
          <p>{data.desc}</p>
        </div>
        <div className="SubPgsQuote__author">
          <span>– {data.author}</span>
        </div>
      </div>
    </section>
  );
};

export default SubPgsQuote;
