import map1 from '../../../../assets/interactive/intro/map1.json';
import map2 from '../../../../assets/interactive/stage2/map2.json';

export const mapDotsData = [
  {
    upper1: [
      'In a neighborhood near the edge of town some people are becoming very sick.',
      'A few have been hospitalized.'
    ],
    upper2: [
      'Laboratories are running tests on patient samples for known local viruses — but so far, they haven’t been able to identify the cause of the illnesses.',
      'So they’ve sent you several blood and serum specimens for deeper analysis.'
    ],
    lower1: [''],
    lower2: 'But how do you find a virus that nobody knows exists yet?',
    lottie: map1
  },

  {
    upper1: ['Your work is far from over.'],
    lower1: [
      'Local clinics continue to fill up with patients – including in new neighborhoods across the city.',
      'More people are being hospitalized.',
      'Their symptoms are often similar to those caused by many other known pathogens — which is why it’s so important to quickly develop diagnostic tests that can help identify specific cases and facilitate contact tracing.'
    ],

    lottie: map2
  }
];
