import React from 'react';
import './mobileMenu.scss';
import logo from '../../../assets/logo.svg';
import { navItems } from '../../../navItems';
import exit from '../../../assets/exit.svg';
import { Link } from 'react-router-dom';

function MobileMenu({ positionMobileMenu, mobileMenuRef }) {
  return (
    <nav className="mobileMenu" ref={mobileMenuRef} aria-label="section navigation">
      <div className="mobileMenu__contents">
        <button
          className="mobileMenu__contents__close"
          aria-label="Close menu"
          onClick={positionMobileMenu}
        >
          <img
            src={exit}
            aria-hidden="true"
            focusable="false"
            className="mobileMenu__contents__close-img"
            alt=""
          />
        </button>
        <ul>
          {navItems.map((item, i) => {
            return (
              <li className="mobileMenu__contents__item" key={i}>
                <div className="mobileMenu__contents__item-container">
                  <span className="mobileMenu__contents__item__episode">{item.copy2}</span>
                  {i === 0 ? (
                    <a
                      className="mobileMenu__contents__item__link"
                      href={item.href}
                      onClick={positionMobileMenu}
                    >
                      {item.copy}
                    </a>
                  ) : (
                    <Link
                      to={item.href}
                      onClick={positionMobileMenu}
                      className="mobileMenu__contents__item__link"
                    >
                      {item.copy}
                    </Link>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
        <a
          className="mobileMenu__contents__logo"
          href="https://www.abbott.com/"
          target="_blank"
          aria-label="Abbott Labs Homepage"
          rel="noreferrer"
        >
          <img src={logo} alt="" className="mobileMenu__contents__logo-img" />
        </a>
      </div>
    </nav>
  );
}

export default MobileMenu;
