import jackieImg from '../../assets/enhanced-contents/abbott-usa-hero-jackie.png';
import rachelImg from '../../assets/enhanced-contents/abbott-usa-hero-rachel.png';
import stefanImg from '../../assets/enhanced-contents/abbott-usa-hero-stefan.png';
import gavinImg from '../../assets/enhanced-contents/sa-hero-gavin.png';

const Heroes = [
  {
    img: jackieImg,
    name: 'Jackie Buckley, MS',
    title: 'One Health Research Coordinator,',
    title2: 'Lincoln Park Zoo',
    desc:
      'Originally a zookeeper with expertise in African wild dogs, spotted hyenas, Masai giraffes, and other wild and domestic animals, Jackie eventually gravitated toward further studies that explored public health through the lens of human and animal ecosystems. Now, through the Rat Project and other programs, she’s working to inspire city stakeholders and public officials to better appreciate the importance of animals and our shared ecosystems, especially as they relate to human health and the possible spread of disease.'
  },
  {
    img: rachelImg,
    name: 'Rachel Poretsky, PhD',
    title: 'Associate Professor in Microbial Ecology',
    desc:
      'This microbiologist and microbial ecologist specializes in the diversity of bacteria and viruses found in the water systems that surround us, particularly in the urban context. From sewers to urban waterways, Rachel regularly analyzes what our water and waste say about the health of our communities — research that can serve as an ongoing surveillance and discovery of pathogens that may be circulating unnoticed in our communities.'
  },
  {
    img: stefanImg,
    name: 'Stefan Green, PhD',
    title: 'Director of Core Laboratory Services,',
    title2: 'RUSH University',
    desc:
      'This microbiologist and microbial ecologist is the Faculty Advisor for the Genomics and Microbiome Core Facility (GMCF), Director of Core Lab Services, and an Associate Professor in the Division of Infectious Diseases at RUSH University. Here, in Stefan’s labs, molecular analyses of DNA and RNA take place, including from samples containing possible pathogens uncovered through partner projects like the Rat Project and wastewater collection. Unknown pathogens or unfamiliar DNA or RNA sequences can be sent to partners like Abbott for further analysis.'
  },
  {
    img: gavinImg,
    name: 'Gavin Cloherty, PhD',
    title: 'Head of Infectious Disease Research, Abbott',
    desc:
      'This expert in infectious disease diagnostics leads the effort to establish the Abbott Pandemic Defense Coalition, a first-of-its-kind, industry-led initiative that has brought together more than 20 academic and public health organizations worldwide to support early detection and rapid response to pandemic threats. The Coalition actively hunts, tracks, analyzes, and researches numerous pathogens of public health concern in member countries.'
  }
];

export default Heroes;
