import violetImg from '../../assets/enhanced-contents/sa-hero-violet.png';
import aliceImg from '../../assets/enhanced-contents/sa-hero-alice.png';
import gavinImg from '../../assets/enhanced-contents/sa-hero-gavin.png';

const Heroes = [
  {
    img: violetImg,
    name: 'Violet Avontuur',
    title: 'Community Health Worker, HOPE Cape Town',
    desc:
      'This Community Health Worker and Social Auxiliary Worker from HOPE Cape Town works in her community to promote good health through outreach, prevention, and education. With a special passion for destigmatizing HIV/AIDS, Violet brings personalized community care to under-resourced neighborhoods.'
  },
  {
    img: aliceImg,
    name: 'Alice Rubine',
    title: 'Community Health Worker, HOPE Cape Town',
    desc:
      'This Community Health Worker from HOPE Cape Town works through local municipal and provincial clinics in neighborhoods where resources are scarce, providing a wide variety of testing, screening, and counseling services.'
  },
  {
    img: gavinImg,
    name: 'Gavin Cloherty, PhD',
    title: 'Head of Infectious Disease Research, Abbott',
    desc:
      'This expert in infectious disease diagnostics leads the effort to establish the Abbott Pandemic Defense Coalition, a first-of-its-kind, industry-led initiative that has brought together more than 20 academic and public health organizations worldwide to support early detection and rapid response to pandemic threats. The Coalition actively hunts, tracks, analyzes, and researches numerous pathogens of public health concern in member countries.'
  }
];

export default Heroes;
