import Lottie from 'lottie-react';

const LottieLoop = ({ lottie, style }) => {
  return (
    <div className="lottieLoop">
      <Lottie animationData={lottie} style={style} />
    </div>
  );
};

export default LottieLoop;
