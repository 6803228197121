export const navItems = [
  {
    href: '#virusHunt',
    copy: 'The Virus Hunt',
    copy2: 'FILM'
  },
  {
    href: '/colombia',
    copy: 'Colombia',
    copy2: 'EPISODE 4'
  },
  {
    href: '/united-states',
    copy: 'United States',
    copy2: 'EPISODE 3'
  },
  {
    href: '/india',
    copy: 'India',
    copy2: 'EPISODE 2'
  },
  {
    href: '/south-africa',
    copy: 'South Africa',
    copy2: 'EPISODE 1'
  },
  {
    href: '/on-the-hunt',
    copy: 'On The Hunt',
    copy2: 'INTERACTIVE'
  },
  {
    href: '/virus-hunters',
    copy: 'Q + A Virus Hunters',
    copy2: false
  }
];
