import PcrCheckpt from './PcrCheckpt/PcrCheckpt';
import ParagraphWLottie3 from './ParagraphWLottie3/ParagraphWLottie3';
import Sequence3 from './Sequence3/Secquence3';
import Finish3 from './Finish3/Finish3';

const index = ({ startRef, progressRef }) => {
  return (
    <section className="stages" ref={startRef}>
      <Sequence3 />
      <PcrCheckpt />
      <ParagraphWLottie3 />
      <Finish3 progressRef={progressRef} />
    </section>
  );
};

export default index;
