import { useContext, useRef, useLayoutEffect, useState } from 'react';
import { WidthContext } from '../../../../../WidthContext';
import './familyTree.scss';
import treeGraphic from '../../../../../assets/interactive/stage1/familyTreeGraphic.png';
import treeGraphicM from '../../../../../assets/interactive/stage1/familyTreeGraphicM.png';
import Response from '../../../Common/Response/Response';
import { gsap, ScrollTrigger } from '../../../../../gsap';
import { responseData } from '../../../Common/Response/responseData';

const FamilyTree = () => {
  const [active, setActive] = useState(null);
  const [reveal, setReveal] = useState(false);
  const { width } = useContext(WidthContext);
  const { correct } = responseData[0];

  const bodyRef = useRef();
  const text1Ref = useRef();
  const containerRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (entry['intersectionRatio'] > 0.3) {
              entry.target.style.transform = 'translateY(0)';
              entry.target.style.opacity = 1;
            }
          }
        });
      },
      { threshold: [0, 0.3, 1] }
    );
    observer.observe(text1Ref.current);
    observer.observe(bodyRef.current);
  }, []);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (active !== correct) {
        ScrollTrigger.create({
          trigger: containerRef.current,
          start: () => 'bottom-=250 top',
          onEnter: () => {
            setReveal(true);
            setActive(correct);
          }
        });
      }
    });
    return () => ctx.revert();
  }, [active, correct, reveal]);

  return (
    <div className="familyTree" ref={containerRef}>
      <div className="familyTree__paragraph familyTree__paragraph-upper" ref={text1Ref}>
        <p>
          By looking at a new virus’s closest “genetic cousins,” researchers can learn a great deal
          about what to expect with a new pathogen.
        </p>
      </div>
      <div className="familyTree__body" ref={bodyRef}>
        <div className="familyTree__paragraph familyTree__paragraph-lower">
          <h4>What’s the closest cousin to your virus on the Flavivirus family tree?</h4>
          <p>
            Select the closest matching genetic code (the one with the fewest variations from GACU).
          </p>
        </div>
        <img
          className="familyTree__graphic"
          src={width > 767 ? treeGraphic : treeGraphicM}
          alt="graphic of data tree"
        />
        <Response index={0} active={active} reveal={reveal} setActive={setActive} />
      </div>
    </div>
  );
};

export default FamilyTree;
