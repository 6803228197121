import React from 'react';
import logoWhite from '../../../assets/discovery/discovery-logo-black.png';
import mobileMenu from '../../../assets/discovery/mobile-menu.png';
import closeMobileMenu from '../../../assets/discovery/mobile-menu-close.png';

import './header.scss';

const Header = ({ discoveryNavRef, discoveryHeaderRef }) => {
  const showMobileMenu = () => {
    discoveryNavRef.current.style.left = '0';
  };

  const hideMobileMenu = () => {
    discoveryNavRef.current.style.left = '100%';
  };

  return (
    <header className="discoveryHeader" ref={discoveryHeaderRef}>
      <div className="discovery-header-nav">
        <div className="inner-wrapper">
          <div className="seperator"></div>
          <div className="discovery-nav-menu-container">
            <div className="mobile-menu-container">
              <img
                src={mobileMenu}
                alt="Mobile Menu"
                height="28"
                width="28"
                onClick={showMobileMenu}
              />
            </div>
            <nav
              className="discovery-header-nav-menu"
              id="nav"
              ref={window.innerWidth < 1025 ? discoveryNavRef : null}
            >
              <div className="mobile-menu-close">
                <img
                  src={closeMobileMenu}
                  alt="Mobile Menu"
                  height="28"
                  width="28"
                  onClick={hideMobileMenu}
                />
              </div>
              <ul>
                <li>
                  <a href="https://www.discovery.com/shows">Shows</a>
                </li>
                <li>
                  <a href="https://www.discovery.com/dnews">DNews</a>
                </li>
                <li>
                  <a href="https://www.discovery.com/nature">Nature</a>
                </li>
                <li>
                  <a href="https://www.discovery.com/exploration">Exploration</a>
                </li>
                <li>
                  <a href="https://www.discovery.com/space">Space</a>
                </li>
                <li>
                  <a href="https://www.discovery.com/science">Science</a>
                </li>
                <li>
                  <a href="https://www.discovery.com/motor">Motor</a>
                </li>
                <li>
                  <a href="https://www.discovery.com/schedule">Schedule</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="nav-logo-wrapper">
            <a href="https://www.discovery.com/">
              <img src={logoWhite} alt="Logo White" height="36" width="172" />
            </a>
          </div>
        </div>
      </div>
      <div className="header--gold">
        <span className="header--gold__text">Content by Abbott</span>
      </div>
    </header>
  );
};

export default Header;
