import MutationsCheckpt from './MutationsCheckpt/MutationsCheckpt';
import ParagraphWLottie4 from './ParagraphWLottie4/ParagraphWLottie4';
import Sequence4 from './Sequence4/Sequence4';
import Map4Static from './Map4Static/Map4Static';

const index = ({ startRef, progressRef }) => {
  return (
    <section className="stages" ref={startRef}>
      <Map4Static />
      <Sequence4 />
      <MutationsCheckpt />
      <ParagraphWLottie4 progressRef={progressRef} />
    </section>
  );
};

export default index;
