import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './onTheHunt.scss';

const OnTheHunt = ({ animateRef }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            // Iterate over child nodes
            for (
              var child = animateRef.current.firstChild;
              child !== null;
              child = child.nextSibling
            ) {
              child.style.cssText = 'opacity: 1; transform: translateY(0)';
            }

            document.getElementById('onTheHunt').classList.add('animate-cover');
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(animateRef.current);
  }, [animateRef]);

  return (
    <section className="onTheHunt" id="onTheHunt">
      <div className="inner-wrapper">
        <div className="article-content animate-content" ref={animateRef}>
          <div className="article-eyebrow">
            <span>interactive</span>
          </div>
          <div className="article-title">
            <h2>On The Hunt</h2>
          </div>
          <div className="article-description">
            <p>
              Join the Abbott Pandemic Defense Coalition on an interactive virus hunt to track the
              hypothetical outbreak of an unknown viral threat. Can you help identify the virus in
              time to slow the spread?
            </p>
          </div>
          <Link to="/on-the-hunt" id="virusHuntButton" className="outline-button">
            GO ON A VIRUS HUNT
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OnTheHunt;
