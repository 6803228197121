import React from 'react';
import Answers from '../Answers/Answers';
import Questions from '../Questions/Questions';
import './qa.scss';
import Observer from '../Observer';

function QA({ qaData, qaBodyRef }) {
  return (
    <div className="qa" id={`qa-${qaData.class}`} ref={qaBodyRef}>
      <Questions questionsData={qaData.q} number={qaData.class} />
      {qaData.a.map((data, i) => {
        return (
          <Observer key={i}>
            <Answers answersData={data} />
          </Observer>
        );
      })}
    </div>
  );
}

export default QA;
