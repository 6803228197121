import './articles-carousel.scss';

const ArticlesCarousel = ({ location, color }) => {
  return (
    <section className="articles-carousel">
      <div className={`articles-carousel-container articles-${color}`}>
        {location.map(({ title, description, image, position }, index) => (
          <div className="article-item" key={index}>
            {position ? (
              <div className={`article-item-image article-item-image--${position}`}>
                <img src={image} alt="" />
              </div>
            ) : (
              <div className="article-item-image">
                <img src={image} alt="" />
              </div>
            )}

            <div
              className={
                index === 0 ? 'article-item-title article-item-title-first' : 'article-item-title'
              }
            >
              <h3>{title}</h3>
            </div>
            <div className="text-container">
              <p>{description}</p>
            </div>
            <span className="scroll-next">
              Scroll{' '}
              <svg
                width="22"
                height="9"
                viewBox="0 0 22 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.4717 4.85355C21.667 4.65829 21.667 4.34171 21.4717 4.14645L18.2897 0.964466C18.0945 0.769204 17.7779 0.769204 17.5826 0.964466C17.3874 1.15973 17.3874 1.47631 17.5826 1.67157L20.4111 4.5L17.5826 7.32843C17.3874 7.52369 17.3874 7.84027 17.5826 8.03553C17.7779 8.2308 18.0945 8.2308 18.2897 8.03553L21.4717 4.85355ZM0 5H21.1182V4H0L0 5Z"
                  fill="#009CDE"
                />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ArticlesCarousel;
