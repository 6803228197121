import { useInView } from 'react-intersection-observer';

const Observer = ({ children }) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <div className={inView ? 'qa__answer qa__answer--visible' : 'qa__answer'} ref={ref}>
      {children}
    </div>
  );
};

export default Observer;
