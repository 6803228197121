import React, { useEffect, useRef } from 'react';
import stage4Photo from '../../../../../assets/interactive/stage4/inline.jpg';
import './finishContent4.scss';

const FinishContent4 = () => {
  const photoRef = useRef();
  const copyRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (entry['intersectionRatio'] > 0.3) {
              photoRef.current.style.opacity = 1;
              photoRef.current.style.transform = 'translateY(0)';
            }
            if (entry['intersectionRatio'] > 0.5) {
              copyRef.current.style.opacity = 1;
              copyRef.current.style.transform = 'translateY(0)';
            }
          }
        });
      },
      { threshold: [0, 0.3, 0.5, 1] }
    );
    observer.observe(photoRef.current);
  }, []);
  return (
    <div className="finishContent4">
      <p className="finishContent4__copy">
        Your work with the Abbott Pandemic Defense Coalition has{' '}
        <span className="finishContent4__copy--b">
          supplied decision makers with the tools they need
        </span>{' '}
        to make an informed response to this emerging viral threat.
      </p>
      <img src={stage4Photo} alt="" className="finishContent4__image" ref={photoRef} />
      <p className="finishContent4__copy finishContent4__copy-lower" ref={copyRef}>
        By isolating cases, distributing mosquito nets, testing frequently, and sequencing a
        meaningful percentage of positive results to keep tabs on variants, this major capital city
        has contained the outbreak before it exploded into a global pandemic.
      </p>
    </div>
  );
};

export default FinishContent4;
