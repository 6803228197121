import React from 'react';
import BlankCard from '../Card/BlankCard';
import Card from '../Card/Card';
import './cards.scss';

function Cards({ origin, cardRefs, blankCardRefs, stage, blankRef }) {
  return (
    <div className="cards">
      {origin === 'stage' && (
        <div
          className={`cards__contents cards__contents-${origin} cards__contents-blank`}
          ref={blankRef}
        >
          {[...Array(4)].map((_, i) => (
            <BlankCard cardRef={blankCardRefs[i]} stage={stage} key={`blankcards-${stage}-${i}`} />
          ))}
        </div>
      )}
      <div className={`cards__contents cards__contents-${origin} cards__contents-icon`}>
        {[...Array(4)].map((_, i) => (
          <Card
            origin={origin}
            stage={i}
            key={`cards-${stage}-${i}`}
            cardRef={cardRefs[i]}
            hide={origin === 'stage' && stage <= i ? true : false}
          />
        ))}
      </div>
    </div>
  );
}

export default Cards;
