import RadioLetters from './RadioLetters/RadioLetters';
import LinesAnimation from './LinesAnimation/LinesAnimation';
import Gacu from './Gacu/Gacu';
import ParagraphWLottie from './ParagraphWLottie1/ParagraphWLottie';
import FamilyTree from './FamilyTree/FamilyTree';
import Sequence1 from './Sequence1/Sequence1';
import Finish from './Finish1/Finish1';

const index = ({ startRef, progressRef }) => {
  return (
    <section className="stages" ref={startRef}>
      <Sequence1 progressRef={progressRef} />
      <RadioLetters />
      <Gacu />
      <LinesAnimation />
      <FamilyTree />
      <ParagraphWLottie />
      <Finish stage={1} progressRef={progressRef} />
    </section>
  );
};

export default index;
