export const sendAnswerClickEvents = (activity, stage, choice) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'answer_clicks',
      event_activity: activity,
      event_location: `interactive_stage${stage}`,
      event_button: choice
    });
  }
  console.log('send answer: ', activity, stage, choice);
};

export const sendPopOutClickEvents = (stage, name) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'popout_clicks',
      event_location: `interactive_stage${stage}`,
      event_button: name
    });
  }
  console.log('send popout: ', stage, name);
};
