import quoteImg from '../../assets/enhanced-contents/yellow-quote.png';

const Quote = {
  one: {
    img: quoteImg,
    desc:
      'Our work here could really be a blueprint for how we respond to a new pandemic. By collecting environmental samples — from animal populations, wastewater, clinical data, and more — we can build an early warning system that helps us slow or prevent the next pandemic.',
    author: 'Alan Landay'
  },
  two: {
    img: quoteImg,
    desc:
      "The Abbott Pandemic Defense Coalition serves a critical role in the global fight against a new pandemic. With representatives around the world, we have the opportunity to collaborate and understand what is going on globally. Because we're in close communication with each other, we can move quickly when we identify a novel pathogen — to develop diagnostics and other mitigations to recognize cases earlier.",
    author: 'Alan Landay'
  }
};

export default Quote;
