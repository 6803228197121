export const contentsData = {
  carousel: [
    {
      title: 'Head of Infectious Disease Research and the Pandemic Defense Coalition, Abbott, USA',
      class: 'gavin'
    },
    {
      title:
        'Professor of Haematology at the Faculty of Medicine and Biomedical Sciences, University of Yaoundé I, Cameroon',
      class: 'dora'
    },

    {
      title: 'Director of the Uganda Virus Research Institute (UVRI), Uganda',
      class: 'pontiano'
    },
    {
      title: 'Doctor of Medicine at University Quisqueya in Haiti, Ph.D',
      class: 'melody'
    },
    {
      title:
        'Professor and Vice Chair, Research Department of Internal Medicine, Rush University Medical Center, USA',
      class: 'alan'
    },

    {
      title: 'Director of the Centre for Epidemic Response & Innovation and KRISP, South Africa',
      class: 'tulio'
    },

    {
      title: 'Director, Global Health Institute, University of Wisconsin-Madison',
      class: 'jorge'
    },
    {
      title: 'Lead Virus Hunter and Principal Research Scientist, Abbott, USA',
      class: 'mary'
    }
  ],
  qa: [
    {
      q: ['Let’s start at the beginning.', 'What is a virus?'],
      class: '01',
      a: [
        {
          class: 'tulio',
          copy: [
            'Viruses cannot reproduce alone. So they always need what we call, in scientific terms, “hosts” — an animal or a plant or a human to be able to live on.'
          ]
        },
        {
          class: 'mary',
          copy: [
            'I’m fascinated by viruses. They are microscopic structures made up of the same building blocks that our own cells are made of — lipids, proteins, and nucleic acids like RNA and DNA. And yet they are completely reliant on us to make more copies of themselves and spread. We are essentially what gives them “life.” It comes at a cost, though — since they can consume and even kill in the process of replicating.'
          ]
        },
        {
          class: 'gavin',
          copy: [
            "A virus is a very basic unit — some proteins and some nucleic acid, some DNA or RNA, encased in such a way that it’s able to get into another organism. It hijacks their machinery and makes more copies of itself. And that’s basically its sole raison d'etre.",
            'A bacteria, by contrast, is a more complex organism. Its genome is much bigger. It has other organs and organelles. It can move by itself.',
            'It was very eye opening to me to realize that viruses infect bacteria — that, at a very basic level, even bacteria catch colds. Even bacteria can get infected and killed by viruses.'
          ]
        },
        {
          class: 'pontiano',
          copy: [
            'Is a virus a living microorganism? That’s an interesting question. A virus has to live in another cell. Without another cell, a host, it cannot replicate and grow.'
          ],
          highlight:
            'A virus is living but it has to live in another cell. Without another cell, a host, it cannot replicate and grow.'
        },
        {
          class: 'dora',
          copy: ['And they cause a lot of havoc.']
        }
      ]
    },
    {
      q: ['What does a virus hunter do?'],
      class: '02',
      a: [
        {
          class: 'gavin',
          copy: [
            'A virus hunter looks for emerging viruses that may pose a threat to humans. They also look at existing, known viruses to see how they’re changing and evolving — and what kind of a threat that poses to us and to our healthcare systems.'
          ]
        },
        {
          class: 'alan',
          copy: [
            'I’m a virus hunter — but from a different perspective. As an immunologist, I’m trying to look at what the virus has done to us, the human host. I’m looking to see what parts of the immune system have responded to viruses. And by looking at how our bodies respond, we can use all these leading-edge tools of immunology to look for and identify and even treat viruses.'
          ]
        }
      ]
    },
    {
      q: ['What’s the difference between a virus and a variant?'],
      class: '03',
      a: [
        {
          class: 'mary',
          copy: [
            'A virus is actually a collection of closely related individual strains that are mostly the same but not identical. The small number of differences between the individual strains are too minor to be called a completely new virus, but they are distinct enough to be called out as unique versions, which we call variants. For example, some SARS-CoV-2 variants that cause COVID only differ from each other by 3 or 4 mutations in a genome that is ~30,000 bases long.'
          ]
        },
        {
          class: 'tulio',
          copy: [
            'There are tens of thousands of different viruses in the world. These viruses are grouped together based on how similar they are. Very closely related versions of one virus that are similar — but not identical — are called variants. They have a common origin but have evolved over time. A lot of these will be less pathogenic and cause less disease as they evolve. But some viruses evolve to evade things like vaccination or drug resistance — becoming more pathogenic.',
            'These viruses are grouped together based on how similar they are. Very closely related versions of one virus are not often identical and we call these versions variants.'
          ],
          highlight:
            'Some viruses evolve to evade things like vaccination or drug resistance — becoming more pathogenic.'
        },
        {
          class: 'gavin',
          copy: [
            'Viruses replicate so much that you can get billions of new copies in an individual in a given day. Small mutations happen during this process. Beneficial mutations will be selected for and pulled out. The ones that are detrimental to the virus will fade away. And this confers the ability for the virus to thrive. And when a beneficial mutation establishes itself, we call this evolved version of the original virus a variant.'
          ]
        }
      ]
    },
    {
      q: ['What factors influence whether a virus might cause a pandemic?'],
      class: '04',
      a: [
        {
          class: 'melody',
          copy: [
            'It’s complicated — we scientists are still trying to explain this in detail — but I’d say it starts with two things. First, a virus’s ability to first go through our defenses. Normally our body doesn’t let anything in. We stop outsiders at the door. But if a virus is able to go through our defenses, and it’s able to use our body, our cells, to stay alive and replicate, then we’ve got a problem. The second part is transmissibility — the ability to go to someone else. When a virus has these two elements, then there’s a greater chance that it could lead to a pandemic.'
          ],
          highlight:
            'If a virus is able to go through our defenses, and it’s able to use our body, our cells, to stay alive and replicate, then we’ve got a problem.'
        },
        {
          class: 'pontiano',
          copy: [
            'Throughout history, of course, humans have been exposed to many viruses. And we adapt to these over time. The common flu once caused greater death than it does now. But we as humans, as the host, adapted to it over time. That’s why new viruses have the potential to be more dangerous and cause disease — because the body has not had time to adjust to them.'
          ]
        },
        {
          class: 'gavin',
          copy: [
            'One of the things we have to be very aware of is that viruses are constantly changing. They’re always evolving. We have to be actively involved in understanding how they’re changing. Will they become more pathogenic and make people sicker? Are they more transmissible? Do the diagnostic tests we use to identify them still work? Do the drugs and medicines people take still work? That’s a full time job. You have to stay on top of that.'
          ]
        }
      ]
    },
    {
      q: ['When a new virus is emerging, what alarms you the most as a virus hunter?'],
      class: '05',
      a: [
        {
          class: 'jorge',
          copy: [
            'One of the top questions is: do we have exposure to this virus or a similar virus already? If we don’t have previous exposure to it, we are called “naive.” And that indicates the potential is there to cause an infection or a pandemic. Typically, new viruses also need to cycle between animals and humans to create a pandemic potential. Research indicates that viruses need to have around 7 transmission cycles between humans and animals to reach a level where the potential for transmission becomes a major problem.'
          ]
        },
        {
          class: 'mary',
          copy: [
            'I get worried when people can be infected and not feel sick, whether it’s during a long incubation phase or an asymptomatic infection — because that means the virus can spread silently. It’s one of the reasons diagnostic tests are so important early on in a pandemic — so that we can quickly identify people who are infected to stop the spread.'
          ],
          highlight:
            'Diagnostic tests are so important early on in a pandemic — so that we can quickly identify people who are infected to stop the spread.'
        },
        {
          class: 'gavin',
          copy: [
            'For me, it’s transmission rates. How is this being transmitted? You can have individuals who get infected from an animal or a mosquito, but then they don’t transmit it to another person. But if you start to see human-to-human transmission, that’s an immediate red flag. Does the route of transmission require me to physically touch you, be in the same room, or breathe the same air? Then you start looking at the clinical consequences of that infection. Are people getting very sick? Are they dying? Is this something where 1 in 10 people die, or 1 in 100 million people die? Those are very different situations.'
          ]
        }
      ]
    },
    {
      q: ['What’s the difference between an outbreak, an epidemic, and a pandemic?'],
      class: '06',
      a: [
        {
          class: 'tulio',
          copy: [
            'An outbreak is when a few people get infected with a new pathogen.',
            'Epidemics are when a pathogen starts circulating widely in either a population group or a given geographic region. And you really want to stop that before it becomes a pandemic.',
            'A pandemic is when a pathogen is circulating everywhere in the world at the same time. That’s when a lot of systems get overwhelmed. It’s not only hospitals that get overwhelmed. We start to have shortages of diagnostic therapeutics and vaccines, because infections are happening everywhere at the same time.'
          ]
        }
      ]
    },
    {
      q: ['How do pandemics end — and when will the current pandemic end?'],
      class: '07',
      a: [
        {
          class: 'dora',
          copy: [
            'It depends. Some pandemics can be eradicated through vaccines and treatments. The difficulty comes when there are a lot of mutations into new strains or variants of viruses. Mutations and variants can make things very difficult, especially when it comes to producing vaccines or medications that target specific aspects of a virus to eliminate it. So the trick is — what happens when viruses change?'
          ],
          highlight:
            'Mutations and variants can make things very difficult, especially when it comes to producing vaccines or medications that target specific aspects of a virus.'
        },
        {
          class: 'tulio',
          copy: [
            'I believe that the worst phase of the SARS-CoV-2 pandemic has already ended — unless this virus surprises us again. That’s why we need to keep tracking the evolution of this virus.'
          ]
        },
        {
          class: 'melody',
          copy: [
            'We’re trying ourselves as a scientific community to answer that question. At a very general level, pandemics can end in different ways. If we are able to stop the disease itself — then there are no more cases, no more causes of the disease. That’s one way.',
            'But it can go another route: one where we don’t stop the virus from causing more cases, but instead we reduce the severity of the disease it causes. The disease stays out there in the population long-term and comes and goes in phases. But we are able to respond each time with treatments and sometimes vaccines, like we do with the flu. We call this an endemic phase.'
          ]
        },
        {
          class: 'gavin',
          copy: [
            "There's not a hard and fast line. We've lived through a pandemic. I would say it's still ongoing. There's still a lot of people being infected and becoming sick. But I think we're learning to live with it. It's becoming more endemic. It remains to be seen if this is going to become another seasonal illness, like influenza, the flu. Influenza kills tens of thousands or more globally every year. And yet, technically, it's not a pandemic. We live with it every day. There’s reason to suggest SARS-CoV-2 might go that route."
          ]
        }
      ]
    },
    {
      q: ['Is there reason to think pandemics will become more frequent in the future?'],
      class: '08',
      a: [
        {
          class: 'tulio',
          copy: [
            'Outbreaks and epidemics are becoming much more common. One reason for that is because of large population growth and mobility, but also because we have urbanized our environment, which puts us in close contact with animals and provides a greater opportunity for viruses to jump species. Our job is to make sure that these do not translate into another pandemic.'
          ]
        },
        {
          class: 'gavin',
          copy: [
            "Outbreaks are happening more frequently. Viruses move very fast. Think about a virus emerging from an animal host, or evolving in a specific way to be more transmissible. We saw with COVID-19 how fast it can get around the world. If you have a new virus coming from an animal into a person, and that person travels to a big city that is a global transportation hub, then that virus could be all around the world in a matter of days or even hours. Once that happens, it's very difficult to put the genie back in the bottle. We need to be really vigilant to understand these threats as they present themselves. If we're not proactive, we will relive history."
          ],
          highlight:
            'Viruses move very fast. If you have a new virus coming from an animal into a person, and that person travels to a big city that is a global transportation hub, then that virus could be all around the world in a matter of days or even hours.'
        }
      ]
    },
    {
      q: ['What do we need to do now to prevent the next pandemic?'],
      class: '09',
      a: [
        {
          class: 'pontiano',
          copy: [
            'All of us need to be aware that another pandemic could happen. A concerted effort is needed from everyone — the scientists, the politicians, the communities. Everyone.',
            'I’ll give you an example. In Uganda, we have set up systems in the villages, whereby when they identify unusual deaths, unusual cases, they can quickly report them to the nearest health center. Then the message quickly goes out to the virus surveillance team so they can start the documentation of cases. Time is of the essence if we’re to manage pandemics.',
            'Identifying, testing, coming up with diagnostics, vaccines, treatments are all important — but the key is really ensuring that the communities are with us, and that they are aware, and can implement prevention strategies.'
          ]
        },
        {
          class: 'gavin',
          copy: [
            "The stakes are global. Viruses don't recognize borders. They don't need passports. They're constantly moving. Everywhere we go, they go. And never mind that everywhere animals go, everywhere insects go, they go.",
            'So it’s critically important to develop networks. This is a team sport. This is not something that anyone can do on their own. We’ve learned that from the current pandemic. It was amazing to see what the world could do when everybody pulled in the same direction, when scientists were working together, when barriers came down. A threat anywhere is a threat everywhere. Being proactive will enable us to react quickly — to try to identify and stem an emerging virus as fast as possible.'
          ]
        },
        {
          class: 'alan',
          copy: [
            "We also need to work with our public health officials, and do so very quickly, because that's the system that allows us to implement diagnostics and potential prevention measures."
          ]
        },
        {
          class: 'jorge',
          copy: [
            "We need to practice, practice, practice. It's like the fire department. There are many fire departments. And we have fires, but not very often. And yet the fire department practices and drills on a weekly basis to train to stop fires when they come. We have to do the same thing for pandemics. We have to practice, practice, practice. Today, we are better prepared than we were for the next pandemic. But we are not there yet."
          ],
          highlight:
            'The fire department practices and drills on a weekly basis to train to stop fires when they come. We have to do the same thing for pandemics. We have to practice, practice, practice.'
        }
      ]
    },
    {
      q: [
        'You’re part of the Abbott Pandemic Defense Coalition. How does the Coalition support the work that you do?'
      ],
      class: '10',
      a: [
        {
          class: 'dora',
          copy: [
            'The Coalition is a network where we can assist each other, not only in identifying the agents that can cause pandemics, but also in carrying out the appropriate research to see how to contain them. There’s a lot that is being anticipated and diagnosed in advance — and that is exactly what we need to prepare for forthcoming pandemics.'
          ]
        },
        {
          class: 'jorge',
          copy: [
            "Partners in the Abbott Pandemic Defense Coalition connect on a weekly basis, and we try to understand what's happening in each one of these sites to see how we all can work together to find new pathogens. In Colombia, our ability to detect viruses and to understand them has grown significantly with the Coalition. We are now able to sequence samples in Colombia instead of shipping them outside. And with all the capacity building that we are doing, our scientists are now well trained. We have equipment to do some of this testing locally. That’s important — we can now get results relatively quickly, and better understand what pathogens are in circulation."
          ]
        },
        {
          class: 'pontiano',
          copy: [
            'The Uganda Virus Research Institute has worked with Abbott for over seven years, initially in diagnostics. If they come up with new diagnostics, we test them in the field to see how they work, and provide information so that the diagnostics can either be improved or expanded.',
            'About a year ago, we joined the Coalition to prepare for pandemics. Because of the weather that we have in the tropics, the environment, the ecosystem, the greater interactions between animals and the environment and humans — for all these reasons, we are more likely to have more viruses, and new sources of new infections.',
            'So we are working with Abbott to look around for new illnesses. When we’ve found a new virus, then we can work with Abbott to build new tests and new screenings for these new pathogens. And together we build the capacity for pandemic preparedness.'
          ]
        },
        {
          class: 'tulio',
          copy: [
            'The Abbott Pandemic Defense Coalition touches scientific research organizations, and puts them together with industry, to work together to quickly identify and characterize new pathogens and new threats for human health. And that intersection is where a lot of the innovation happens.'
          ]
        },
        {
          class: 'melody',
          copy: [
            'We joined because we want to not only gather more information about what diseases are circulating, but how they’re circulating in Haiti. By sharing resources, we are able to better prepare for future pandemics.'
          ]
        },
        {
          class: 'gavin',
          copy: [
            "What's unique about the Abbott Pandemic Defense Coalition is that it's the first of its kind — an industry-led network of experts who are dedicated to identifying emerging infectious disease threats and rapidly responding to them.",
            'When a novel pathogen emerges, we need to very quickly move from the research side into production. So the Abbott Pandemic Defense Coalition plays a unique role in not only trying to find new viruses or new emerging pathogens, but to quickly build up tools at an industry level. That means developing high quality diagnostic tests and tools that can be put into the hands of public health experts — and then, if necessary, to walk down the hall, give it to our manufacturing department, and have them go from making a few hundred tests to a few hundred million tests in a matter of days.',
            'Because speed is of the essence — to prevent future pandemics you have to be able to scale like that, quickly.'
          ]
        }
      ]
    },
    {
      q: ['As a virus hunter, what keeps you up at night?'],
      class: '11',
      a: [
        {
          class: 'dora',
          copy: [
            "New viruses are constantly being discovered. I work in blood transfusion and I'm frightened because I keep saying, “What if all of these new viruses can be transmitted through blood? And what about the ones we don't even know exist yet?”"
          ]
        },
        {
          class: 'alan',
          copy: [
            "We are a global society and pathogens can spread not just within days or weeks, but now in a matter of hours. You know, 100 years ago, it took you weeks to get across the United States. Now it takes you 4 hours. It is critical for us to get information quickly about something happening in, say, Bangladesh — to make sure we're aware of it here in Chicago. We need to be very cognizant of what’s happening because viruses can now move so quickly."
          ],
          highlight:
            'We are a global society and pathogens can spread not just within days or weeks, but now in a matter of hours.'
        },
        {
          class: 'gavin',
          copy: [
            "Respiratory viruses are concerning because they are very easily transmitted. Viruses that are very easily transmitted worry me. You see the recurring problem of Ebola. If you get one of these highly transmissible pathogens in the right location, it could have dire consequences. For now, Ebola is emerging in more rural and isolated areas. But if you had something like Ebola emerge in a very densely populated area, that could have significant consequences. We need to be aware that emerging infectious diseases don't stay put for long. They can spread far and fast."
          ]
        },
        {
          class: 'mary',
          copy: [
            'A lot of things keep me up at night. But what helps me go to sleep is knowing that we’re in this together. We have the brightest minds from every field committed to finding and tackling new viruses. I like to think that I’ll wake up safer thanks to the work we’re doing.'
          ]
        }
      ]
    }
  ],
  menu: [
    { number: '01', q: 'What is a virus?' },
    { number: '02', q: 'What is a virus hunter?' },
    { number: '03', q: 'What’s the difference between a virus and a variant?' },
    { number: '04', q: 'What causes a pandemic?' },
    { number: '05', q: 'What do virus hunters look for?' },
    { number: '06', q: 'What’s the difference between an outbreak, an epidemic, and a pandemic?' },
    { number: '07', q: 'When will the pandemic end?' },
    { number: '08', q: 'Are pandemics becoming more frequent?' },
    { number: '09', q: 'How can we prevent the next pandemic?' },
    { number: '10', q: 'What is the Abbott Pandemic Defense Coalition?' },
    { number: '11', q: 'What new viral threats are most concerning?' }
  ]
};
