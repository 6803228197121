import { useState, useRef, useLayoutEffect, useContext } from 'react';
import './radioLetters.scss';
import checkboxUnchecked from '../../../../../assets/interactive/stage1/checkboxUnchecked.svg';
import checkboxChecked from '../../../../../assets/interactive/stage1/checkboxChecked.svg';
import { WidthContext } from '../../../../../WidthContext';
import { sendAnswerClickEvents } from '../../../sendInteractiveEvents';

const RadioLetters = () => {
  const selection1 = 'ACCTACGT';
  const selection2 = 'ACGT';
  const selection5 = 'GACU';
  const selection4 = 'AUCG';
  const [s1Checked, setS1Checked] = useState(false);
  const [s2Checked, setS2Checked] = useState(false);
  const [s4Checked, setS4Checked] = useState(false);
  const containerRef = useRef();
  const { width } = useContext(WidthContext);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (width < 768) {
              if (entry['intersectionRatio'] > 0.1) {
                containerRef.current.style.opacity = 1;
              }
            } else {
              if (entry['intersectionRatio'] > 0.3) {
                containerRef.current.style.opacity = 1;
              }
            }
          }
        });
      },
      { threshold: [0, 0.1, 0.3, 1] }
    );
    observer.observe(containerRef.current);
  }, [width]);
  return (
    <div className="radioLetters" ref={containerRef}>
      <div className="radioLetters__headerText">
        <h4>Filter out the “known” genomic sequences below.</h4>
        <p>The “unknown” genomic code that is left over could be your mystery virus.</p>
      </div>
      <div className="radioLetters__contentCont">
        <div className="radioLetters__contentCont__left">
          {' '}
          <div className="radioLetters__contentCont__left__letters">
            <p className={s1Checked ? 'selected' : ''}>{selection1}</p>
            <p className={s4Checked ? 'selected' : ''}>{selection4}</p>
            <p className={s1Checked ? 'selected' : ''}>{selection1}</p>
            <p className={s4Checked ? 'selected' : ''}>{selection4}</p>
            <p className={s2Checked ? 'selected' : ''}> {selection2}</p>
            <p className={s4Checked ? 'selected' : ''}>{selection4}</p>
            <p className={s1Checked ? 'selected' : ''}>{selection1}</p>
            <p className={s4Checked ? 'selected' : ''}>{selection4}</p>
            <p className={s2Checked ? 'selected' : ''}> {selection2}</p>
            <p className={s1Checked ? 'selected' : ''}>{selection1}</p>
            <p className={s4Checked ? 'selected' : ''}>{selection4}</p>
            <p className={s2Checked ? 'selected' : ''}> {selection2}</p>
            <p className={s4Checked ? 'selected' : ''}>{selection4}</p>
            <p className={s1Checked ? 'selected' : ''}>{selection1}</p>
            <p className={s2Checked ? 'selected' : ''}> {selection2}</p>
            <p>{selection5}</p>
            <p className={s1Checked ? 'selected' : ''}>{selection1}</p>
            <p className={s1Checked ? 'selected' : ''}>{selection1}</p>
            <p className={s4Checked ? 'selected' : ''}>{selection4}</p>
            <p className={s2Checked ? 'selected' : ''}> {selection2}</p>
            <p className={s1Checked ? 'selected' : ''}>{selection1}</p>
            <p className={s4Checked ? 'selected' : ''}>{selection4}</p>
            <p className={s1Checked ? 'selected' : ''}>{selection1}</p>
            <p className={s4Checked ? 'selected' : ''}>{selection4}</p>
          </div>
        </div>
        <div className="radioLetters__contentCont__right">
          <div className="radioLetters__contentCont__right__header">
            <p className="descrip">
              Click below to remove all the following “known” genomic codes from your sample.
            </p>
          </div>
          {/* ----------------------------------------------------------------- selection 1*/}
          <div className="radioLetters__contentCont__right__infoBlock first ">
            <div className="radioSelection">
              <div className="radio">
                <button
                  onClick={() => {
                    setS1Checked(!s1Checked);
                    sendAnswerClickEvents('filter_out', 1, 'Human DNA');
                  }}
                >
                  <img
                    src={s1Checked ? checkboxChecked : checkboxUnchecked}
                    alt="circle checkbox"
                  />
                </button>
              </div>
              <div className="info">
                <div>
                  <p className="descripTitle">Human DNA</p>
                  <p className="descrip">
                    Human DNA has around <span className="bold">3 billion</span> base-pairs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* ----------------------------------------------------------------- selection 2*/}
          <div className="radioLetters__contentCont__right__infoBlock  ">
            <div className="radioSelection">
              <div className="radio">
                <button
                  onClick={() => {
                    setS2Checked(!s2Checked);
                    sendAnswerClickEvents('filter_out', 1, 'Bacteria DNA');
                  }}
                >
                  <img
                    src={s2Checked ? checkboxChecked : checkboxUnchecked}
                    alt="circle checkbox"
                  />
                </button>
              </div>
              <div className="info">
                <div>
                  <p className="descripTitle">Bacteria DNA</p>
                  <p className="descrip">
                    Bacteria DNA, depending on the type, can range from{' '}
                    <span className="bold">500,000 to 13 million</span> base-pairs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* ----------------------------------------------------------------- selection 3*/}
          <div className="radioLetters__contentCont__right__infoBlock  ">
            <div className="radioSelection">
              <div className="radio">
                <button
                  onClick={() => {
                    setS4Checked(!s4Checked);
                    sendAnswerClickEvents('filter_out', 1, 'Known virus DNA / RNA');
                  }}
                >
                  <img
                    src={s4Checked ? checkboxChecked : checkboxUnchecked}
                    alt="circle checkbox"
                  />
                </button>
              </div>
              <div className="info">
                <div>
                  <p className="descripTitle">Known virus DNA / RNA</p>
                  <p className="descrip">
                    Virus DNA or RNA can range from{' '}
                    <span className="bold">
                      3,200 to 1.2 million base-pairs (DNA) or bases (RNA)
                    </span>
                    . Many viruses have relatively small genome sequences, often around 7,000 to
                    20,000 base-pairs (DNA) or bases (RNA). The SARS-CoV-2 virus, for example, has
                    an RNA sequence of  ~30,000 bases.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadioLetters;
