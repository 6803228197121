import React, { useLayoutEffect, useRef, useContext, useEffect } from 'react';
import '../../../Common/Finish/finish.scss';
import confetti from 'canvas-confetti';
import Progress from '../../../Common/Progress/Progress';

import Cards from '../../../Common/Cards/Cards';
import { gsap } from '../../../../../gsap';
import Finish from '../../../Common/Finish/Finish';
import { WidthContext } from '../../../../../WidthContext';
import StageHero from '../../../Common/StageHero/StageHero';

const Finish1 = ({ progressRef }) => {
  const progressAnimRef = useRef();
  const progress1Ref = useRef();
  const finishRef = useRef();
  const s1card1Ref = useRef();
  const s1card2Ref = useRef();
  const s1card3Ref = useRef();
  const s1card4Ref = useRef();
  const s1blank1Ref = useRef();
  const s1blank2Ref = useRef();
  const s1blank3Ref = useRef();
  const s1blank4Ref = useRef();
  const blankRef = useRef();
  const confettiRef = useRef();

  const allCardsRef = useRef();
  allCardsRef.current = [s1card1Ref, s1card2Ref, s1card3Ref, s1card4Ref];
  const blankCardsRef = useRef();
  blankCardsRef.current = [s1blank1Ref, s1blank2Ref, s1blank3Ref, s1blank4Ref];
  const hero2Ref = useRef();
  const text1Ref = useRef();
  const wrapperRef = useRef();

  const { viewSize } = useContext(WidthContext);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (progressRef.current) {
          if (entry['isIntersecting'] === true) {
            progressRef.current.style.opacity = 0;
          } else {
            progressRef.current.style.opacity = 1;
          }
        }
      });
    });
    observer.observe(finishRef.current);
  }, [progressRef]);

  useLayoutEffect(() => {
    const stage1Confetti = confetti.create(confettiRef.current, {
      resize: true,
      useWorker: true
    });
    let ctx = gsap.context(() => {
      const tl = gsap.timeline();
      const anim = gsap.timeline({
        scrollTrigger: {
          trigger: finishRef.current,
          start: () => 'top top',
          end: () => 'bottom bottom',
          pin: wrapperRef.current,
          scrub: true,
          onEnter: () => {
            stage1Confetti({
              particleCount: 120,
              spread: 200,
              gravity: 0.7
            });
          }
        }
      });
      tl.to(
        progressAnimRef.current,
        {
          width: '100%',
          height: '5px',
          duration: 2
        },
        '3'
      )
        .to(s1card2Ref.current, { opacity: 1, duration: 2 }, '<')
        .to(blankRef.current, { opacity: 0, duration: 1 }, '<')
        .to(s1card2Ref.current, { x: '52.5%', duration: 2 })
        .to(s1card1Ref.current, { opacity: 0, duration: 1 }, '<')
        .to(progress1Ref.current, { opacity: 0, duration: 1 }, '<')
        .to(text1Ref.current, { opacity: 0, duration: 1 }, '<')
        .to(hero2Ref.current, { opacity: 1, scale: 1, duration: 2 });

      anim.add(tl);
    });
    return () => ctx.revert();
  }, [viewSize]);
  return (
    <div className="finish finish-stages finish-1" ref={finishRef}>
      <div className="finish-stages-wrapper" ref={wrapperRef}>
        <div className="finish__confetti">
          <canvas className="finish__confetti__canvas" ref={confettiRef} />
        </div>
        <Progress
          origin="stage"
          stage={1}
          containerRef={progress1Ref}
          animateRef={progressAnimRef}
        />
        <Cards
          origin="stage"
          stage={1}
          cardRefs={allCardsRef.current}
          blankCardRefs={blankCardsRef.current}
          blankRef={blankRef}
        />
        <Finish containerRef={text1Ref} stage={1} />
        <StageHero index={1} reference={hero2Ref} />
      </div>
    </div>
  );
};

export default Finish1;
