import { useLottie, useLottieInteractivity } from 'lottie-react';

// COMMENT BACK IN TO ADD IN SPECIFIC STYLES
// const style = {
//   height: 300,
//   border: 3,
//   borderStyle: 'solid',
//   borderRadius: 7
// };

const LottieScrubOnScroll = ({ lottie }) => {
  const options = {
    animationData: lottie
  };
  // to add specific styles, add styles as a second param to useLottie()
  const lottieObj = useLottie(options);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: 'scroll',
    actions: [
      {
        visibility: [0.1, 1],
        type: 'seek',
        frames: [0, lottieObj.getDuration(true)]
      }
    ]
  });

  return Animation;
};

export default LottieScrubOnScroll;
