import USImage from '../../assets/enhanced-contents/usa-map.png';
import Meet from '../../assets/enhanced-contents/abbott-meet-alan.jpg';
import videoData from '../Hub/Video/videoData';

const Content = {
  top: {
    eyebrow: 'The Virus Hunt: Episode 3',
    img: USImage,
    title: 'United States',
    description: videoData[2].description
  },
  bottom: {
    img: Meet,
    title: 'Meet Alan Landay',
    bio:
      'Alan Landay, PhD and Immunologist, Professor of Medicine and Vice Chair of Research, RUSH University',
    description:
      'This award-winning immunologist and researcher has over 40 years of experience in HIV immunopathogenesis, a field that studies the development of disease through the lens of the body’s immune response. Together with RUSH, Landay is working with the Coalition to develop an early alert system for tracking undiagnosed illnesses in the hospital, and with local partners to perform wastewater and rat disease surveillance — all to keep a step ahead of new and known viral outbreaks.'
  }
};

export default Content;
