import React from 'react';
import './numbers.scss';

function Numbers({ origin, number }) {
  return (
    <span className={origin === 'body' ? 'numbers numbers--body' : 'numbers'}>
      <svg viewBox="0 0 37 36" xmlns="http://www.w3.org/2000/svg" className="numbers__circle">
        <circle
          cx="18.5"
          cy="18"
          r="18"
          fill="#009CDE"
          className={
            origin === 'body' ? 'numbers__circle__circle' : 'numbers__circle__circle--menu'
          }
        />
      </svg>
      <span className="numbers__text">{number}</span>
    </span>
  );
}

export default Numbers;
