import { useContext, useState, useRef, useLayoutEffect } from 'react';
import { WidthContext } from '../../../../../WidthContext';
import './antigenCheckpt.scss';
import antigenGraphic from '../../../../../assets/interactive/stage2/antigenChkptGraphic.svg';
import antigenGraphicM from '../../../../../assets/interactive/stage2/antigenChkptGraphicM.svg';
import one from '../../../../../assets/interactive/stage2/1icon.svg';
import two from '../../../../../assets/interactive/stage2/2icon.svg';
import three from '../../../../../assets/interactive/stage2/3icon.svg';
import closeIcon from '../../../../../assets/interactive/stage2/closeIcon.svg';
import Response from '../../../Common/Response/Response';
import { responseData } from '../../../Common/Response/responseData';
import { gsap, ScrollTrigger } from '../../../../../gsap';
import InfoButton from '../../../Common/InfoButton/InfoButton';

const AntigenCheckpt = () => {
  const { width } = useContext(WidthContext);
  const isSmall = width < 768;
  const [menuOpen, setMenuOpen] = useState(false);
  const [active, setActive] = useState(null);
  const [reveal, setReveal] = useState(false);
  const bottomRef = useRef();
  const { correct } = responseData[1];
  const containerRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (entry['intersectionRatio'] > 0.3) {
              containerRef.current.style.opacity = 1;
            }
          }
        });
      },
      { threshold: [0, 0.3, 0.7, 1] }
    );
    observer.observe(containerRef.current);
  }, []);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (active !== correct) {
        ScrollTrigger.create({
          trigger: bottomRef.current,
          start: () => 'bottom+=150 top',
          onEnter: () => {
            setReveal(true);
            setActive(correct);
          }
        });
      }
    });
    return () => ctx.revert();
  }, [active, correct, reveal]);

  return (
    <div className="antigenCheckpt" ref={containerRef}>
      <div className="antigenCheckpt__paragraph">
        <h4>What antigens should you target for your antigen test?</h4>
        <p>Read about each antigen area and then select which one to target.</p>
      </div>
      <div className="antigenCheckpt__graphic" ref={bottomRef}>
        <img
          className="antigenCheckpt__graphic__img"
          src={width > 768 ? antigenGraphic : antigenGraphicM}
          alt="graphic of data tree"
        />
        {isSmall && (
          <div className="infoButtonCont">
            <InfoButton handleClick={setMenuOpen} param={!menuOpen} stage={2} />
          </div>
        )}
      </div>
      {menuOpen && (
        <div className="infoPanel">
          <div className="infoPanel__xButton">
            <button
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <img src={closeIcon} alt="button with x" />
            </button>
          </div>
          <div className="infoPanel__wrapper">
            <div className="infoPanel__content">
              {/* //infoBlock1  */}
              <div className="infoBlock">
                <div className="infoBlock__header">
                  <img src={one} alt="one with circle" />
                  <p className="bold">The Outer Envelope</p>
                </div>
                <div className="infoBlock__paragraph">
                  <p>
                    An assay is the industry term for a diagnostic test to assess or measure the
                    presence of a virus in a sample.
                  </p>
                </div>
              </div>
              {/* //infoBlock2  */}
              <div className="infoBlock">
                <div className="infoBlock__header">
                  <img src={two} alt="two with circle" />
                  <p>The Inner Envelope</p>
                </div>
                <div className="infoBlock__paragraph">
                  <p>
                    This part of the viral envelope does not come into contact with the host cell,
                    as it is more “buried” within the virus. This is also a relatively “small” part
                    of the virus, containing a tiny amount of material overall.
                  </p>
                </div>
              </div>
              {/* //infoBlock3  */}
              <div className="infoBlock">
                <div className="infoBlock__header">
                  <img src={three} alt="three with circle" />
                  <p className="bold">The Capsid</p>
                </div>
                <div className="infoBlock__paragraph">
                  <p>
                    The capsid is an area between the outer shell of the virus and its inner RNA.
                    The capsid is protected from most interactions with a host’s immune system, and
                    makes up most of the volume inside of the virus shell, containing a relatively
                    large amount of material.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Response index={1} active={active} reveal={reveal} setActive={setActive} />
    </div>
  );
};

export default AntigenCheckpt;
