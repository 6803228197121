import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './article.scss';

const Article = ({ animateRef }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            // Iterate over child nodes
            for (
              var child = animateRef.current.firstChild;
              child !== null;
              child = child.nextSibling
            ) {
              child.style.cssText = 'opacity: 1; transform: translateY(0)';
            }

            document.getElementById('qaVirusHunters').classList.add('animate-cover');
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(animateRef.current);
  }, [animateRef]);

  return (
    <section className="article" id="qaVirusHunters">
      <div className="inner-wrapper">
        <div className="article-content animate-content" ref={animateRef}>
          <div className="article-eyebrow">
            <span>Article</span>
          </div>
          <div className="article-title">
            <h2>Q&A With Virus Hunters</h2>
          </div>
          <div className="article-description">
            <p>
              We ask the Abbott Pandemic Defense Coalition's world-class virus hunters some of the
              most common questions about viruses and pandemics.
            </p>
          </div>
          <Link to="/virus-hunters" id="readArticleButton" className="outline-button">
            Read More
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Article;
