import React, { useEffect, useRef } from 'react';
import './qaHero.scss';
import Hyperlink from '../Hyperlink/Hyperlink';
import QACarousel from '../QACarousel/QACarousel';
import Genomes from '../../Hub/Genomes/Genomes';
import SubPgsNav from '../../SubPgs/SubPgsNav';
import subPgsData from '../../SubPgs/subPgsData';

function QAHero({ showArrows, qaHeroRef }) {
  const copyRef = useRef();
  const subheadRef = useRef();
  const galleryRef = useRef();
  const hyperlinkData = {
    href: 'https://www.abbott.com/virushunters.html',
    copy: 'Abbott Pandemic Defense Coalition'
  };

  useEffect(() => {
    subheadRef.current.style.animation = 'fade-in 0.7s linear 0.4s forwards';
    copyRef.current.style.animation = 'fade-in 0.7s linear 1s forwards';
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.4) {
            galleryRef.current.style.opacity = '1';
          }
        });
      },
      { threshold: [0, 0.4] }
    );
    observer.observe(galleryRef.current);
  }, [galleryRef]);

  return (
    <section className="qaHero" ref={qaHeroRef}>
      <div className="qaHero__background">
        <div className="qaHero__background__image">
          <div className="qaHero__background__gradient qaHero__background__gradient--top" />
        </div>
        <div className="qaHero__background__gradient--bottom" />
      </div>

      <div className="qaHero__content">
        <div>
          <div className="genomes-left-qa">
            <Genomes height={'small'} />
            <Genomes height={'large'} />
            <Genomes height={'medium'} />
          </div>
          <div className="genomes-right-qa">
            <Genomes height={'small'} />
            <Genomes height={'large'} />
            <Genomes height={'medium'} />
          </div>
        </div>
        <SubPgsNav data={subPgsData.qa.nav} />

        <div className="qaHero__content__head">
          <h1 className="head">Q&A With Virus Hunters</h1>
          <h2 className="subhead" ref={subheadRef}>
            We pulled some of the most common questions about viruses and pandemics. Then we asked
            them to the world’s top virus hunters.
          </h2>
        </div>
        <div className="qaHero__content__copy" ref={copyRef}>
          <p className="paragraph">
            The SARS-CoV-2 pandemic has made all of us acutely aware of the outsized impact viruses
            can have on our global community. And many of us have questions: What have we learned?
            What’s being done to keep pandemics from happening again? Where do viruses come from,
            anyway?
          </p>
          <p className="paragraph">
            To answer these and other common questions, we mined search terms and social data to
            curate a list of frequently-asked queries from everyday people about viruses and
            pandemics.
          </p>
          <p className="paragraph">
            Then we asked a group of the world’s top virus hunters from the{' '}
            <Hyperlink hyperlinkData={hyperlinkData} /> for answers.
          </p>
        </div>
        <div className="qaHero__content__carousel" ref={galleryRef}>
          <QACarousel showArrows={showArrows} />
        </div>
      </div>
    </section>
  );
}

export default QAHero;
