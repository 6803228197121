import React, { useEffect, useRef, useState } from 'react';
import './hub.scss';
import '../../styles/reset.css';
import { gsap, ScrollTrigger } from '../../gsap';
import Nav from './nav/Nav';
import MobileMenu from './mobileMenu/MobileMenu';
import Hero from './Hero';
import Teaser from './Teaser';
import Article from './Article';
import Footer from './Footer';
import Video from './Video';
import videoData from './Video/videoData';
import OnTheHunt from './OnTheHunt/OnTheHunt';
import MetaTags from 'react-meta-tags';

function Hub({ navRef }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const hubRef = useRef();
  const mobileMenuRef = useRef();
  const heroRef = useRef();
  const articleRef = useRef();
  const teaserRef = useRef();
  const footerRef = useRef();
  const videoRef = useRef();
  const indiaVideoRef = useRef();
  const usVideoRef = useRef();
  const colVideoRef = useRef();
  const interactiveRef = useRef();

  //show/hide mobile menu
  const positionMobileMenu = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
  };

  useEffect(() => {
    const tl = gsap.timeline();
    menuOpen
      ? tl.to(mobileMenuRef.current, { x: 0, duration: 0.3 })
      : tl.to(mobileMenuRef.current, { x: '100%', duration: 0.3 });
  }, [menuOpen]);

  //hides nav bar on scroll down & shows on scroll up
  useEffect(() => {
    ScrollTrigger.create({
      trigger: hubRef.current,
      start: 'top top',
      pinSpacer: false,
      onUpdate: (self) => {
        if (self.direction === 1) {
          gsap.to(navRef.current, { y: '-135px', duration: 0.5, ease: 'linear' });
        } else if (self.direction === -1) {
          gsap.to(navRef.current, { y: '0', duration: 0.5, ease: 'linear' });
        }
      }
    });
  }, [navRef]);

  return (
    <div className="hub" ref={hubRef}>
      <MetaTags>
        <title>Abbott | The Virus Hunt | Sponsored Content | Discovery</title>
      </MetaTags>
      <MobileMenu mobileMenuRef={mobileMenuRef} positionMobileMenu={positionMobileMenu} />
      <Nav positionMobileMenu={positionMobileMenu} navRef={navRef} />
      <Hero animateRef={heroRef} />
      <Teaser content={videoData[4]} animateRef={teaserRef} />
      <Video content={videoData} dataIndex="3" animateRef={colVideoRef} />
      <Video content={videoData} dataIndex="2" animateRef={usVideoRef} />
      <Video content={videoData} dataIndex="1" animateRef={indiaVideoRef} />
      <Video content={videoData} dataIndex="0" animateRef={videoRef} />
      <OnTheHunt animateRef={interactiveRef} />
      <Article animateRef={articleRef} />
      <Footer animateRef={footerRef} />
    </div>
  );
}

export default Hub;
