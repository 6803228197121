import imageOne from '../../assets/enhanced-contents/col-hero0.png';
import imageTwo from '../../assets/enhanced-contents/col-hero1.png';
import imageThree from '../../assets/enhanced-contents/col-hero2.png';
import imageFour from '../../assets/enhanced-contents/col-hero3.png';
import imageFive from '../../assets/enhanced-contents/col-hero4.png';
import imageSix from '../../assets/enhanced-contents/col-hero5.png';
import imageSeven from '../../assets/enhanced-contents/sa-hero-gavin.png';

const Heroes = [
  {
    img: imageOne,
    name: 'Juan Pablo Hernández Ortiz, PhD',
    title: 'Professor and Director,',
    title2: 'One Health Genomic Lab, Universidad Nacional de Colombia',
    desc:
      'As the head of a state-of-the-art BSL2 genomics facility in Medellín, Juan Pablo is helping pioneer partnerships with local colleagues and communities in Colombia to develop long-term research opportunities that dive deeply into emerging disease in the country, while also training the next generation of students to be virus hunters and scientists.'
  },
  {
    img: imageTwo,
    name: 'Monica Palma Cuero, PhD, MS',
    title: 'Researcher and Coordinator',
    desc:
      'This Leticia native and former public health official is a Global Health Institute visiting scholar with expertise in Parasite Diagnostics and Molecular Biology. She helps coordinate the collection of blood samples from fever patients in Leticia and Puerto Nariño — alongside the collection of mosquitoes and biting flies — in order to work with the lab in Medellín to track the possible emergence of unknown or under-researched infectious diseases.'
  },
  {
    img: imageThree,
    name: 'Julian Rodriguez Zabala, MSc',
    title: 'Researcher and Coordinator',
    desc:
      'Currently based in Leticia in the heart of the Amazon and only accessible by plane or boat, Julian helps coordinate the regular collection of samples from fever patients in Leticia and Puerto Nariño to send to the lab in Medellín. In close partnership with local hospitals and clinics, Julian helps with the initial diagnosis and testing of known febrile illnesses, like dengue and malaria – flagging samples that test negative for known illnesses for further analysis.'
  },
  {
    img: imageFour,
    name: 'Karl Ciuoderis, DVM, PhD, MSc',
    title: 'Scientific Coordinator,',
    title2: 'One Health Genomic Lab, Universidad Nacional de Colombia',
    desc:
      'With a background in veterinary sciences, Karl’s research focuses on the public health consequences of Colombia’s wildlife diversity, climate change, socio-cultural factors, and many routes of transmission. Samples from Leticia and Puerto Nariño – along with others from across Colombia – are processed by Karl and the wider laboratory team in Medellín.'
  },
  {
    img: imageFive,
    name: 'Laura Silvana Pérez Restrepo, MSc',
    title: 'Genomics Laboratory Coordinator,',
    title2: 'One Health Genomic Lab, Universidad Nacional de Colombia',
    desc:
      'Laura’s expertise in mosquitos and vector-borne diseases contributes to the efforts of the lab in Medellín as the team works to better understand how new and previously unknown infectious pathogens are transmitted and spread.'
  },
  {
    img: imageSix,
    name: 'Juan Sebastian Mantilla Granados, MSc',
    title: 'Entomologist and Biologist',
    desc:
      'This insect expert specializes in the capture and identification of different species of mosquito, midges, and biting flies. His expertise, in conjunction with samples taken from fever clinics across Colombia, can help identify which “vectors” – a term that refers to the specific species that are behind the spread of pathogens – are the best candidates for further research.'
  },
  {
    img: imageSeven,
    name: 'Gavin Cloherty, PhD',
    title: 'Head of Infectious Disease Research, Abbott',
    desc:
      'This expert in infectious disease diagnostics leads the effort to establish the Abbott Pandemic Defense Coalition, a first-of-its-kind, industry-led initiative that has brought together more than 20 academic and public health organizations worldwide to support early detection and rapid response to pandemic threats. The Coalition actively hunts, tracks, analyzes, and researches numerous pathogens of public health concern in member countries.'
  }
];

export default Heroes;
