import React, { useEffect, useRef } from 'react';
import './styles/App.scss';
import { WidthContextProvider } from './WidthContext';
import Hub from './components/Hub/Hub';
import QAArticle from './components/QAArticle/QAArticle';
import Layout from './components/Layout/Layout';
import { Routes, Route } from 'react-router-dom';
import SouthAfrica from './components/SouthAfrica/SouthAfrica';
import India from './components/India/India';
import UnitedStates from './components/UnitedStates/UnitedStates';
import Colombia from './components/Colombia/Colombia';
import Interactive from './components/Interactive/Interactive';
import Macro from './components/Macro/Macro';

function App() {
  const appRef = useRef();
  const discoveryNavRef = useRef();
  const discoveryHeaderRef = useRef();
  const navRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (navRef.current) {
            if (entry.isIntersecting) {
              navRef.current.style.position = 'absolute';
            } else {
              navRef.current.style.position = 'fixed';
            }
          }
        });
      },
      { threshold: [0] }
    );
    observer.observe(discoveryHeaderRef.current);
  }, []);

  return (
    <WidthContextProvider>
      <main className="App" ref={appRef}>
        <Layout discoveryNavRef={discoveryNavRef} discoveryHeaderRef={discoveryHeaderRef}>
          <Routes>
            <Route
              path="/"
              element={
                <Hub
                  discoveryNavRef={discoveryNavRef}
                  discoveryHeaderRef={discoveryHeaderRef}
                  navRef={navRef}
                />
              }
            ></Route>
            <Route path="/virus-hunters" element={<QAArticle />}></Route>
            <Route path="/south-africa" element={<SouthAfrica />}></Route>
            <Route path="/india" element={<India />}></Route>
            <Route path="/united-states" element={<UnitedStates />}></Route>
            <Route path="/colombia" element={<Colombia />}></Route>
            <Route path="/virus-hunt" element={<Macro />}></Route>
            <Route path="/on-the-hunt" element={<Interactive />}></Route>
          </Routes>
        </Layout>
      </main>
    </WidthContextProvider>
  );
}

export default App;
