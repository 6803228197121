import React, { useRef, useEffect, useState, useCallback } from 'react';
import './qaArticle.scss';
import QAHero from './QAHero/QAHero';
import QA from './QA/QA';
import QANav from './QANav/QANav';
import { contentsData } from './contentslData';
import InlinePhoto from './InlinePhotos/InlinePhotos';
import { gsap, ScrollTrigger } from '../../gsap';
import MetaTags from 'react-meta-tags';
import RelatedContents from '../RelatedContents';

function QAArticle() {
  const [showArrows, setShowArrows] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [fixMenu, setFixMenu] = useState(false);
  const photo0Ref = useRef();
  const photo1Ref = useRef();
  const photo2Ref = useRef();
  const photo3Ref = useRef();
  const photo4Ref = useRef();
  const allPhotosRef = useRef();
  const qaHeroRef = useRef();
  const qaBodyRef = useRef();
  const qaNavRef = useRef();
  const qaRef = useRef();
  const collapsedMenuRef = useRef();
  const expandedMenuRef = useRef();
  const expandedContentsRef = useRef();
  allPhotosRef.current = [photo0Ref, photo1Ref, photo2Ref, photo3Ref, photo4Ref];

  const handleMenu = useCallback(() => {
    const closeTl = gsap.timeline({
      onComplete: () => {
        setShowMenu(false);
      }
    });
    const openTl = gsap.timeline({
      onComplete: () => {
        setShowMenu(true);
      }
    });
    if (showMenu) {
      closeTl
        .to(expandedMenuRef.current, {
          opacity: '0',
          duration: 0.3
        })
        .to(
          qaNavRef.current,
          {
            height: '0',
            duration: 0.4
          },
          '>'
        );
    } else {
      openTl
        .to(collapsedMenuRef.current, {
          opacity: '0',
          duration: 0.3
        })
        .to(qaNavRef.current, {
          height: 'auto',
          duration: 0.3
        });
    }
  }, [showMenu]);

  useEffect(() => {
    if (fixMenu) {
      setShowMenu();
    }
  }, [fixMenu]);

  useEffect(() => {
    if (showMenu) {
      expandedMenuRef.current.style.opacity = 1;
    } else {
      collapsedMenuRef.current.style.opacity = 1;
    }
  }, [showMenu]);

  useEffect(() => {
    let ctx = gsap.context(() => {
      allPhotosRef.current.forEach((photo) => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: photo.current,
            start: '20% bottom',
            end: 'top center',
            scrub: 1
          }
        });
        tl.to(photo.current, {
          width: '100%',
          height: '100%',
          duration: 3
        });
      });
    });
    return () => ctx.revert();
  }, []);

  useEffect(() => {
    let ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: qaBodyRef.current,
        start: 'top top',
        end: 'top top',
        once: true,
        onEnter: () => {
          setFixMenu(true);
          qaNavRef.current.style.transform = 'translateY(-100px)';
          qaNavRef.current.style.animation = 'moveDown 0.5s linear forwards';
        }
      });
    });
    return () => ctx.revert();
  }, []);

  useEffect(() => {
    const header = qaHeroRef.current;
    const nav = qaNavRef.current.style;
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (fixMenu && entry['isIntersecting'] === false) {
            nav.position = 'fixed';
          } else if (fixMenu && entry['isIntersecting'] === true) {
            nav.position = 'absolute';
          }
        });
      },
      { threshold: [0] }
    );
    observer.observe(header);
    return () => observer.disconnect();
  }, [fixMenu]);

  return (
    <div
      className="qaArticle"
      ref={qaRef}
      onTouchStart={() => {
        if (showArrows) {
          setShowArrows(false);
        }
      }}
    >
      <MetaTags>
        <title>Abbott | Q & A With Virus Hunters | Sponsored Content | Discovery</title>
        <meta
          name="description"
          content="We pulled some of the most common questions about viruses and pandemics. Then we asked them to the world’s top virus hunters."
        />
        <meta
          property="og:description"
          content="We pulled some of the most common questions about viruses and pandemics. Then we asked them to the world’s top virus hunters."
        />
        <meta
          property="og:title"
          content="Abbott | Q & A With Virus Hunters | Sponsored | Discovery"
        />
        <meta
          property="og:image"
          content="https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/qa_og.jpg"
        />
        <meta property="og:url" content="https://abbott-discovery.com/virus-hunters" />
      </MetaTags>
      <QAHero showArrows={showArrows} qaHeroRef={qaHeroRef} />
      <div className="qaArticle__nav">
        <QANav
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          qaNavRef={qaNavRef}
          collapsedMenuRef={collapsedMenuRef}
          expandedMenuRef={expandedMenuRef}
          expandedContentsRef={expandedContentsRef}
          handleMenu={handleMenu}
        />
        <QA qaData={contentsData.qa[0]} number="01" qaBodyRef={qaBodyRef} />
        <QA qaData={contentsData.qa[1]} number="02" />
        <InlinePhoto photoIndex="photo0" photoRef={photo0Ref} />
        <QA qaData={contentsData.qa[2]} number="03" />
        <QA qaData={contentsData.qa[3]} number="04" />
        <InlinePhoto photoIndex="photo1" photoRef={photo1Ref} />
        <QA qaData={contentsData.qa[4]} number="05" />
        <QA qaData={contentsData.qa[5]} number="06" />
        <InlinePhoto photoIndex="photo2" photoRef={photo2Ref} />
        <QA qaData={contentsData.qa[6]} number="07" />
        <QA qaData={contentsData.qa[7]} number="08" />
        <InlinePhoto photoIndex="photo3" photoRef={photo3Ref} />
        <QA qaData={contentsData.qa[8]} number="09" />
        <InlinePhoto photoIndex="photo4" photoRef={photo4Ref} />
        <QA qaData={contentsData.qa[9]} number="10" />
        <QA qaData={contentsData.qa[10]} number="11" />
        <div className="qaArticle__cta">
          <p>
            Viruses move fast. We need to move faster.
            <br />
            <a
              className="qaArticle__cta__hyperlink"
              href="https://www.abbott.com/virushunters.html"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about the Abbott Pandemic Defense Coalition{' '}
              <svg
                width="25"
                height="8"
                viewBox="0 0 25 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="qaArticle__cta__hyperlink__arrow"
              >
                <path
                  className="qaArticle__cta__hyperlink__arrow__path"
                  d="M24.8516 4.13871C25.0469 3.94345 25.0469 3.62686 24.8516 3.4316L21.6696 0.249622C21.4744 0.0543602 21.1578 0.0543602 20.9625 0.249622C20.7673 0.444885 20.7673 0.761467 20.9625 0.956729L23.7909 3.78516L20.9625 6.61358C20.7673 6.80885 20.7673 7.12543 20.9625 7.32069C21.1578 7.51595 21.4744 7.51595 21.6696 7.32069L24.8516 4.13871ZM0.482422 4.28516H24.498V3.28516H0.482422V4.28516Z"
                  fill="black"
                />
              </svg>
            </a>
          </p>
        </div>
        <RelatedContents origin="article" />
      </div>
    </div>
  );
}

export default QAArticle;
