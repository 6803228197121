import { useLayoutEffect, useRef } from 'react';
import './paragraphWLottie2.scss';
import LottieLoop from '../../../Common/LottieLoop/LottieLoop';
import countryLottie from '../../../../../assets/interactive/stage2/countryLottie.json';

const ParagraphWLottie2 = () => {
  const containerRef = useRef();
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['intersectionRatio'] > 0.5) {
            entry.target.style.opacity = 1;
          }
        });
      },
      { threshold: [0, 0.5, 1] }
    );
    observer.observe(containerRef.current);
  }, []);
  return (
    <div className="paragraphWLottie2" ref={containerRef}>
      <div className="paragraphWLottie2__textCont">
        <p>
          The best place to target for your antigen test is the capsid region — an area that is less
          likely to mutate because it does not come into direct contact with the human immune
          system.
        </p>
        <p>Now it’s time to get your tests over to the manufacturing department.</p>
      </div>
      <div className="paragraphWLottie2__lottieCont">
        <LottieLoop lottie={countryLottie} />
      </div>
    </div>
  );
};

export default ParagraphWLottie2;
