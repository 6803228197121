import { useEffect, useRef } from 'react';
import Player from '@vimeo/player';
import './subPgsVimeo.scss';

const SubPgsVimeo = ({ data }) => {
  const videos = useRef();
  const saVideo = useRef();
  const indiaVideo = useRef();
  const usVideo = useRef();
  const colVideo = useRef();
  const macroVideo = useRef();
  videos.current = [saVideo, indiaVideo, usVideo, colVideo, macroVideo];
  const { id, url, color, index } = data;

  useEffect(() => {
    videos.current[index].current = new Player(id, {
      url: url,
      controls: true,
      frameborder: '0',
      allow: 'fullscreen; picture-in-picture',
      responsive: true,
      playsinline: 0
    });
  }, [id, url, index]);

  return (
    <section className="subPgsVimeo">
      <div className={`subPgsVimeo__bar subPgsVimeo__bar--${color}`} />
      <div className="subPgsVimeo-container">
        <div ref={videos.current[index]} id={id} className="subPgsVimeo__video" />
      </div>
    </section>
  );
};

export default SubPgsVimeo;
