import './ImageGrid.scss';

const ImageGrid = ({ items }) => {
  return (
    <section className="SubPgsImageGrid__container">
      {items.map(({ img }, index) => (
        <div className="SubPgsImageGrid__item" key={index}>
          <img src={img} alt="" />
        </div>
      ))}
    </section>
  );
};

export default ImageGrid;
