import { useLayoutEffect, useRef } from 'react';
import './paragraphWLottie3.scss';
import LottieLoop from '../../../Common/LottieLoop/LottieLoop';
import countryTubesLottie from '../../../../../assets/interactive/stage3/countryTubesLottie.json';

const ParagraphWLottie3 = () => {
  const containerRef = useRef();
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['intersectionRatio'] > 0.5) {
            entry.target.style.opacity = 1;
          }
        });
      },
      { threshold: [0, 0.5, 1] }
    );
    observer.observe(containerRef.current);
  }, []);

  return (
    <div className="paragraphWLottie3" ref={containerRef}>
      <div className="paragraphWLottie3__textCont">
        <p>Finally, you have a PCR assay with a formulation that can effectively scale. </p>
        <p>
          Now it’s time to get it — and your antigen test — into the hands of those at the front
          lines.
        </p>
        <p>
          You submit your assays to governing health agencies for approval — and take one step
          closer to the goal of diagnostic deployment.
        </p>
      </div>
      <div className="paragraphWLottie3__lottieCont">
        <LottieLoop lottie={countryTubesLottie} />
      </div>
    </div>
  );
};

export default ParagraphWLottie3;
