import { useLayoutEffect, useRef } from 'react';
import LottieLoop from '../../../Common/LottieLoop/LottieLoop';
import gacuLottie from '../../../../../assets/interactive/stage1/gacu.json';
import './gacu.scss';

const Gacu = () => {
  const containerRef = useRef();
  const text1Ref = useRef();
  const text2Ref = useRef();
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (entry['intersectionRatio'] > 0.3) {
              entry.target.style.transform = 'translateY(0)';
              entry.target.style.opacity = 1;
            }
          }
        });
      },
      { threshold: [0, 0.3, 1] }
    );
    observer.observe(text1Ref.current);
    observer.observe(text2Ref.current);
  }, []);

  return (
    <section className="gacu" ref={containerRef}>
      <div className="gacu__text" ref={text1Ref}>
        <p>The bioinformatics software has isolated an unknown sequence of RNA in your sample.</p>
      </div>
      <div className="gacu__lottie">
        <LottieLoop lottie={gacuLottie} />
      </div>
      <div className="gacu__text" ref={text2Ref}>
        <p>But how do you know if it’s really your mystery virus?</p>
      </div>
    </section>
  );
};

export default Gacu;
