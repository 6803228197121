import React, { useRef, useContext, useEffect } from 'react';
import { gsap } from '../../../../../gsap';
import './sequence3.scss';
import { WidthContext } from '../../../../../WidthContext';
import Lottie from 'lottie-react';
import multiplyD from '../../../../../assets/interactive/stage3/s3_multiply.json';
import multiplyM from '../../../../../assets/interactive/stage3/s3_multiply_mobile.json';
import tubeD from '../../../../../assets/interactive/stage3/s3_tubes.json';
import tubeM from '../../../../../assets/interactive/stage3/s3_tubes_mobile.json';

function Sequence3() {
  const sequence3Section = useRef();
  const testAnimation = useRef();
  const tubeAnimation = useRef();
  const upper1Ref = useRef();
  const upper2Ref = useRef();
  const upper3Ref = useRef();
  const upper4Ref = useRef();
  const lower1Ref = useRef();
  const lottieTubeRef = useRef();
  const lottieMultiplyRef = useRef();
  const wrapperRef = useRef();

  const { width } = useContext(WidthContext);

  useEffect(() => {
    lottieTubeRef.current.goToAndStop(0);
    lottieMultiplyRef.current.goToAndStop(0);
    let ctx = gsap.context(() => {
      const tl1 = gsap.timeline();
      const tl2 = gsap.timeline();

      let testPlayhead = { frame: 0 };
      let tubePlayhead = { frame: 0 };
      let testFrameCount = lottieMultiplyRef.current.getDuration(true);
      let tubeFrameCount = lottieTubeRef.current.getDuration(true);

      const pin = gsap.timeline({
        scrollTrigger: {
          trigger: sequence3Section.current,
          start: () => 'top top',
          end: () => 'bottom-=400 bottom',
          pin: wrapperRef.current,
          scrub: true
        }
      });

      tl1
        .to(upper1Ref.current, {
          opacity: 1,
          y: 0,
          duration: 1
        })
        .to(
          upper1Ref.current,
          {
            opacity: 0,
            duration: 1
          },
          '>10'
        )
        .to(upper2Ref.current, {
          opacity: 1,
          y: 0,
          duration: 1
        })
        .to(
          upper2Ref.current,
          {
            opacity: 0,
            duration: 1
          },
          '>10'
        )
        .to(
          testAnimation.current,
          {
            opacity: 0,
            duration: 1
          },
          '<'
        )
        .to(
          tubeAnimation.current,
          {
            opacity: 1,
            duration: 1
          },
          '>5'
        )

        .to(upper3Ref.current, {
          opacity: 1,
          y: 0,
          duration: 1
        });

      tl2
        .to(
          upper3Ref.current,
          {
            opacity: 0,
            duration: 1
          },
          '>10'
        )
        .to(upper4Ref.current, {
          opacity: 1,
          y: 0,
          duration: 1
        })

        .to(
          upper4Ref.current,
          {
            opacity: 0,
            duration: 1
          },
          '>10'
        )
        .to(
          lower1Ref.current,
          {
            opacity: 1,
            y: 0,
            duration: 1
          },
          '<'
        );

      pin.add(tl1);

      pin.add('testLottie', '<').to(
        testPlayhead,
        {
          frame: (testFrameCount -= 1),
          ease: 'none',
          onUpdate: () => {
            renderLottie('test');
          },
          duration: 20
        },
        'testLottie'
      );
      pin.add(tl2);
      pin.add('tubeLottie', '<').to(
        tubePlayhead,
        {
          frame: (tubeFrameCount -= 1),
          ease: 'none',
          onUpdate: () => {
            renderLottie('tube');
          },
          duration: 20
        },
        'tubeLottie'
      );

      function renderLottie(lottie) {
        if (lottie === 'test') {
          lottieMultiplyRef.current.goToAndStop(testPlayhead.frame, true);
        } else {
          lottieTubeRef.current.goToAndStop(tubePlayhead.frame, true);
        }
      }
    });
    return () => ctx.revert();
  }, []);

  return (
    <section className="sequence3" ref={sequence3Section}>
      <div className="sequence3-wrapper" ref={wrapperRef}>
        <div className="sequence3__copy">
          <p className="sequence3__copy__text" ref={upper1Ref}>
            Creating tests is only half the battle.
          </p>
          <p className="sequence3__copy__text" ref={upper2Ref}>
            Getting them into the right hands in the real world is another problem altogether.
            <br />
            <br />
            And that requires production — scaling up the manufacture of your tests into the
            thousands, or even hundreds of thousands.
          </p>
          <p className="sequence3__copy__text" ref={upper3Ref}>
            Your antigen test is on good footing.
            <br />
            <br />
            So let’s jump back in time to the PCR test.
            <br />
            <br />
            When you go to the manufacturing department with your PCR test, you receive some bad
            news.
          </p>
          <p className="sequence3__copy__text" ref={upper4Ref}>
            They tell you your PCR test uses an enzyme that’s in short supply. You’ll never be able
            to scale it up to create the number of tests that might be needed in a pandemic.
          </p>
        </div>
        <div className="sequence3__animation">
          <div
            className="sequence3__animation-container sequence3__animation-test"
            ref={testAnimation}
          >
            {width > 480 ? (
              <Lottie
                animationData={multiplyD}
                loop={false}
                className="sequence3__animation__lottie sequence3__animation__lottie-test"
                lottieRef={lottieMultiplyRef}
              />
            ) : (
              <Lottie
                animationData={multiplyM}
                loop={false}
                className="sequence3__animation__lottie sequence3__animation__lottie-test"
                lottieRef={lottieMultiplyRef}
              />
            )}
          </div>
          <div
            className="sequence3__animation-container sequence3__animation-tube"
            ref={tubeAnimation}
          >
            {width > 480 ? (
              <Lottie
                animationData={tubeD}
                loop={false}
                className="sequence3__animation__lottie sequence3__animation__lottie-tube"
                lottieRef={lottieTubeRef}
              />
            ) : (
              <Lottie
                animationData={tubeM}
                loop={false}
                className="sequence3__animation__lottie sequence3__animation__lottie-tube"
                lottieRef={lottieTubeRef}
              />
            )}
          </div>
        </div>
        <div className="sequence3__copy sequence3__copy-lower">
          <p className="sequence3__copy__text" ref={lower1Ref}>
            The team will work with you to reformulate the PCR test so it can be scaled up, if
            needed, to number in the hundreds of thousands. Together, you’ve found a new component
            that can easily scale — but now your PCR test isn’t giving the same test results as
            before.
            <br />
            <br />
            You’ll need to re-adjust the formula of the test to compensate.
            <br />
            <br />
            The delay will cost you valuable time.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Sequence3;
