import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './subPgsHeroes.scss';

// import required modules
import { Pagination, Navigation } from 'swiper';

const SubPgsHeroes = ({ data, origin }) => {
  const [touch, setTouch] = useState(false);

  const sendCarouselEvents = () => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'profile_carousel_interaction', event_location: origin });
    }
  };

  useEffect(() => {
    const handleTouch = () => {
      setTouch(true);
      return () => window.removeEventListener('touchstart', handleTouch);
    };
    window.addEventListener('touchstart', handleTouch, { passive: true });

    return () => window.removeEventListener('touchstart', handleTouch);
  }, []);

  return (
    <Swiper
      onSlideChange={sendCarouselEvents}
      slidesPerView={1}
      spaceBetween={24}
      navigation={touch ? false : true}
      cssMode={true}
      pagination={{
        el: '.pagination-container',
        clickable: true
      }}
      modules={[Pagination, Navigation]}
      breakpoints={{
        768: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        }
      }}
      className="subPgsHeroes__container"
    >
      <div className="pagination-container"></div>
      {data &&
        data.map(({ img, name, title, desc, title2 }, index) => (
          <SwiperSlide className="subPgsHeroes__item" key={index}>
            <div className="subPgsHeroes__img">
              <img src={img} alt="" />
            </div>
            <div className="subPgsHeroes__name">
              <h3>{name}</h3>
            </div>
            <div className="subPgsHeroes__title">
              <span>
                {title}
                <br />
                {title2 ? <span>{title2}</span> : ''}
              </span>
            </div>
            <div className="subPgsHeroes__desc">
              <p>{desc}</p>
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default SubPgsHeroes;
