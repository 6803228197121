export const responseData = [
  {
    correct: 3,
    buttons: ['yellow fever', 'encephalitis', 'zika', 'dengue']
  },
  {
    correct: 2,
    buttons: ['the outer envelope', 'the inner envelope', 'the capsid'],
    answers: [
      [
        'This part of the virus is more prone to mutation – which means a different area would be a wiser choice to target for your assay. Here’s why:',
        'Because the envelope protein on flaviviruses binds to other proteins on the human host cell, our immune system tends to be “on the lookout” for them. As a result, our immune system “catches” envelope proteins more easily to prevent infection. In order to “hide” from our immune system, virus envelope proteins often change and mutate over time to avoid detection.'
      ],

      [
        'While this part of the virus is indeed less prone to mutation, its location “deeper” within the virus makes it harder for a diagnostic test to detect – which means a different area would be a wiser choice to target for your assay.',
        "Here's why:",
        'Because the inner envelope does not come into contact with the host cell, our immune system tends not to recognize it easily. As a result, the inner envelope lacks the need for frequent mutation to evade our immune system. However, this area is more “buried” within the virus, making it harder for a diagnostic test to access. Additionally, there is a smaller amount of material in this area across the virus as a whole, which will make your test less sensitive if you pick this target area.'
      ],

      [
        'Because the capsid is protected from most interactions with a host’s immune system, it is less likely to develop evasive mutations and therefore tends to be more stable across new variants. It also makes up most of the volume inside the virus shell, which helps boost the sensitivity of your diagnostic test.'
      ]
    ]
  },
  {
    correct: 1,
    buttons: ['Test Tube 1', 'Test Tube 2', 'Test Tube 3'],
    answers: [
      [
        'By having too little buffer material in this updated PCR formulation, your test’s pH levels are becoming too acidic, which breaks down DNA and prevents the amplification process from effectively reproducing your viral DNA for analysis.'
      ],
      [
        'A PCR test requires the “just right” balance of ingredients – and this delicate concoction must be heated, cooled, then heated again at carefully controlled temperatures for specific durations in order to work.',
        'Finding the “just right” balance is often a matter of trial and error – and this combination had the best sensitivity when samples were tested, allowing for effective amplification cycles to take place – thus creating the millions of copies of viral DNA needed for sensitive, accurate diagnostic analysis.'
      ],
      [
        'PCR formulations are best evaluated by challenging them with real samples. A test run showed that this combination gave the lowest level of sensitivity — meaning that many positive samples could be missed. It’s possible that having too little primer material in this formulation meant the heated DNA was broken down but not able to be amplified, or doubled, afterward.'
      ]
    ]
  },

  {
    correct: 0,
    buttons: ['the outer envelope', 'the inner envelope', 'the capsid'],

    answers: [
      [
        'Good eye! The outer envelope across these variants have significant differences in either color or shape as compared to the original virus, indicating the antigens here have mutated. It’s a good thing you didn’t target these antigens in your antigen test, or else the test might not work anymore! This is good news — because you chose to target the antigens in the stable capsid, your antigen test should continue to be effective, despite the mutations in the outer envelope.'
      ],

      [
        'The inner envelope across these variants has the same shape and color as the original virus, indicating the antigens here have not mutated.'
      ],
      [
        'The capsid across these variants has the same shape and color as the original virus, indicating the antigens here have not mutated. This is actually good news — it means you chose a stable place to target with your antigen test.'
      ]
    ]
  }
];
