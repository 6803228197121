import React, { useLayoutEffect, useRef, useContext } from 'react';
import { WidthContext } from '../../../../../WidthContext';
import { gsap, ScrollTrigger } from '../../../../../gsap';
import Progress from '../../../Common/Progress/Progress';
import Cards from '../../../Common/Cards/Cards';
import scroll from '../../../../../assets/interactive/intro/scroll.svg';

import './introCards.scss';
import StageHero from '../../../Common/StageHero/StageHero';

function IntroCards({ progressAnimation }) {
  const { viewSize } = useContext(WidthContext);
  const card1Ref = useRef();
  const card2Ref = useRef();
  const card3Ref = useRef();
  const card4Ref = useRef();
  const cardRefs = useRef();
  const cardBgRef = useRef();
  const contentsRef = useRef();
  const textRef = useRef();
  const stage1Ref = useRef();
  const text2Ref = useRef();
  cardRefs.current = [card1Ref, card2Ref, card3Ref, card4Ref];
  const containerRef = useRef();
  const wrapperRef = useRef();
  const progressRef = useRef();
  const num1 = useRef();
  const num2 = useRef();
  const num3 = useRef();
  const num4 = useRef();
  const bar1 = useRef();
  const bar2 = useRef();
  const bar3 = useRef();
  const bar4 = useRef();
  const allProgressRef = useRef();
  allProgressRef.current = [
    [num1, bar1],
    [num2, bar2],
    [num3, bar3],
    [num4, bar4]
  ];

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (entry['isIntersecting'] === true) {
          progressAnimation.current.style.opacity = 0;
        }
      });
    });
    observer.observe(containerRef.current);
  }, [progressAnimation]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline();
      ScrollTrigger.create({
        trigger: textRef.current,
        start: () => 'top 50%',
        onEnter: () => {
          gsap.to(textRef.current, { opacity: 1, y: 0, duration: 1 });
        }
      });
      const anim = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: () => 'top top',
          end: () => 'bottom bottom',
          pin: wrapperRef.current,
          scrub: 1
        }
      });

      tl.to(card1Ref.current, { opacity: 1, duration: 1 })
        .to(bar1.current, { opacity: 1, width: '100%', duration: 1 }, '<')
        .to(num1.current, { opacity: 1, duration: 1 }, '<')
        .to(card2Ref.current, { opacity: 1, duration: 1 })
        .to(bar2.current, { opacity: 1, width: '100%', duration: 1 }, '<')
        .to(num2.current, { opacity: 1, duration: 1 }, '<')
        .to(card3Ref.current, { opacity: 1, duration: 1 })
        .to(bar3.current, { opacity: 1, width: '100%', duration: 1 }, '<')
        .to(num3.current, { opacity: 1, duration: 1 }, '<')
        .to(card4Ref.current, { opacity: 1, duration: 1 })
        .to(bar4.current, { opacity: 1, width: '100%', duration: 1 }, '<')
        .to(num4.current, { opacity: 1, duration: 1 }, '<')
        .to(card2Ref.current, { opacity: 0, duration: 0.5 }, '+=2')
        .to(card3Ref.current, { opacity: 0, duration: 0.5 }, '<')
        .to(card4Ref.current, { opacity: 0, duration: 0.5 }, '<')
        .to(card1Ref.current, { x: viewSize === 'large' ? '165%' : '52.5%', duration: 1 })
        .to(text2Ref.current, { opacity: 1, duration: 1 }, '<')
        .to(progressRef.current, { opacity: 0, duration: 1 }, '<')
        .to(textRef.current, { opacity: 0, duration: 1 }, '<')
        .to(card1Ref.current, { opacity: 0, duration: 1 }, '>2')
        .to(text2Ref.current, { opacity: 0, duration: 1 }, '<')
        // .to(contents.current, { opacity: 0, duration: 1 })
        .to(stage1Ref.current, { opacity: 1, scale: 1, duration: 2 }, '<2');

      anim.add(tl);
    });
    return () => ctx.revert();
  }, [cardRefs, viewSize, progressAnimation]);
  return (
    <div className="introCards" ref={containerRef}>
      <div className="introCards-wrapper" ref={wrapperRef}>
        <div className="introCards__body" ref={contentsRef}>
          <h3 className="introCards__text introCards__text-head" ref={textRef}>
            Your work falls into 4 stages.
          </h3>
          <Progress
            origin={'intro'}
            stage={0}
            containerRef={progressRef}
            allRefs={allProgressRef}
          />
          <Cards origin={'intro'} stage={0} cardRefs={cardRefs.current} cardBgRef={cardBgRef} />
        </div>
        <div
          className={viewSize === 'large' ? 'introCards__textBox' : 'introCards__textBox--m'}
          ref={text2Ref}
        >
          <h3 className="introCards__text ">It's time to get started.</h3>
          <img src={scroll} alt="" className="introCards__scroll" />
        </div>

        <StageHero index={0} reference={stage1Ref} />
      </div>
    </div>
  );
}

export default IntroCards;
