import React, { useLayoutEffect, useRef } from 'react';
import './map4Static.scss';
import map4Lottie from '../../../../../assets/interactive/stage4/map4.json';
import Lottie from 'lottie-react';
import { gsap } from '../../../../../gsap';

const Map4Static = () => {
  const containerRef = useRef();
  const upperTextRef = useRef();
  const lottieRef = useRef();
  const lowerTextRef = useRef();
  const animationRef = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 60%',
          end: 'bottom+=200 bottom',
          scrub: true
        }
      });

      tl.to(upperTextRef.current, {
        opacity: 1,
        y: 0,
        duration: 1
      })
        .to(animationRef.current, {
          opacity: 1,
          duration: 1,
          onStart: () => {
            lottieRef.current.goToAndPlay(0);
          }
        })

        .to(
          lowerTextRef.current,
          {
            opacity: 1,
            y: 0,
            duration: 1
          },
          '>5'
        );
    });
    return () => ctx.revert();
  }, []);

  return (
    <div className="map4Static" ref={containerRef}>
      <div className="map4Static__text" ref={upperTextRef}>
        <p className="map4Static__text--reg">
          You begin testing samples from the neighborhood where the first cases were identified, the
          surrounding clinics, and others across the country.
        </p>
      </div>
      <div className="map4Static__lottie" ref={animationRef}>
        <Lottie animationData={map4Lottie} lottieRef={lottieRef} />
      </div>
      <div className="map4Static__text map4Static__text-lower" ref={lowerTextRef}>
        <p className="map4Static__text--reg">
          Widespread positive results make it clear the virus is spreading — sometimes
          asymptomatically — but not at the scale or speed you first feared. 
        </p>
        <br />
        <p className="map4Static__text--reg">
          Research from experts in the Abbott Pandemic Defense Coalition indicates that isolating
          cases is proving to be an effective way to help reduce transmission. And as a likely
          mosquito-borne virus, distributing mosquito nets or using other mosquito control methods
          like larvicides may also be effective further prevention measures, which are typically
          handled at the local level through county or city governments. The Coalition, for its
          part, continues to collect data on risk factors like age and pre-existing conditions.
        </p>
        <br />
        <p className="map4Static__text--bold">But what if the virus mutates?</p>
      </div>
    </div>
  );
};

export default Map4Static;
