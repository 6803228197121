import React, { useEffect, useState, createContext } from 'react';

const WidthContext = createContext({
  width: window.innerWidth,
  last: null,
  viewSize: null
});

function WidthContextProvider({ children }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [last, setLast] = useState();
  const [viewSize, setViewSize] = useState();

  useEffect(() => {
    const handleResize = () => {
      const current = window.innerWidth;
      setWidth(current);
      if (current > 767) {
        setViewSize('large');
      }
      if (current > 1023) {
        setLast(4);
      } else if (current < 1024 && current > 767) {
        setLast(5);
      } else if (current < 768) {
        setLast(7);
        setViewSize('small');
      }
    };
    window.addEventListener('resize', handleResize, { passive: true });
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <WidthContext.Provider value={{ width, last, viewSize }}>{children}</WidthContext.Provider>
  );
}

export { WidthContext, WidthContextProvider };
