import saLoopD from '../../../assets/videos/sa_videoloop_desktop.mp4';
import saLoopM from '../../../assets/videos/sa_videoloop_mobile.mp4';
import indiaLoopD from '../../../assets/videos/india_bgD.mp4';
import indiaLoopM from '../../../assets/videos/india_bgM.mp4';
import saMap from '../../../assets/videos/sa_tile_map.png';
import indiaMap from '../../../assets/videos/india_tile_map.png';
import usMap from '../../../assets/videos/us-map.png';
import usLoopD from '../../../assets/videos/usa_bgD.mp4';
import usLoopM from '../../../assets/videos/usa_bgM.mp4';
import colLoopD from '../../../assets/videos/col_bgD.mp4';
import colLoopM from '../../../assets/videos/col_bgM.mp4';
import colMap from '../../../assets/videos/colombia-map.png';

const videoData = [
  {
    id: 'southAfrica',
    title: 'South Africa',
    description:
      'Viruses are always evolving. Follow scientists from the Abbott Pandemic Defense Coalition as they demonstrate what it takes to track the emergence of a possible new COVID-19 variant in South Africa.',
    color: 'orange',
    map: saMap,
    link: '/south-africa',
    videoD: saLoopD,
    videoM: saLoopM,
    episodeNumber: '1'
  },
  {
    id: 'india',
    title: 'India',
    description:
      'Transmission of blood-borne infections is often higher among people who inject drugs, typically driven by unsafe injection practices. See how the Coalition is working to understand how transmission occurs to identify ways to slow the spread of HIV and viral hepatitis among vulnerable populations in Delhi.',
    color: 'green',
    map: indiaMap,
    link: '/india',
    videoD: indiaLoopD,
    videoM: indiaLoopM,
    episodeNumber: '2'
  },

  {
    id: 'unitedStates',
    title: 'United States',
    description:
      'Urban centers can supercharge the spread of viruses because of their dense populations, constant flow of people, and close living quarters. See how rats, wastewater, and other surprising data sources are helping the Abbott Pandemic Defense Coalition and RUSH University pave the way for the future of pandemic prevention and virus surveillance in cities across the world.',
    color: 'yellow',
    map: usMap,
    link: '/united-states',
    videoD: usLoopD,
    videoM: usLoopM,
    episodeNumber: '3'
  },
  {
    id: 'colombia',
    title: 'Colombia',
    description:
      'New viral threats that jump from mosquitoes to humans must be monitored closely for their potential to spark epidemics or pandemics. Follow scientists and mosquito researchers from the Abbott Pandemic Defense Coalition as they uncover a mosquito-borne virus that was previously unknown to be widespread in Colombia.',
    color: 'purple',
    map: colMap,
    link: '/colombia',
    videoD: colLoopD,
    videoM: colLoopM,
    episodeNumber: '4'
  },
  {
    id: 'virusHunt',
    title: 'the virus hunt',
    description:
      'In this special extended film version of The Virus Hunt series, follow the Abbott Pandemic Defense Coalition — an industry-led global scientific and public health partnership dedicated to the early detection of and rapid response to future pandemic threats — as they identify, track, analyze, and test emerging viruses around the globe.',
    color: 'blue',
    link: '/virus-hunt'
  }
];

export default videoData;
