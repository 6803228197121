import React from 'react';
import './inlinePhotos.scss';

function InlinePhoto({ photoIndex, photoRef }) {
  return (
    <div className="inline">
      <div ref={photoRef} className={`inline__background inline--${photoIndex}`} />
    </div>
  );
}

export default InlinePhoto;
