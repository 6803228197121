import ColImage from '../../assets/enhanced-contents/col_map_video.png';
import Meet from '../../assets/enhanced-contents/abbott-meet-jorge.jpg';
import videoData from '../Hub/Video/videoData';

const Content = {
  top: {
    eyebrow: 'The Virus Hunt: Episode 4',
    img: ColImage,
    title: 'Colombia',
    description: videoData[3].description
  },
  bottom: {
    img: Meet,
    title: 'Meet Jorge Osorio',
    bio: 'Jorge Osorio, DVM, PhD',
    bio2: 'University of Wisconsin-Madison',
    description:
      'This Medellín native and international expert in epidemiology, virology, and vaccines specializes in emerging diseases and research that recognizes the interconnectedness of people, animals, and plants in one ecosystem. His work in developing vaccines against dengue, chikungunya, influenza, rabies, plague, and more is evidence of his abiding commitment to detect and respond to infectious diseases that impact people living in low- and middle-income countries.'
  }
};

export default Content;
