import React, { useLayoutEffect, useRef } from 'react';
import IntroHero from './IntroHero/IntroHero';
import IntroCards from './IntroCards/IntroCards';
import Lottie from 'lottie-react';
import './intro.scss';
import IntroGlobe from './IntroGlobe/IntoGlobe';
import mapDotsLottie from '../../../../assets/interactive/intro/map1.json';

const Intro = ({ progressAnimation }) => {
  const globeRef = useRef();
  const globeVideoRef = useRef();
  const globeTextRef = useRef();
  const introMapRef = useRef();
  const introMapTextRef = useRef();
  const lottieRef = useRef();
  const introRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (entry['intersectionRatio'] > 0.3) {
              globeTextRef.current.style.opacity = 1;
              globeTextRef.current.style.transform = 'translateY(0)';
            }
            if (entry['intersectionRatio'] > 0.5) {
              globeVideoRef.current.style.opacity = 1;
              globeVideoRef.current.play();
            }
          }
        });
      },
      { threshold: [0, 0.3, 0.5, 1] }
    );
    observer.observe(globeRef.current);
  }, []);

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (entry['intersectionRatio'] > 0.5) {
              introMapRef.current.style.opacity = 1;
              introMapTextRef.current.style.opacity = 1;
              introMapTextRef.current.style.transform = 'translateY(0)';
            }
            if (entry['intersectionRatio'] > 0.7) {
              lottieRef.current.goToAndPlay(0, true);
            }
          }
        });
      },
      { threshold: [0, 0.5, 0.7, 1] }
    );
    observer.observe(introMapRef.current);
  }, []);

  return (
    <section className="intro" ref={introRef}>
      <IntroHero />

      <IntroGlobe globeRef={globeRef} globeVideoRef={globeVideoRef} globeTextRef={globeTextRef} />
      <div className="intro__map" ref={introMapRef}>
        <div className="intro__map__text" ref={introMapTextRef}>
          <p className="intro__map__text--reg">
            A cluster of unusual cases has broken out in a major capital city.
          </p>
          <br />
          <p className="intro__map__text--reg">It’s your job to find out what’s going on.</p>
          <br />
          <p className="intro__map__text--bold">
            Can you change the course of an outbreak before it becomes a pandemic?
          </p>
        </div>
        <Lottie
          animationData={mapDotsLottie}
          className="intro__map__lottie"
          lottieRef={lottieRef}
        />
      </div>
      <IntroCards progressAnimation={progressAnimation} />
    </section>
  );
};

export default Intro;
