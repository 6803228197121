import { useRef, useLayoutEffect } from 'react';
import './linesAnimation.scss';
import linesLottie from '../../../../../assets/interactive/stage1/lines2.json';
import LottieScrubOnScroll from '../../../Common/LottieScrubOnScroll/LottieScrubOnScroll';

const LinesAnimation = () => {
  const p1ref = useRef();
  const p2ref = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (entry['intersectionRatio'] > 0.3) {
              entry.target.style.transform = 'translateY(0)';
              entry.target.style.opacity = 1;
            }
          }
        });
      },
      { threshold: [0, 0.3, 1] }
    );
    observer.observe(p1ref.current);
    observer.observe(p2ref.current);
  }, []);

  return (
    <section className="linesAnimation">
      <div className="linesAnimation__paragraph">
        <p ref={p1ref}>
          Just like other living things, viruses belong to “family trees” which map the genetic
          similarities between them. Powerful software can run newly identified viral genomes
          through a database of previously discovered viruses to identify which ones most closely
          match.
        </p>
      </div>
      <div className="linesAnimation__lottie">
        <LottieScrubOnScroll lottie={linesLottie} />
      </div>
      <div className="linesAnimation__paragraph">
        <p ref={p2ref}>
          Genetic markers — along with ongoing work by other Coalition partners in documenting the
          symptoms seen in local clinics — help you identify what “family” of viruses this mystery
          pathogen belongs to:
          <br />
          <br />
          The Flavivirus family.
        </p>
      </div>
    </section>
  );
};

export default LinesAnimation;
