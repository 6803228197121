import TopBlurb from './TopBlurb';
import BottomBlurb from './BottomBlurb';

import './SubPgsContent.scss';

const SubPgsContent = ({ topData, btmData, topRef, btmRef }) => {
  return (
    <>
      <TopBlurb data={topData} animateRef={topRef} />
      <BottomBlurb data={btmData} animateRef={btmRef} />
    </>
  );
};

export default SubPgsContent;
