import AntigenCheckpt from '../Stage2/AntigenCheckpt/AntigenCheckpt';
import Finish2 from './Finish2/Finish2';
import Sequence2 from './Sequence2/Sequence2';
import '../stages.scss';
import ParagraphWLottie2 from './ParagraphWLottie2/ParagraphWLottie2';

const index = ({ startRef, progressRef }) => {
  return (
    <section className="stages" ref={startRef}>
      <Sequence2 />
      <AntigenCheckpt />
      <ParagraphWLottie2 />
      <Finish2 stage={2} progressRef={progressRef} />
    </section>
  );
};

export default index;
