import React from 'react';
import './Hyperlink.scss';

function Hyperlink({ hyperlinkData }) {
  return (
    <a className="hyperLink" href={hyperlinkData.href} target="_blank" rel="noreferrer">
      {hyperlinkData.copy}
    </a>
  );
}

export default Hyperlink;
