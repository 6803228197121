import imageOne from '../../assets/enhanced-contents/abbott-sa-grid-1.jpg';
import imageTwo from '../../assets/enhanced-contents/abbott-sa-grid-2.jpg';
import imageThree from '../../assets/enhanced-contents/abbott-sa-grid-3.jpg';

const Grid = [
  {
    img: imageOne
  },
  {
    img: imageTwo
  },
  {
    img: imageThree
  }
];

export default Grid;
