import React from 'react';
import './introGlobe.scss';
import globe from '../../../../../assets/interactive/intro/globe.mov';
import globeW from '../../../../../assets/interactive/intro/globe.webm';

function IntroGlobe({ globeRef, globeVideoRef, globeTextRef }) {
  return (
    <div className="introGlobe" ref={globeRef}>
      <div className="introGlobe__text" ref={globeTextRef}>
        <p className="introGlobe__text--reg">
          The Abbott Pandemic Defense Coalition is working to build a global network of virus
          detectives who can help address outbreaks before they explode into pandemics.
        </p>
        <br />
        <p className="introGlobe__text--bold">And today, you’ve become the newest member.</p>
      </div>
      <video muted playsInline preload="auto" ref={globeVideoRef} className="introGlobe__video">
        <source src={globe} type="video/quicktime" />
        <source src={globeW} type="video/webm" />
      </video>
    </div>
  );
}

export default IntroGlobe;
