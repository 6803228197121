import Logo from '../../../assets/logo.svg';
import './subPgsNav.scss';
import { Link } from 'react-router-dom';

const SubPgsNav = ({ data }) => {
  const { to, copy, hide, bg } = data;
  return (
    <nav className={bg ? 'subPgsNav' : 'subPgsNav subPgsNav--bg'}>
      <div className="subPgsNav__links">
        <Link to="/" className="subPgsNav__links__link">
          {`<`} back to hub
        </Link>

        <Link
          to={to}
          className={
            hide ? 'subPgsNav__links__link subPgsNav__links__link--hide' : 'subPgsNav__links__link '
          }
        >
          {copy} {`>`}
        </Link>
      </div>
      <div className="subPgsNav__logo">
        <a
          href=" https://www.abbott.com/"
          target="_blank"
          rel="noreferrer"
          aria-label="Visit Abbott Homepage"
        >
          <img src={Logo} alt="" />
        </a>
      </div>
    </nav>
  );
};

export default SubPgsNav;
