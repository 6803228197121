import React, { useRef, useLayoutEffect, useContext, useEffect } from 'react';
import { gsap } from '../../../../../gsap';
import './sequence4.scss';
import { WidthContext } from '../../../../../WidthContext';
import Lottie from 'lottie-react';
import tubeD from '../../../../../assets/interactive/stage4/s4_sequence.json';
import tubeM from '../../../../../assets/interactive/stage4/s4_sequence_mobile.json';

function Sequence4() {
  const sequence4Section = useRef();
  const upper1Ref = useRef();
  const lower1Ref = useRef();
  const lottieRef = useRef();
  const animationRef = useRef();
  const { width } = useContext(WidthContext);

  useEffect(() => {
    lottieRef.current.goToAndStop(0);

    let ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: sequence4Section.current,
          start: () => 'top 40%',
          end: () => 'bottom+=200 bottom',
          scrub: true
        }
      });

      let playhead = { frame: 0 };
      let frameCount = lottieRef.current.getDuration(true);

      tl.to(upper1Ref.current, {
        opacity: 1,
        y: 0,
        duration: 2
      }).to(animationRef.current, { opacity: 1, duration: 1 });

      tl.add('startLottie').to(
        playhead,
        {
          frame: (frameCount -= 1),
          ease: 'none',
          onUpdate: () => {
            renderLottie();
          },
          duration: 15
        },
        'startLottie'
      );

      tl.add('lowerText').to(
        lower1Ref.current,
        {
          opacity: 1,
          y: 0,
          duration: 2
        },
        'lowerText'
      );
      function renderLottie() {
        lottieRef.current.goToAndStop(playhead.frame, true);
      }
    });
    return () => ctx.revert();
  }, []);

  return (
    <section className="sequence4" ref={sequence4Section}>
      <div className="sequence4__copy">
        <p className="sequence4__copy__text" ref={upper1Ref}>
          To keep tabs on mutations, a certain percentage of positive results from your PCR tests
          must be tested further — and genetically sequenced to identify any changes that might be
          of concern.
        </p>
      </div>
      <div className="sequence4__animation" ref={animationRef}>
        {width > 480 ? (
          <Lottie
            animationData={tubeD}
            loop={false}
            className="sequence3__animation__lottie"
            lottieRef={lottieRef}
          />
        ) : (
          <Lottie
            animationData={tubeM}
            loop={false}
            className="sequence3__animation__lottie"
            lottieRef={lottieRef}
          />
        )}
      </div>
      <div className="sequence4__copy sequence4__copy-lower">
        <p className="sequence4__copy__text" ref={lower1Ref}>
          This type of real-time virus surveillance can help identify variants that evolve to be
          more transmissible, deadly, or elusive to diagnostics — and help our healthcare systems
          better prepare in advance.
        </p>
      </div>
    </section>
  );
}

export default Sequence4;
