const subPgsData = {
  qa: {
    nav: {
      to: '/on-the-hunt',
      copy: 'INTERACTIVE: ON THE HUNT',
      hide: false,
      bg: false
    }
  },
  sa: {
    nav: {
      to: '/india',
      copy: 'EPISODE 2: India',
      hide: false,
      bg: true
    },
    vimeo: {
      id: 'saVimeo',
      url: 'https://player.vimeo.com/video/789100336?h=bbf6f305fa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      color: 'sa',
      index: 0
    }
  },
  india: {
    nav: {
      to: '/united-states',
      copy: 'EPISODE 3: United States',
      hide: false,
      bg: true
    },
    vimeo: {
      id: 'indiaVimeo',
      url: 'https://player.vimeo.com/video/817385224?h=c9fe5e0067&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      color: 'india',
      index: 1
    }
  },
  us: {
    nav: {
      to: '/colombia',
      copy: 'EPISODE 4: Colombia',
      hide: false,
      bg: true
    },
    vimeo: {
      id: 'usVimeo',
      url: 'https://player.vimeo.com/video/801623670?h=d636edda02&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      color: 'us',
      index: 2
    }
  },
  col: {
    nav: {
      to: '/virus-hunt',
      copy: 'FILM: THE VIRUS HUNT',
      hide: false,
      bg: true
    },
    vimeo: {
      id: 'colVimeo',
      url: 'https://player.vimeo.com/video/806412129?h=7161f66648&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      color: 'col',
      index: 3
    }
  },
  interactive: {
    nav: {
      to: '/south-africa',
      copy: 'EPISODE 1: South Africa',
      hide: false,
      bg: false
    }
  },
  macro: {
    nav: {
      to: '/virus-hunters',
      copy: 'Q + A VIRUS HUNTERS',
      hide: false,
      bg: true
    },
    vimeo: {
      id: 'macroVimeo',
      url: 'https://player.vimeo.com/video/863173778?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      color: 'macro',
      index: 4
    }
  }
};

export default subPgsData;
