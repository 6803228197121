import { useContext, useState, useRef, useLayoutEffect } from 'react';
import { WidthContext } from '../../../../../WidthContext';
import './pcrCheckpt.scss';
import pcrGraphic from '../../../../../assets/interactive/stage3/pcrGraphic.png';
import pcrGraphicLegend from '../../../../../assets/interactive/stage3/pcrGraphicLegend.svg';
import pcrGraphicLegendM from '../../../../../assets/interactive/stage3/pcrGraphicLegendM.svg';
import Response from '../../../Common/Response/Response';
import xButton from '../../../../../assets/interactive/stage2/closeIcon.svg';
import orangeDroplet from '../../../../../assets/interactive/stage3/orangeDroplet.svg';
import blueDroplet from '../../../../../assets/interactive/stage3/blueDroplet.svg';
import pinkDroplet from '../../../../../assets/interactive/stage3/pinkDroplet.svg';
import greenDroplet from '../../../../../assets/interactive/stage3/greenDroplet.svg';
import { responseData } from '../../../Common/Response/responseData';
import { gsap, ScrollTrigger } from '../../../../../gsap';
import InfoButton from '../../../Common/InfoButton/InfoButton';

const PcrCheckpt = () => {
  const { width } = useContext(WidthContext);
  const [infoOpen, setInfoOpen] = useState(false);
  const [active, setActive] = useState(null);
  const [reveal, setReveal] = useState(false);
  const bottomRef = useRef();
  const { correct } = responseData[2];
  const containerRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (entry['intersectionRatio'] > 0.3) {
              containerRef.current.style.opacity = 1;
            }
          }
        });
      },
      { threshold: [0, 0.3, 0.7, 1] }
    );
    observer.observe(containerRef.current);
  }, []);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (active !== correct) {
        ScrollTrigger.create({
          trigger: bottomRef.current,
          start: () => 'bottom+=100 top',
          onEnter: () => {
            setReveal(true);
            setActive(correct);
          }
        });
      }
    });
    return () => ctx.revert();
  }, [active, correct, reveal]);

  return (
    <div className="pcrCheckpt" ref={containerRef}>
      <div className="pcrCheckpt__paragraph">
        <h4>Which of the three reformulated PCR tests will give the best results?</h4>
        <p>Select which new formulation best matches the control tube.</p>
      </div>
      <div className="pcrCheckpt__graphic" ref={bottomRef}>
        <div className="legendGraphic">
          <img src={width > 768 ? pcrGraphicLegend : pcrGraphicLegendM} alt="" />

          <InfoButton handleClick={setInfoOpen} param={!infoOpen} stage={3} />
        </div>
        <div className="mainGraphic">
          <img src={pcrGraphic} alt="graphic of data tree" />
        </div>
      </div>
      {infoOpen && (
        <div className="pcrCheckpt__infoPanel">
          <div className="pcrCheckpt__infoPanel__wrapper">
            <div className="xButton">
              <button
                onClick={() => {
                  setInfoOpen(false);
                }}
              >
                <img src={xButton} alt="button with x" />
              </button>
            </div>
            .
            <div className="container">
              {/* infoblock 1 */}
              <div className="container__infoBlock first">
                <div className="header">
                  <img src={orangeDroplet} alt="orange raindrop" />
                  <p>Primers</p>
                </div>
                <div className="info">
                  <p>
                    In a PCR test, double-stranded DNA is heated carefully until it breaks down into
                    single strands. Then the reaction is cooled significantly to allow “primers” to
                    attach to the ends of these strands. These primers act as a kind of “guide
                    light” for the polymerase enzyme in the next stage of the PCR process —
                    directing the enzyme where to attach so it can make a new complementary strand
                    of DNA from the single strand. Each such “amplification cycle” doubles the
                    amount of viral DNA, turning what is initially a small amount of viral DNA (or
                    RNA, which must first be “reverse transcribed” into DNA) taken from swabs or
                    blood samples into large enough amounts of viral DNA for meaningful analysis.
                  </p>
                </div>
              </div>
              {/* infoblock 2 */}
              <div className="container__infoBlock">
                <div className="header">
                  <img src={pinkDroplet} alt="pink raindrop" />
                  <p>Nucleotides</p>
                </div>
                <div className="info">
                  <p>
                    Nucleotides are the building blocks of DNA – sophisticated chemical structures
                    that are necessary for effective “amplification” of the viral DNA. During the
                    amplification process, these nucleotides become incorporated into the base pairs
                    of the viral DNA and play an important role in turning small amounts of viral
                    DNA (or RNA) into larger amounts that can be better analyzed.
                  </p>
                </div>
              </div>
              {/* infoblock 3 */}
              <div className="container__infoBlock">
                <div className="header">
                  <img src={greenDroplet} alt="green raindrop" />
                  <p>Buffers</p>
                </div>
                <div className="info">
                  <p>
                    DNA is an extremely sensitive material, and easy to damage and break down.
                    Buffers are chemicals that provide a stable and suitable environment for DNA
                    amplification to take place. Typically, this is at a more alkaline (basic) pH
                    level, between 8.0 and 9.5.
                  </p>
                </div>
              </div>
              {/* infoblock 4 */}
              <div className="container__infoBlock">
                <div className="header">
                  <img src={blueDroplet} alt="blue raindrop" />
                  <p>Taq polymerase</p>
                </div>
                <div className="info">
                  <p>
                    This DNA “polymerase” — an enzyme that can make copies of DNA molecules — is
                    thermostable, which means it can stand up to the higher temperatures that are
                    important to the PCR amplification process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Response index={2} active={active} reveal={reveal} setActive={setActive} />
    </div>
  );
};

export default PcrCheckpt;
