import { useEffect } from 'react';

import Genomes from '../Genomes';

import Logo from '../../../assets/logo.svg';

import './footer.scss';

const Footer = ({ animateRef }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            // Iterate over child nodes
            for (
              var child = animateRef.current.firstChild;
              child !== null;
              child = child.nextSibling
            ) {
              child.style.cssText = 'opacity: 1; transform: translateY(0)';
            }
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(animateRef.current);
  }, [animateRef]);

  return (
    <footer className="footer">
      <div className="inner-wrapper">
        <div className="footer-content animate-content" ref={animateRef}>
          <div className="footer-image">
            <img src={Logo} alt="Footer logo" />
          </div>
          <div className="footer-description">
            <p>
              The Abbott Pandemic Defense Coalition is the first industry-led global scientific and
              public health partnership dedicated to the early detection of and rapid response to
              future pandemic threats.
            </p>
          </div>

          <a
            href="https://www.abbott.com/virushunters.html"
            target="_blank"
            rel="noreferrer"
            id="learnMoreButton"
            className="outline-button"
          >
            Learn More
          </a>
        </div>
      </div>
      <div className="genomes-left">
        <Genomes height={'medium'} />
        <Genomes height={'small'} />
        <Genomes height={'large'} />
      </div>
      <div className="genomes-right">
        <Genomes height={'small'} />
        <Genomes height={'xlarge'} />
        <Genomes height={'medium'} />
      </div>
    </footer>
  );
};

export default Footer;
