import React, { useRef, useState, useContext } from 'react';
import './qaCarousel.scss';
import { contentsData } from '../contentslData';
import { huntersData } from '../huntersData';
import { useSwipeable } from 'react-swipeable';
import arrow from '../../../assets/qa/carousel/carousel_arrow.svg';
import { WidthContext } from '../../../WidthContext';

function QACarousel({ showArrows }) {
  const { last } = useContext(WidthContext);
  const [current, setCurrent] = useState(0);
  const photoRef = useRef();
  const carouselData = contentsData.carousel;
  const length = contentsData.carousel.length - 1;

  const sendGaCarouselEvent = () => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'qa_article_carousel' });
    }
  };
  const handleClick = (direction) => {
    let currentI;
    setTimeout(() => {
      setCurrent(currentI);
    }, 500);
    sendGaCarouselEvent();
    if (direction === 'right') {
      if (current === last) {
        photoRef.current.style.animation = 'moveTo1R 0.4s linear forwards';
      } else {
        photoRef.current.style.animation = `moveTo${current + 2}R 0.4s linear forwards`;
      }
      currentI = current === last ? 0 : current + 1;
    } else {
      if (current === 0) {
        photoRef.current.style.animation = `moveTo${last + 1} 0.4s linear forwards`;
      } else {
        photoRef.current.style.animation = `moveTo${current} 0.4s linear forwards`;
      }

      currentI = current === 0 ? last : current - 1;
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleClick('right');
      sendGaCarouselEvent();
    },
    onSwipedRight: () => {
      handleClick('left');
      sendGaCarouselEvent();
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  return (
    <div className="qaCarousel">
      <h3>meet the virus hunters</h3>
      {showArrows && (
        <div className="qaCarousel__arrows">
          <button
            aria-label="Go to previous slide"
            onClick={() => {
              handleClick('left');
            }}
          >
            <img src={arrow} alt="" className="qaCarousel__arrows__arrow" />
          </button>
          <button
            aria-label="Go to next slide"
            onClick={() => {
              handleClick('right');
            }}
          >
            <img
              src={arrow}
              alt=""
              className="qaCarousel__arrows__arrow qaCarousel__arrows__arrow-right"
            />
          </button>
        </div>
      )}

      <div className="qaCarousel__container" {...handlers}>
        <div className="qaCarousel__cards" ref={photoRef}>
          <div className="qaCarousel__cards__card">
            <div className={`qaCarousel__cards__card__bar mary`} />
            <img className="qaCarousel__cards__card__image" alt="" src={huntersData.mary.photo} />
            <div className="qaCarousel__cards__card__name">{huntersData.mary.name}</div>
            <div className="qaCarousel__cards__card__title">{carouselData[7].title}</div>
          </div>
          {carouselData.map((content) => {
            return (
              <div key={content.class} className="qaCarousel__cards__card">
                <div className={`qaCarousel__cards__card__bar ${content.class}`} />
                <img
                  className="qaCarousel__cards__card__image"
                  alt=""
                  src={huntersData[content.class].photo}
                />
                <div className="qaCarousel__cards__card__name">
                  {huntersData[content.class].name}
                </div>
                <div className="qaCarousel__cards__card__title">{content.title}</div>
              </div>
            );
          })}
          <div className="qaCarousel__cards__card">
            <div className={`qaCarousel__cards__card__bar gavin`} />
            <img className="qaCarousel__cards__card__image" alt="" src={huntersData.gavin.photo} />
            <div className="qaCarousel__cards__card__name">{huntersData.gavin.name}</div>
            <div className="qaCarousel__cards__card__title">{carouselData[0].title}</div>
          </div>
        </div>
      </div>
      <div className="qaCarousel__indicator">
        {carouselData.map((content, i) => {
          return (
            <svg
              key={i}
              className={
                i >= current && i <= current + length - last
                  ? 'qaCarousel__indicator__circle qaCarousel__indicator__circle-active'
                  : 'qaCarousel__indicator__circle'
              }
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="6" />
            </svg>
          );
        })}
      </div>
    </div>
  );
}

export default QACarousel;
