import { useLayoutEffect, useRef, useContext } from 'react';
import './ending.scss';
import endingBg from '../../../../../assets/interactive/stage4/end_d.jpg';
import endingBgM from '../../../../../assets/interactive/stage4/end_m.jpg';
import { WidthContext } from '../../../../../WidthContext';
import { sendPopOutClickEvents } from '../../../sendInteractiveEvents';

const Ending = () => {
  const contentsRef = useRef();
  const containerRef = useRef();
  const { width } = useContext(WidthContext);

  const handleOpenCert = () => {
    var win = window.open('');
    var img = win.document.createElement('img');
    img.src = 'https://courageous-cdn-media-bucket.s3.amazonaws.com/abbott/certificate.jpg';
    win.document.body.appendChild(img);
    img.onload = function () {
      win.print();
    };
  };
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === true) {
            if (entry['intersectionRatio'] > 0.3) {
              entry.target.style.opacity = 1;
              entry.target.style.transform = 'translateY(0)';
            }
          }
        });
      },
      { threshold: [0, 0.3, 0.7, 1] }
    );
    observer.observe(containerRef.current);
    observer.observe(contentsRef.current);
  }, []);

  return (
    <div className="ending" ref={containerRef}>
      <img className="ending__fullWidthImg" alt="" src={width > 480 ? endingBg : endingBgM} />
      <div className="ending-contents" ref={contentsRef}>
        <div className="ending__copy">
          {/* <p>
            Vaccine trials are going well and will soon further help reduce the threat of infection
            spread.
          </p> */}
          <p>It’s a job well done for a virus hunter.</p>
          <p>
            The Abbott Pandemic Defense Coalition is bringing together experts around the world to
            join forces to stay one step ahead of the next viral threat.
          </p>
        </div>
        <div className="ending__links">
          <a href="https://abbott-discovery.com/" target="_blank" rel="noreferrer" className="bold">
            Explore The Virus Hunt
          </a>{' '}
          &rarr;
        </div>
        <div className="ending__links">
          <a
            className="bold"
            href="https://www.abbott.com/virushunters.html"
            target="_blank"
            rel="noreferrer"
          >
            Explore the Abbott Pandemic Defense Coalition
          </a>{' '}
          &rarr;
        </div>
        <button
          className="ending__button"
          onClick={() => {
            handleOpenCert();
            sendPopOutClickEvents('end', 'Get your certificate');
          }}
        >
          Get your certificate
        </button>
      </div>
    </div>
  );
};

export default Ending;
