import IndiaImage from '../../assets/videos/sa-map-video.png';
import Meet from '../../assets/videos/meet-tulio.jpg';
import videoData from '../Hub/Video/videoData';

const Content = {
  top: {
    eyebrow: 'The Virus Hunt: Episode 1',
    img: IndiaImage,
    title: 'South Africa',
    description: videoData[0].description
  },
  bottom: {
    img: Meet,
    title: 'Meet Tulio de Oliveira',
    bio: 'Tulio de Oliveira, PhD, BSc, Director',
    bio2: 'Centre for Epidemic Response and Innovation',
    description:
      'This award-winning researcher is the Director of South Africa’s Centre for Epidemic Response and Innovation (CERI), where the Omicron variant was first identified and reported. Together with the Abbott Pandemic Defense Coalition, he’s building a best-in-class genomics surveillance system for the continent of Africa — and breaking the mold for what robust pandemic defense looks like, not only in the Global South, but also the world.'
  }
};

export default Content;
