import { useContext, useEffect } from 'react';
import { WidthContext } from '../../../WidthContext';

const TopBlurb = ({ data, animateRef }) => {
  const { width } = useContext(WidthContext);
  useEffect(() => {
    const delayAnimation = width <= window.innerHeight;
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            if (delayAnimation !== true) {
              animateRef.current.style.cssText = 'opacity: 1;transform:translateY(0);';
            } else {
              setTimeout(() => {
                animateRef.current.style.cssText = 'opacity: 1;transform:translateY(0);';
              }, [1000]);
            }
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(animateRef.current);
  }, [animateRef, width]);

  return (
    <section className="SubPgsContent" id="topContent">
      <div className="SubPgsContent-container" ref={animateRef}>
        <div className="SubPgsContent-eyebrow">
          <span>{data.eyebrow}</span>
        </div>
        <div className="SubPgsContent-title">
          <h2>{data.title}</h2>
        </div>
        <div className="SubPgsContent-description">
          <p>{data.description}</p>
        </div>
      </div>
      <div className="SubPgsContent-image">
        <img src={data.img} alt="Map" />
      </div>
    </section>
  );
};

export default TopBlurb;
