import './relatedContents.scss';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';

const RelatedContents = (origin) => {
  const [relatedContentsData, setRelatedContentsData] = useState();
  const [currentContent, setCurrentContent] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(
          'https://related-contents-datas.s3.amazonaws.com/abbottRelatedContents.json'
        );
        const data = JSON.parse(JSON.stringify(result.data));
        setRelatedContentsData(data.contents);
      } catch (error) {
        console.log('theres an error', error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const pathname = window.location.pathname;
    let current = pathname.substring(1, 3);
    if (current === 'vi') {
      if (pathname === '/virus-hunters') {
        setCurrentContent('blue1');
      } else {
        setCurrentContent('blue3');
      }
    } else if (current === 'so') {
      setCurrentContent('orange');
    } else if (current === 'in') {
      setCurrentContent('green');
    } else if (current === 'co') {
      setCurrentContent('purple');
    } else if (current === 'un') {
      setCurrentContent('yellow');
    } else if (current === 'on') {
      setCurrentContent('blue2');
    }
  }, []);

  return (
    <section
      className={origin === 'article' ? 'relatedContent relatedContent--article' : 'relatedContent'}
    >
      <div className="relatedContent__heading-container">
        <h3>More from the virus hunt</h3>
      </div>
      <div className="relatedContent-container">
        {relatedContentsData &&
          relatedContentsData.map(({ eyebrow, title, image, color, link }) => (
            <Fragment key={color}>
              {currentContent !== color && (
                <a
                  className={`relatedContent__item ${'color-' + color}`}
                  style={{ backgroundImage: `url(${image})` }}
                  href={link}
                >
                  {!link && <div className="relatedContent__item__coming">coming soon</div>}
                  <div className="relatedContent__item__eyebrow">
                    <span>{eyebrow}</span>
                  </div>
                  <div className="relatedContent__item__title">
                    <h3>{title}</h3>
                  </div>
                </a>
              )}
            </Fragment>
          ))}
      </div>
    </section>
  );
};

export default RelatedContents;
