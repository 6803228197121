import IndiaImage from '../../assets/enhanced-contents/abbott-labs-india-map.png';
import Meet from '../../assets/enhanced-contents/abbott-meet-sunil.jpg';
import videoData from '../Hub/Video/videoData';

const Content = {
  top: {
    eyebrow: 'The Virus Hunt: Episode 2',
    img: IndiaImage,
    title: 'India',
    description: videoData[1].description
  },
  bottom: {
    img: Meet,
    title: 'Meet Sunil Solomon',
    bio: 'Sunil Solomon, MBBS, PhD, MPH',
    bio2: 'Chairman, YRGCARE',
    description:
      'This researcher and head of YRGCARE specializes in epidemiology, clinical management, and access to HIV and hepatitis care among vulnerable Indian populations. The son of YRGCARE founder and famed HIV researcher Dr. Suniti Solomon, Sunil continues his mother’s legacy by expanding prevention and treatment services for viruses while also pioneering an extensive research portfolio of clinical, laboratory, and behavioral research across India with the Abbott Pandemic Defense Coalition.'
  }
};

export default Content;
