import { useLayoutEffect, useRef } from 'react';
import heroVideo from '../../../../../assets/interactive/intro/interactive_bgD.mp4';
import mobileHeroVideo from '../../../../../assets/interactive/intro/interactive_bgM.mp4';
import SubPgsNav from '../../../../SubPgs/SubPgsNav';
import subPgsData from '../../../../SubPgs/subPgsData';
import scroll from '../../../../../assets/interactive/intro/scroll.svg';
import { gsap } from '../../../../../gsap';

import './introHero.scss';

const IntroHero = () => {
  const copyRef = useRef();
  const headRef = useRef();
  useLayoutEffect(() => {
    const words = gsap.utils.selector(headRef.current);
    let ctx = gsap.context(() => {
      const tl = gsap.timeline();
      tl.to(
        words('.introHero__word'),
        {
          opacity: 1,
          duration: 0.9,
          stagger: 0.3,
          ease: 'ease-in',
          delay: 1
        },
        '<'
      ).to(copyRef.current, { opacity: 1, duration: 1 });
    });

    return () => ctx.revert();
  }, []);

  return (
    <section className="introHero">
      <div className="introHero__bg">
        <video
          muted
          autoPlay
          loop
          playsInline
          className="introHero__bg__video introHero__bg__video-d"
        >
          <source src={heroVideo} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
        <video
          muted
          autoPlay
          loop
          playsInline
          className="introHero__bg__video introHero__bg__video-m"
        >
          <source src={mobileHeroVideo} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
      <div className="introHero__content">
        <SubPgsNav data={subPgsData.interactive.nav} />
        <div className="introHero__content__text">
          <h1 ref={headRef} className="introHero__words">
            <span className="introHero__word">On</span> <span className="introHero__word">the</span>{' '}
            <span className="introHero__word">Hunt</span>
          </h1>
          <p ref={copyRef}>How to change the course of an outbreak before it becomes a pandemic</p>
        </div>
        <div className="introHero__content__scroll">
          <span>Scroll to experience</span>
          <img src={scroll} alt="" />
        </div>
      </div>
    </section>
  );
};

export default IntroHero;
