import React from 'react';
import { ReactComponent as InfoIcon } from '../../../../assets/interactive/i.svg';
import { sendPopOutClickEvents } from '../../sendInteractiveEvents';
import './infoButton.scss';

function InfoButton({ handleClick, param, stage }) {
  return (
    <button
      className="infoButton"
      onClick={() => {
        handleClick(param);
        sendPopOutClickEvents(stage, 'info');
      }}
    >
      <div className="infoButton__icon">
        <InfoIcon />
      </div>
      <span className="infoButton__text">INFO</span>
    </button>
  );
}

export default InfoButton;
